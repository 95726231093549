import React, { useContext, useMemo } from 'react';
import { UserContext } from '../context/UserContext';
import { Laptop, MoonFill } from 'react-bootstrap-icons';
import Logo from '../components/common/Logo';
import useLocalization from '../hooks/useLocalization';
import { useScreenWidth } from '../hooks/useScreenWidth';
import { isCustomer } from '../helpers/global';

const SmallScreenWarning = ({ translate }) => {
  return (
    <div className="h-100 w-100 d-flex justify-content-center align-items-center px-2">
      <div className="d-flex flex-column justify-content-center align-items-center border rounded">
        <div className="bg-dark text-center py-2 mb-2 w-100">
          <Logo />
        </div>
        <Laptop size={50} />
        <h6 dir="ltr" className="mx-2 text-center mt-1">
          <b> Hey there!</b> For the best experience, we recommend using our app on a desktop. You'll enjoy a smoother
          and more immersive experience. <br /> Thanks you!
        </h6>
      </div>
    </div>
  );
};

const MobileScreenChecker = ({ children }) => {
  const { isUserLoggedIn, user, viewingUser } = useContext(UserContext);
  const authRole = useMemo(() => (viewingUser ?? user)?.['role'], [user, viewingUser]);
  const { screenSize } = useScreenWidth();
  const { translate } = useLocalization();

  const showSmallScreenWarning = useMemo(() => isUserLoggedIn && isCustomer(authRole) && screenSize === 'xs', [
    screenSize,
    isUserLoggedIn,
    authRole
  ]);

  return showSmallScreenWarning ? <SmallScreenWarning translate={translate} /> : children;
};

export default MobileScreenChecker;
