import React from 'react';
import { Route, Switch, withRouter } from 'react-router';
import { Redirect, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import NotFound from '../common/NotFound';
import CompTable from './comp-table';
import ManageUsers from './manage-users';

const Admin = ({ assigneUsers }) => {
  const location = useLocation();
  const { user } = useAuth();
  if (location.pathname === '/admin') {
    return <Redirect from="/admin" to="/admin/manage-users" />;
  }

  return (
    <Switch>
      <Route path="/admin/manage-users/">
        <ManageUsers assigneUsers={assigneUsers} />
      </Route>
      <Route path="/admin/comp-table/">
        <CompTable />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

export default withRouter(Admin);
