import { cloneDeep } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Container, Dropdown, FormControl, FormSelect, Modal, Row } from 'react-bootstrap';
import { PlusCircle } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getFormattedDate } from '../../../../form-generator/helpers/utility';
import { deleteFileFromServer, makeApiRequests } from '../../../../helpers/api';
import {
  APP_URL,
  ENDPOINTS,
  FINANCIAL_STRATEGIST_ROLE,
  IRR_CALCULATOR_KEY,
  PAPER_CALCULATOR_KEY,
  PINUI_BINUI_CALCULATOR_KEY,
  REAL_ESTATE_ANALYST_ROLE,
  RSU_OPTIONS_KEY,
  SALES_ROLE,
  STATUS_INTEREST_IN_MY_COURSE,
  fieldsWithFormulas,
  getCustomerSortNameToField,
  getPresetTableHeaders,
  monthSmall,
  presetTableHeaders,
  searchFilterOptions
} from '../../../../helpers/constants';
import {
  checkIfUserCanEdit,
  evalFormula,
  generateAlphanumericString,
  isAdmin,
  updateItemsInArray,
  validateEmail
} from '../../../../helpers/global';
import useAuth from '../../../../hooks/useAuth';
import RealEstateIRR from '../../../calculators/Irr';
import PaperApartmentIRR from '../../../calculators/PaperApartment';
import PinuiBinuiIRR from '../../../calculators/PinuiBinui';
import RSUOptions from '../../../calculators/RSUOptionsCalculator';
import AlertModal from '../../../common/AlertModal';
import CustomMultiSelect from '../../../common/CustomMultiSelect';
import FileViewer from '../../../common/FileViewer';
import FloatingButton from '../../../common/FloatingButton';
import HorizontalProgress from '../../../common/HorizontalProgress';
import NotFound from '../../../common/NotFound';
import SlidingSideBar from '../../../common/SlidingSideBar/SlidingSideBar';
import SearchBox from '../../../common/searchbox';
import CompTable from '../../comp-table';
import AddEditCustomerModal from './AddEditCustomerModal';
import CustomerCard from './CustomerCard';
import CustomerGeneralInfoSidebar from './CustomerGeneralInfoSidebar';
import CustomerList from './CustomerList';
import CustomerShareSidebar from './CustomerShareSidebar';
import InviteCustomerModal from './InviteCustomerModal';
import ReapplyCompModal from './ReapplyCompModal';
import ViewAppointmentModal from './ViewAppointmentModal';
import UserLogsModal from './strategies/UserLogsModal';
import ComparisonChart from './strategies/charts/ComparisonChart';
import {
  addMonthAndYear,
  areMonthYearEqual,
  createDefaultStrategies,
  findDiffInNumberOfMonths,
  getUpdatedLinkedVariablesOfLiability,
  updateNumberPercentage
} from './strategies/helper';
import AddEditCustomerProfileModal from './AddEditCustomerProfileModal';
import { useScreenWidth } from '../../../../hooks/useScreenWidth';

const customerHeaders = [
  { name: 'Name', key: 'name', linkToKey: 'customerPanel' },
  { name: 'Email', key: 'email', type: 'email' },
  { name: 'Secondary Name', key: 'secondaryName', linkToKey: 'customerPanel' },
  { name: 'Secondary Email', key: 'secondaryEmail', type: 'email' },
  { name: 'Status', key: 'status' },
  { name: 'Program Type', key: 'programType' },
  { name: 'Show General Info', key: 'showGeneralInfo' },
  { name: 'Example User', key: 'isPublic' },
  { name: 'Level', key: 'level' },
  { name: 'Allowed Calculators', key: 'allowedCalculators' },
  { name: 'Phone Number', key: 'phone', type: 'phone' },
  { name: 'Secondary Phone Number', key: 'secondaryPhone', type: 'phone' },
  { name: 'Address', key: 'address' },
  { name: 'Added On', key: 'createdAt', type: 'date' },
  { name: 'Start Date', key: 'startDate', type: 'date' },
  { name: 'Additional Info', key: 'additionalInfo' },
  { name: 'Active', key: 'active', showAsBadge: true, type: 'boolean' },
  { name: SALES_ROLE, key: 'salesperson' },
  { name: FINANCIAL_STRATEGIST_ROLE, key: 'financialStrategist' },
  { name: REAL_ESTATE_ANALYST_ROLE, key: 'realEstateAnalyst' }
];

const getCustomerFromObj = customerObj => {
  const returnObj = {};
  customerHeaders.forEach(h => (returnObj[h.key] = customerObj[h.key]));
  return returnObj;
};

const fieldsToExcludeForClone = [
  '_id',
  'crmContact',
  'name',
  'email',
  'secondaryName',
  'secondaryEmail',
  'address',
  'phone',
  'secondaryPhone'
];

const getCustomerForClone = customerObj => {
  let obj = cloneDeep(customerObj);
  fieldsToExcludeForClone.forEach(h => {
    delete obj[h];
  });
  return obj;
};
const applyPresetToAsset = (presetName, compTable, asset, selectedFields = []) => {
  if (!presetName) return;

  const preset = compTable?.presets?.find(p => p.name === presetName);
  if (!preset) return;

  presetTableHeaders.forEach(({ key, mapsToElem, isPercentageField, numberField, onlyForLoan }) => {
    if (
      !selectedFields.includes(key) ||
      onlyForLoan ||
      !preset[key] ||
      !mapsToElem ||
      !asset.hasOwnProperty(isPercentageField && numberField ? numberField : mapsToElem)
    )
      return;

    try {
      if (!fieldsWithFormulas.includes(key)) {
        asset[mapsToElem] = preset[key];
        return;
      }

      const assetValue = asset.value;
      const presetValue = evalFormula(preset[key], compTable.variables) || 0;

      if (isPercentageField && numberField) {
        asset[numberField] = (assetValue * presetValue) / 100;
      } else {
        asset[mapsToElem] = presetValue;
      }
    } catch (e) {
      console.log(e);
    }
  });
  //TODO also recalculate tax
};

const applyPresetToLiability = (presetName, compTable, liability, assetForLiability, selectedFields = []) => {
  if (!presetName) return;

  const preset = compTable?.presets?.find(p => p.name === presetName);
  if (!preset) return;

  presetTableHeaders.forEach(({ key, mapsToElem, isPercentageField, numberField }) => {
    if (!selectedFields.includes(key)) return;

    try {
      if (
        !preset[key] ||
        !mapsToElem ||
        !liability.hasOwnProperty(isPercentageField && numberField ? numberField : mapsToElem)
      )
        return;

      if (!fieldsWithFormulas.includes(key)) {
        liability[mapsToElem] = preset[key];
        return;
      }

      const assetValue = assetForLiability.value;
      const liabilityValue = liability.value;
      const presetValue = evalFormula(preset[key], compTable.variables) || 0;

      if (isPercentageField && numberField) {
        if (key === 'loanValue') {
          liability[numberField] = (assetValue * presetValue) / 100;
        } else {
          liability[numberField] = (liabilityValue * presetValue) / 100;
        }
      } else {
        liability[mapsToElem] = presetValue;
      }
    } catch (e) {
      console.log(e);
    }
  });
};

const UpdateStrategyTimeModal = ({
  show,
  onHide,
  showProgress,
  translate,
  strategyStartMonth,
  strategyStartYear,
  onStartMonthChange,
  onStartYearChange,
  strategyTotalYears,
  onTotalYearsChange,
  onStrategyStartDateUpdateSubmit
}) => {
  return (
    <Modal size="lg" show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton={!showProgress}>
        <Modal.Title>
          <h6 className="mb-0 d-inline-block">{translate('update_strategy_start_time')}</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-auto">
        <div className="mt-1 p-2">
          <Row>
            <Col xs={4}>
              <h6 className="mid">
                {translate('start_month')}
                <span className="text-danger">*</span>
              </h6>
              <FormSelect
                size="sm"
                id="startMonth"
                onChange={onStartMonthChange}
                value={strategyStartMonth}
                name="startMonth"
              >
                {monthSmall.map((month, index) => {
                  return (
                    <option value={index} key={index}>
                      {month}
                    </option>
                  );
                })}
              </FormSelect>
            </Col>
            <Col xs={4}>
              <h6 className="mid">
                {translate('start_year')}
                <span className="text-danger">*</span>
              </h6>
              <FormControl
                size="sm"
                type="number"
                id="startYear"
                onChange={onStartYearChange}
                value={strategyStartYear}
                name="startYear"
              />
            </Col>
            <Col xs={4}>
              <h6 className="mid">
                {translate('total_years')}
                <span className="text-danger">*</span>
              </h6>
              <FormControl
                size="sm"
                type="number"
                id="totalYears"
                onChange={onTotalYearsChange}
                value={strategyTotalYears}
                name="totalYears"
              />
            </Col>
          </Row>
          <h6 className="smallFont mt-2 text-muted">{translate('update_start_date_note_for_strategies')}</h6>
          <Row className="mt-4">
            <Col xs={12} className={'mt-2 mt-md-0'}>
              <Button size="sm" onClick={onStrategyStartDateUpdateSubmit} disabled={showProgress}>
                {translate('update')}
              </Button>
            </Col>
          </Row>
        </div>

        {showProgress && <HorizontalProgress text={`${translate('updating_strategy_time')}...`} />}
      </Modal.Body>
    </Modal>
  );
};

const CompTableSidebar = ({ customer, show, onHide, onCompUpdate, translate }) => (
  <SlidingSideBar
    fullScreen
    visible={show}
    onClose={onHide}
    showCloseButton
    title={`${translate('comp_table')} (${customer?.name || translate('no_name')})`}
  >
    {show && <CompTable compId={customer.comp._id} onCompUpdate={onCompUpdate} />}
  </SlidingSideBar>
);

const ComparisonChartSidebar = ({ customer, activeStrategy, show, onHide, translate, adminCompTable }) => (
  <SlidingSideBar
    fullScreen
    visible={show}
    onClose={onHide}
    showCloseButton
    title={`${translate('compare_strategies')} (${customer?.name || translate('no_name')})`}
  >
    {show && (
      <ComparisonChart
        activeStrategy={activeStrategy}
        strategies={customer.strategies}
        customerCompTable={customer['comp']}
        adminCompTable={adminCompTable}
      />
    )}
  </SlidingSideBar>
);

const IrrCalculatorSidebar = ({
  customer,
  show,
  onHide,
  onCustomerUpdate,
  translate,
  adminCompTable,
  readOnlyMode,
  assigneUsers
}) => {
  return (
    <SlidingSideBar
      fullScreen
      visible={show}
      onClose={onHide}
      showCloseButton
      title={`${translate('irr_calculator')} (${customer?.name})`}
    >
      {show && (
        <RealEstateIRR
          adminCompTable={adminCompTable}
          customer={customer}
          onCustomerUpdate={onCustomerUpdate}
          readOnlyMode={readOnlyMode}
          assigneUsers={assigneUsers}
        />
      )}
    </SlidingSideBar>
  );
};

const PinuiBinuiIrrCalculatorSidebar = ({
  customer,
  show,
  onHide,
  onCustomerUpdate,
  translate,
  adminCompTable,
  readOnlyMode,
  assigneUsers
}) => (
  <SlidingSideBar
    fullScreen
    visible={show}
    onClose={onHide}
    showCloseButton
    title={`${translate('pinui_binui')} (${customer?.name})`}
  >
    {show && (
      <PinuiBinuiIRR
        adminCompTable={adminCompTable}
        customer={customer}
        onCustomerUpdate={onCustomerUpdate}
        readOnlyMode={readOnlyMode}
        assigneUsers={assigneUsers}
      />
    )}
  </SlidingSideBar>
);

const PaperApartmentCalculatorSidebar = ({
  customer,
  show,
  onHide,
  onCustomerUpdate,
  translate,
  adminCompTable,
  readOnlyMode,
  assigneUsers
}) => (
  <SlidingSideBar
    fullScreen
    visible={show}
    onClose={onHide}
    showCloseButton
    title={`${translate('paper_apartment')} (${customer?.name})`}
  >
    {show && (
      <PaperApartmentIRR
        adminCompTable={adminCompTable}
        customer={customer}
        onCustomerUpdate={onCustomerUpdate}
        readOnlyMode={readOnlyMode}
        assigneUsers={assigneUsers}
      />
    )}
  </SlidingSideBar>
);

const RSUOptionsCalculatorSidebar = ({
  customer,
  show,
  onHide,
  onCustomerUpdate,
  translate,
  adminCompTable,
  readOnlyMode,
  assigneUsers
}) => (
  <SlidingSideBar
    fullScreen
    visible={show}
    onClose={onHide}
    showCloseButton
    title={`${translate('rsu_options')} (${customer?.name})`}
  >
    {show && (
      <RSUOptions
        adminCompTable={adminCompTable}
        customer={customer}
        onCustomerUpdate={onCustomerUpdate}
        readOnlyMode={readOnlyMode}
        assigneUsers={assigneUsers}
      />
    )}
  </SlidingSideBar>
);

const Customer = ({ assigneUsers, compTable: adminCompTable }) => {
  const { user } = useAuth();
  const { search } = useLocation();
  const { screenWidth } = useScreenWidth();
  const searchParams = new URLSearchParams(search);
  const customerEmails = searchParams.get('customerEmails');

  const role = useMemo(() => user.role, [user]);
  const readOnlyMode = useMemo(() => !checkIfUserCanEdit(user), [user]);

  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('All');
  const [showAlertMeta, setShowAlertMeta] = useState(null);
  const [customerActionMeta, setCustomerActionMeta] = useState(null);
  const [updateStrategyTimeMeta, setUpdateStrategyTimeMeta] = useState(null);
  const [updatingStrategyTime, setUpdatingStrategyTime] = useState(false);
  const [customerAddingUpdating, setCustomerAddingUpdating] = useState(false);
  const [viewAppointmentModalMeta, setViewAppointmentModalMeta] = useState(null);
  //? this is the active customer selected from list
  const [parentCustomer, setParentCustomer] = useState();

  //? a parent customer can also have multiple child customer, a customer is selected from one of these
  //? this is the currently active customer from parent customer [this can also be the parent customer itself]
  const [activeCustomer, setActiveCustomer] = useState();
  const [initialCustomers, setInitialCustomers] = useState([]);
  //collapsed for smaller screens
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(() => window.innerWidth < 768);
  const [showCompTable, setShowCompTable] = useState(false);
  const [showCustomerGeneralInfo, setShowCustomerGeneralInfo] = useState(false);

  const [showComparisonChart, setShowComparisonChart] = useState(false);
  const [showIrrCalculator, setShowIrrCalculator] = useState(false);
  const [showPinuiBinuiIrrCalculator, setShowPinuiBinuiIrrCalculator] = useState(false);
  const [showPaperApartmentCalculator, setShowPaperApartmentCalculator] = useState(false);
  const [showRSUOptionsCalculator, setShowRSUOptionsCalculator] = useState(false);

  const [activeCustomerStrategy, setActiveCustomerStrategy] = useState();
  const [reapplyCompModalMeta, setReapplyCompModalMeta] = useState(null);
  const [shareDialogMeta, setShareDialogMeta] = useState(null);

  const { translate, langCode } = useContext(LocalizeContext);

  const sortNameToField = useMemo(() => getCustomerSortNameToField(translate), [langCode]);
  const sortOptions = useMemo(() => Object.keys(sortNameToField), [sortNameToField]);
  const maxLimitOptions = useMemo(() => ['all', 50, 100, 200], []);

  const [showFiles, setShowFiles] = useState(false);
  const [isFileUploadingToCustomer, setFileUploadingToCustomer] = useState(false);
  const [deleteFileModalMeta, setDeleteFileModalMeta] = useState(null);
  const [inviteCustomerModalMeta, setInviteCustomerModalMeta] = useState(null);
  const [showLogsModal, setShowLogsModal] = useState(false);
  const [customerUpdating, setCustomerUpdating] = useState(false);

  const [customerProfileMeta, setCustomerProfileMeta] = useState(null);
  const [deleteApartmentProfileMeta, setDeleteApartmentProfileMeta] = useState(null);
  const [lastRequestQuery, setLastRequestQuery] = useState('');

  const fetchCustomers = async ({ sortBy, maxLimit, descSort, filters, query, showArchived }) => {
    setLoading(true);
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_LIST,
      requestBody: {
        filter: {
          role: 'Customer',
          active: filters['Active'].map(a => (a === 'Yes' ? true : false)),
          archived: showArchived ? undefined : false
        },
        query,
        maxLimit: maxLimit === 'all' ? undefined : maxLimit,
        sort: { [sortNameToField[sortBy]]: descSort ? 1 : -1 },
        compactMode: true
      }
    });
    setLoading(false);

    if (error) {
      toast.error(error);
      return;
    }

    let customersList = response.map(s => ({
      ...s,
      startDate: s.startDate ?? s.createdAt,
      customerPanel: s['active'] ? `${APP_URL}/customer/${s['_id']}` : undefined
    }));
    setLastRequestQuery(query);
    setCustomers(customersList);
    setInitialCustomers(customersList);
  };

  useEffect(() => {
    let newActiveCustomerIndex = -1;

    // Check if there's a parentCustomer and find its index in the customers array
    if (parentCustomer) {
      newActiveCustomerIndex = customers.findIndex(c => c['_id'] === parentCustomer['_id']);
    }

    // If there is a last request query or the parent customer was not found
    // we want to select the contact whose name matches exactly the search query
    // or default to first one in the status
    if (lastRequestQuery || newActiveCustomerIndex === -1) {
      // Determine the active customer index based on the selected status
      newActiveCustomerIndex =
        selectedStatus === 'All'
          ? customers.findIndex(
              c => c.status !== STATUS_INTEREST_IN_MY_COURSE && (!lastRequestQuery || c.name === lastRequestQuery)
            )
          : -1;
    }

    // Get the customer object based on the determined index or default to the first customer
    const customer = customers[newActiveCustomerIndex !== -1 ? newActiveCustomerIndex : 0];

    // Update the parentCustomer state with the selected customer or set it to undefined
    setParentCustomer(customer ? { ...customer } : undefined);

    // Clear the last request query
    setLastRequestQuery('');
  }, [customers, selectedStatus, lastRequestQuery]);

  useEffect(() => {
    if (!parentCustomer) {
      setActiveCustomer();
      return;
    }

    if (!activeCustomer) {
      setActiveCustomer(parentCustomer);
      return;
    }

    const activeInAccessible = parentCustomer.accessibleUsers?.find(c => c['_id'] === activeCustomer['_id']);

    setActiveCustomer(activeInAccessible || parentCustomer);
  }, [parentCustomer]);

  const onActiveCustomerSwitched = customer => {
    setActiveCustomer(customer);
  };

  const onCustomerUpdate = newCustomer => {
    if (!newCustomer.email) {
      newCustomer.email = '';
    }

    // update delegations and accessibles
    customers.forEach((c, index) => {
      if (c._id === newCustomer._id) {
        customers[index] = { ...customers[index], ...newCustomer };
      }

      ['accessibleUsers', 'delegations'].forEach(key => {
        if (!customers[index][key]) return;

        customers[index][key] = customers[index][key].map(oc => {
          return oc._id === newCustomer._id ? newCustomer : oc;
        });
      });
    });

    setCustomers([...customers]);
  };

  const onCustomerDelete = deletedCustomer => {
    customers.splice(
      customers.findIndex(a => a['_id'] === deletedCustomer['_id']),
      1
    );

    // update delegations and accessibles
    customers.forEach((c, index) => {
      ['accessibleUsers', 'delegations'].forEach(key => {
        if (customers[index][key])
          customers[index][key] = customers[index][key].filter(oc => oc._id !== deletedCustomer._id);
      });
    });

    setCustomers([...customers]);
  };

  const onInviteCustomerClick = () => {
    setInviteCustomerModalMeta({
      hasAlreadyBeenInvited: parentCustomer.userId,
      customer: parentCustomer,
      password: generateAlphanumericString(8),
      receiverEmails: [parentCustomer.email, parentCustomer.secondaryEmail].filter(e => Boolean(e?.trim()))
    });
  };

  const onNewCustomerFormSubmit = async form => {
    const {
      name,
      boardName,
      email,
      phone,
      secondaryName,
      secondaryEmail,
      secondaryPhone,
      address,
      status,
      allowedCalculators,
      additionalInfo,
      startDate,
      programType,
      level,
      showGeneralInfo,
      isPublic,
      salesperson,
      financialStrategist,
      realEstateAnalyst,
      crmContact
    } = form;

    const { toBeClonedCustomer } = customerActionMeta;

    if (!name?.trim() && !email?.trim()) {
      return toast.error('Either name or email is required!');
    }

    setCustomerAddingUpdating(true);

    const newCustomer = {
      boardName,
      name,
      email,
      phone,
      role: 'Customer',
      address,
      status,
      allowedCalculators,
      strategies: toBeClonedCustomer ? [] : createDefaultStrategies(),
      additionalInfo,
      startDate,
      programType,
      generalInformation: level > 1 ? adminCompTable?.customerGeneralInformation : null,
      secondaryName,
      secondaryEmail,
      secondaryPhone,
      cloneFrom: toBeClonedCustomer?._id,
      level,
      showGeneralInfo,
      isPublic,
      crmContact,
      ...(isAdmin(user?.role) ? { salesperson, financialStrategist, realEstateAnalyst } : {})
    };

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_BASE,
      requestBody: newCustomer
    });

    setCustomerAddingUpdating(false);
    if (error) {
      toast.error(error);
      return;
    }

    setCustomers([response, ...customers]);
    setParentCustomer(response);
    setCustomerActionMeta(null);
  };

  const onUpdateCustomerFormSubmit = async form => {
    const {
      boardName,
      name,
      email,
      phone,
      address,
      status,
      allowedCalculators,
      additionalInfo,
      startDate,
      programType,
      secondaryName,
      secondaryEmail,
      secondaryPhone,
      level,
      showGeneralInfo,
      isPublic,
      salesperson,
      financialStrategist,
      realEstateAnalyst,
      crmContact
    } = form;

    if (!name?.trim() && !email?.trim()) {
      return toast.error('Either name or email is required!');
    }

    setCustomerAddingUpdating(true);

    const updatedCustomer = {
      boardName,
      name,
      phone,
      email,
      address,
      status,
      allowedCalculators,
      additionalInfo,
      startDate,
      programType,
      secondaryName,
      secondaryEmail,
      secondaryPhone,
      level,
      showGeneralInfo,
      isPublic,
      crmContact,
      ...(isAdmin(user?.role) ? { salesperson, financialStrategist, realEstateAnalyst } : {})
    };

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_UPDATE,
      requestBody: { ...updatedCustomer, _id: customerActionMeta.customer._id }
    });

    setCustomerAddingUpdating(false);
    if (error) {
      toast.error(error);
      return;
    }

    onCustomerUpdate(response);
    setCustomerActionMeta(null);
  };

  const onCustomerDetailChange = customer => {
    setCustomerActionMeta({ ...customerActionMeta, customer });
  };

  const onCustomerSubmit = () => {
    const { mode, customer } = customerActionMeta;
    if (mode === 'edit') {
      onUpdateCustomerFormSubmit(customer);
    } else {
      onNewCustomerFormSubmit(customer);
    }
  };

  const deleteCustomer = async () => {
    setShowAlertMeta({ ...showAlertMeta, showProgress: true });

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_BASE,
      method: 'DELETE',
      requestBody: { _id: parentCustomer._id }
    });

    setShowAlertMeta({ ...showAlertMeta, showProgress: false });

    if (error) {
      toast.error(error);
      return;
    }

    onCustomerDelete(parentCustomer);

    setShowAlertMeta(null);
    toast.success('Customer deleted successfully');
  };

  const archiveOrUnarchiveCustomer = async () => {
    setShowAlertMeta({ ...showAlertMeta, showProgress: true });

    const isArchived = Boolean(parentCustomer?.['archived']);
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_UPDATE,
      requestBody: { archived: !isArchived, _id: parentCustomer._id }
    });

    setShowAlertMeta({ ...showAlertMeta, showProgress: false });

    if (error) {
      toast.error(error);
      return;
    }

    onCustomerUpdate(response);
    setShowAlertMeta(null);
    toast.success(`Customer ${isArchived ? 'unarchived' : 'archived'} successfully`);
  };

  const onIrrCalculatorClick = () => {
    setShowIrrCalculator(true);
  };

  const onPinuiBinuiClick = () => {
    setShowPinuiBinuiIrrCalculator(true);
  };

  const onPaperApartmentClick = () => {
    setShowPaperApartmentCalculator(true);
  };

  const onRSUOptionsClick = () => {
    setShowRSUOptionsCalculator(true);
  };

  const onDeleteCustomerClick = () => {
    setShowAlertMeta({
      progressText: `${translate('deleting_customer')}...`,
      onContinueClick: deleteCustomer,
      alertText: translate('are_you_sure_you_want_to_delete_customer', {
        userName: parentCustomer?.['name'] || 'No name'
      })
    });
  };

  const onArchiveCustomerClick = () => {
    setShowAlertMeta({
      progressText: `${translate(parentCustomer?.['archived'] ? 'unarchiving_customer' : 'archiving_customer')}...`,
      onContinueClick: archiveOrUnarchiveCustomer,
      alertText: translate(
        parentCustomer?.['archived']
          ? 'are_you_sure_you_want_to_unarchive_customer'
          : 'are_you_sure_you_want_to_archive_customer',
        {
          userName: parentCustomer?.['name'] || 'No name'
        }
      )
    });
  };

  const onCompareStrategyClick = () => {
    setShowComparisonChart(true);
  };

  const onAccountBalanceChange = e => {
    updateNumberPercentage('value', 'returnCashflow', 'returnCashflowPercentage', 'value');
  };
  const onReturnCashflowChange = e => {
    updateNumberPercentage('value', 'returnCashflow', 'returnCashflowPercentage', 'number');
  };
  const onReturnCashflowPercentageChange = e => {
    updateNumberPercentage('value', 'returnCashflow', 'returnCashflowPercentage', '%');
  };

  const alterExpandCollapse = () => {
    setSidebarCollapsed(prev => !prev);
  };

  window['onAccountBalanceChange'] = onAccountBalanceChange;
  window['onReturnCashflowChange'] = onReturnCashflowChange;
  window['onReturnCashflowPercentageChange'] = onReturnCashflowPercentageChange;

  const onCompUpdate = comp => {
    const activeCustomerTemp = customers.find(c => c._id === activeCustomer._id);
    activeCustomerTemp.comp = comp;

    onCustomerUpdate(activeCustomerTemp);
  };

  const onReapplyCompClick = () => {
    if (activeCustomer.strategies.length === 0) {
      return toast.error(translate('no_strategies_created'));
    }

    const fields = getPresetTableHeaders(translate).filter(h => h.mapsToElem);
    setReapplyCompModalMeta({
      strategies: activeCustomer.strategies,
      presetFields: fields,
      selectedFields: [],
      selectedStrategies: []
    });
  };

  const onShareClick = () => {
    setShareDialogMeta({ userBeingAdded: '' });
  };

  const onShareUserAddClick = async () => {
    const { userBeingAdded } = shareDialogMeta;

    if (!validateEmail(userBeingAdded)) {
      return toast.error('Please provide a valid email');
    }

    toast.info('Adding user...');
    setShareDialogMeta({ ...shareDialogMeta, showProgress: true });

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.SHARE_USER_ADD,
      requestBody: {
        email: userBeingAdded,
        customerId: parentCustomer['_id']
      }
    });

    setShareDialogMeta({ ...shareDialogMeta, showProgress: false });

    if (error) {
      return toast.error(error);
    }

    setShareDialogMeta({ ...shareDialogMeta, userBeingAdded: '' });

    onCustomerUpdate(response);

    toast.success('Customer Shared succesfully');
  };

  const onShareUserRemoveClick = async user => {
    toast.info('Removing user...');
    setShareDialogMeta({ ...shareDialogMeta, showProgress: true });

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.SHARE_USER_SUBTRACT,
      requestBody: {
        email: user.email,
        customerId: parentCustomer['_id']
      }
    });

    setShareDialogMeta({ ...shareDialogMeta, showProgress: false });

    if (error) {
      return toast.error(error);
    }

    onCustomerUpdate(response);

    toast.success('User removed successfully');
  };

  const onReapplyCompStrategyCheckChange = value => {
    const { selectedStrategies = [] } = reapplyCompModalMeta;

    if (selectedStrategies.includes(value)) {
      selectedStrategies.splice(selectedStrategies.indexOf(value), 1);
    } else {
      selectedStrategies.push(value);
    }

    setReapplyCompModalMeta({ ...reapplyCompModalMeta });
  };

  const onReapplyCompFieldCheckChange = value => {
    const { selectedFields = [] } = reapplyCompModalMeta;

    if (selectedFields.includes(value)) {
      selectedFields.splice(selectedFields.indexOf(value), 1);
    } else {
      selectedFields.push(value);
    }

    setReapplyCompModalMeta({ ...reapplyCompModalMeta });
  };

  const onStrategyAllCheck = () => {
    const { selectedStrategies = [], strategies } = reapplyCompModalMeta;

    setReapplyCompModalMeta({
      ...reapplyCompModalMeta,
      selectedStrategies: strategies.every(o => selectedStrategies.includes(o._id))
        ? []
        : reapplyCompModalMeta.strategies.map(s => s._id)
    });
  };

  const onFieldsAllCheck = () => {
    const { selectedFields = [], presetFields } = reapplyCompModalMeta;

    setReapplyCompModalMeta({
      ...reapplyCompModalMeta,
      selectedFields: presetFields.every(o => selectedFields.includes(o.key))
        ? []
        : reapplyCompModalMeta.presetFields.map(s => s.key)
    });
  };

  const reapplyComp = async () => {
    const { selectedFields = [], selectedStrategies = [] } = reapplyCompModalMeta;
    if (selectedStrategies.length === 0) {
      return toast.error('Please select atleast one strategy');
    }

    if (selectedFields.length === 0) {
      return toast.error('Please select atleast one field to reapply');
    }

    setReapplyCompModalMeta({ ...reapplyCompModalMeta, showProgress: true });

    const comp = activeCustomer.comp;

    const newStrategies = activeCustomer.strategies.map(strategy => {
      if (!selectedStrategies.includes(strategy._id)) {
        return strategy;
      }

      const clonedStrategy = cloneDeep(strategy);

      const { assets = [], liabilities = [] } = clonedStrategy;

      assets.forEach(asset => {
        applyPresetToAsset(asset.preset, comp, asset, selectedFields);
      });

      liabilities.forEach(liability => {
        const linkedAssetName = liability.relatedAssets ? liability.relatedAssets[0] : null;
        if (!linkedAssetName) return;

        const linkedAsset = assets.find(a => a.name === linkedAssetName);
        if (!linkedAsset) return;

        applyPresetToLiability(linkedAsset.preset, comp, liability, linkedAsset, selectedFields);
      });

      return clonedStrategy;
    });

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_UPDATE,
      requestBody: {
        strategies: newStrategies,
        _id: activeCustomer['_id']
      }
    });

    setReapplyCompModalMeta({ ...reapplyCompModalMeta, showProgress: false });

    if (error) {
      return toast.error(error);
    }

    onCustomerUpdate(response);
    setReapplyCompModalMeta(null);
    toast.success(`Successfully reapplied presets!`);
  };

  const onStrategyStartDateUpdateSubmit = async () => {
    const { strategyStartYear, strategyStartMonth, strategyTotalYears } = updateStrategyTimeMeta;

    if (!strategyStartYear) {
      return toast.error('Please provide a valid year!');
    }

    const yearInNumber = Number(strategyStartYear);
    const monthInNumber = Number(strategyStartMonth);

    if (isNaN(yearInNumber) || strategyStartYear.toString().length !== 4) {
      return toast.error('Please provide a valid year!');
    }

    const totalYearsInNumber = Number(strategyTotalYears);
    if (isNaN(totalYearsInNumber) || totalYearsInNumber < 1) {
      return toast.error('Total number of years must be atleast 1!');
    }

    setUpdatingStrategyTime(true);

    //set dates of all strategies to whatever is specified.
    //this means we set the initial year of each strategy to specified year
    //and move the start and end date of every instrument relatively
    const updateDatesOfInstrument = (instrument, diffInMonths, key, defaultStartMonthKey, defaultStartYearKey) => {
      const startYearKey = defaultStartYearKey || (key === 'assets' ? 'buyingYear' : 'startYear');
      const startMonthKey = defaultStartMonthKey || (key === 'assets' ? 'buyingMonth' : 'startMonth');
      const endYearKey = key === 'assets' ? 'sellingYear' : 'endYear';
      const endMonthKey = key === 'assets' ? 'sellingMonth' : 'endMonth';

      const { month: newStartMonth, year: newStartYear } = addMonthAndYear(
        instrument[startMonthKey],
        instrument[startYearKey],
        diffInMonths
      );

      instrument[startMonthKey] = newStartMonth;
      instrument[startYearKey] = newStartYear;

      if (instrument[endYearKey]) {
        const { month: newEndMonth, year: newEndYear } = addMonthAndYear(
          instrument[endMonthKey],
          instrument[endYearKey],
          diffInMonths
        );
        instrument[endMonthKey] = newEndMonth;
        instrument[endYearKey] = newEndYear;
      }
    };

    const newStrategies = parentCustomer.strategies.map(strategy => {
      const clonedStrategy = cloneDeep(strategy);
      clonedStrategy.initialYear = yearInNumber;
      clonedStrategy.initialMonth = monthInNumber;
      clonedStrategy.totalYears = totalYearsInNumber;

      const diffInMonths = findDiffInNumberOfMonths(
        strategy.initialMonth,
        strategy.initialYear,
        monthInNumber,
        yearInNumber
      );

      const {
        assets,
        liabilities,
        fixedIncomeAssets,
        oneOffChanges,
        customCashflows,
        investments,
        defaultArmageddonConfig
      } = clonedStrategy;

      [
        { key: 'assets', instruments: assets },
        { key: 'liabilities', instruments: liabilities },
        { key: 'fixedIncomeAssets', instruments: fixedIncomeAssets },
        { key: 'oneOffChanges', instruments: oneOffChanges },
        { key: 'customCashflows', instruments: customCashflows },
        { key: 'investments', instruments: investments },
        { key: 'armageddon', instruments: defaultArmageddonConfig }
      ].forEach(({ instruments, key }) => {
        if (!instruments) return;

        //this is for defaultArmageddonConfig
        if (key === 'armageddon') {
          updateDatesOfInstrument(instruments, diffInMonths, key);
          return;
        }

        instruments.forEach(i => {
          updateDatesOfInstrument(i, diffInMonths, key);

          if (i['cashflowChanges']) {
            i['cashflowChanges'].forEach(c => {
              updateDatesOfInstrument(c, diffInMonths, key);
            });
          }

          if (i['paperApartmentPayments']) {
            i['paperApartmentPayments'].forEach(c => {
              updateDatesOfInstrument(c, diffInMonths, key, 'month', 'year');
            });
          }
        });
      });

      //when we extend strategy i.e. total number of Years is changed
      //1.  liabilities which matures before the expanded date should not expand
      //     but should end at maturity, so we update the end dates of all the liabilities
      //2.  reinvestment dont have null/undefined in there ending dates,
      //    so if reinvestment ends at last month and year of strategy we need to extend it to new last month and year as well

      //1.
      clonedStrategy.liabilities = clonedStrategy.liabilities.map(l => ({
        ...l,
        ...getUpdatedLinkedVariablesOfLiability(
          clonedStrategy,
          clonedStrategy.assets.find(a => a.name === l.relatedAssets?.[0]),
          null,
          l
        )
      }));

      //2.
      const { month: prevLastMonth, year: prevLastYear } = addMonthAndYear(
        monthInNumber,
        yearInNumber,
        -1, //last month is just one month before
        strategy.totalYears
      );
      const { month: newLastMonth, year: newLastYear } = addMonthAndYear(
        monthInNumber,
        yearInNumber,
        -1,
        totalYearsInNumber
      );

      clonedStrategy.investments = clonedStrategy.investments.map(i =>
        areMonthYearEqual(i.endMonth, i.endYear, prevLastMonth, prevLastYear)
          ? { ...i, endMonth: newLastMonth, endYear: newLastYear }
          : i
      );

      return clonedStrategy;
    });

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_UPDATE,
      requestBody: {
        strategies: newStrategies,
        _id: activeCustomer['_id']
      }
    });

    setUpdatingStrategyTime(false);

    if (error) {
      return toast.error(error);
    }

    onCustomerUpdate(response);
    setUpdateStrategyTimeMeta(null);
    toast.success(`Successfully updated strategies!`);
  };

  const onViewFilesClick = () => {
    setShowFiles(true);
  };

  const onViewAppointmentClick = () => {
    setViewAppointmentModalMeta(parentCustomer);
  };

  const onFileDeleteClick = file => {
    setDeleteFileModalMeta({ file });
  };

  const deleteFile = async () => {
    const { file: toBeDeletedFile } = deleteFileModalMeta;
    setDeleteFileModalMeta({ ...deleteFileModalMeta, deletingFile: true });

    const newFileArray = activeCustomer.files.filter(f => f._id !== toBeDeletedFile._id).map(f => f._id);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_UPDATE,
      requestBody: {
        files: newFileArray,
        _id: activeCustomer['_id']
      }
    });

    setDeleteFileModalMeta({ ...deleteFileModalMeta, deletingFile: false });
    if (error) {
      return toast.error(error);
    }

    setDeleteFileModalMeta(null);
    onCustomerUpdate(response);
    deleteFileFromServer(toBeDeletedFile);
  };

  const onUploadFileChange = async (uploadedFiles = []) => {
    if (!uploadedFiles.length || uploadedFiles.some(f => ['WAITING_FOR_UPLOAD', 'UPLOADING'].includes(f.status)))
      return;

    let filesToUpload = uploadedFiles.filter(f => f.status !== 'ERROR');

    setFileUploadingToCustomer(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_UPDATE,
      requestBody: {
        files: [...activeCustomer.files.map(f => f._id), ...filesToUpload.map(f => f.id)],
        _id: activeCustomer['_id']
      }
    });

    setFileUploadingToCustomer(false);

    if (error) {
      return toast.error(error);
    }

    onCustomerUpdate(response);

    if (uploadedFiles.length !== filesToUpload.length)
      return toast.error(
        'Some of the files were not uploaded due to some error. Please try uploading the files again!'
      );
  };

  const onShowAllStrategiesClick = async () => {
    setCustomerAddingUpdating(true);

    const { customer } = customerActionMeta;

    const showToCustomer = !customer.strategies.every(s => s.showToCustomer);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_UPDATE,
      requestBody: {
        strategies: customer.strategies.map(s => ({ ...s, showToCustomer })),
        _id: customer['_id']
      }
    });

    setCustomerAddingUpdating(false);

    if (error) {
      return toast.error(error);
    }

    onCustomerUpdate(response);
    setCustomerActionMeta(null);
    toast.success(`Successfully updated strategies!`);
  };

  const makePhoneCall = async () => {
    const { phone, crmContact } = activeCustomer;
    const phoneNumber = phone || crmContact?.phoneNumbers[0] || '';
    if (!phoneNumber) {
      return toast.info('No phone number available');
    }

    const { response, error } = await makeApiRequests({
      requestBody: {
        phoneNumber
      },
      endpoint: ENDPOINTS.DIAL_PHONE_NUMBER,
      method: 'POST'
    });

    if (error) {
      toast.error(error);
      return;
    }
  };

  const onInviteCustomerSubmit = async () => {
    const { receiverEmails, customer } = inviteCustomerModalMeta;

    // Checking if userId and password is not blank or has atleast 6 characters, there should be atleast one receiver email

    if (!receiverEmails || receiverEmails.length === 0) {
      return toast.error('There must be at least one receiver email.');
    }

    setInviteCustomerModalMeta({ ...inviteCustomerModalMeta, showProgress: true });

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.FORGOT_PASSWORD,
      requestBody: { _id: customer._id, receiverEmails, email: customer?.email }
    });

    setInviteCustomerModalMeta({ ...inviteCustomerModalMeta, showProgress: false });
    if (error) {
      return toast.error(error);
    }

    toast.success('Password reset link was successfully sent');
    setInviteCustomerModalMeta(null);
    onCustomerUpdate(response);
  };

  const onLogsClick = async () => {
    setShowLogsModal(true);
  };

  const onUndoClick = async () => {
    undoRedo('undo');
  };
  const onRedoClick = async () => {
    undoRedo('redo');
  };
  const onUndoRedoClick = async (action, diffId) => {
    undoRedo(action, diffId);
  };

  const undoRedo = async (action, diffId) => {
    setCustomerUpdating(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_DIFF_ACTION(parentCustomer._id, action),
      requestBody: { id: diffId }
    });

    setCustomerUpdating(false);
    if (error) {
      return toast.error(error);
    }

    toast.success(`${action.toUpperCase()} done successfully!`);
    setInviteCustomerModalMeta(null);
    onCustomerUpdate(response);
  };

  const onAddProfileClick = data => {
    setCustomerProfileMeta(data);
  };

  const onDeleteApartmentProfileClick = userProfile => {
    setDeleteApartmentProfileMeta({
      profileToDelete: userProfile
    });
  };

  const deleteApartmentProfile = async () => {
    try {
      let userProfileToDelete = deleteApartmentProfileMeta?.profileToDelete;
      setDeleteApartmentProfileMeta({ ...deleteApartmentProfileMeta, showProgress: true });

      let updateApartmentProfiles = activeCustomer?.apartmentProfiles?.filter(
        ap => ap?._id !== userProfileToDelete?._id
      );

      const { response, error } = await makeApiRequests({
        endpoint: ENDPOINTS.USERS_UPDATE,
        requestBody: {
          _id: activeCustomer._id,
          apartmentProfiles: updateApartmentProfiles
        }
      });

      if (error) {
        setDeleteApartmentProfileMeta({ ...deleteApartmentProfileMeta, showProgress: false });
        toast.error(error);
        return;
      }
      setDeleteApartmentProfileMeta(null);
      setCustomerProfileMeta(null);
      onCustomerUpdate(response);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitCustomerProfile = async customerProfile => {
    try {
      setCustomerProfileMeta({ ...customerActionMeta, showProgress: true });
      const { response, error } = await makeApiRequests({
        endpoint: ENDPOINTS.USERS_UPDATE,
        requestBody: {
          _id: activeCustomer._id,
          apartmentProfiles: updateItemsInArray(activeCustomer?.apartmentProfiles, customerProfile)
        }
      });

      if (error) {
        setCustomerProfileMeta({ ...customerActionMeta, showProgress: false });

        toast.error(error);
        return;
      }
      setCustomerProfileMeta(null);
      onCustomerUpdate(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomerRecommendation = async query => {
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_LIST,
      requestBody: {
        filter: {
          role: 'Customer'
        },
        compactMode: true,
        query,
        maxLimit: 5,
        sort: { updatedAt: -1 }
      }
    });

    if (error) {
      return [];
    }

    return response.map(r => r.name);
  };

  const onViewCRMContactClick = async contact => {
    if (contact) {
      if (contact?._id) {
        window.open(`${process.env.REACT_APP_CRM_APP_LINK}/contacts?contact=${contact._id}&view=contact`, '_blank');
      }
    }
  };

  const customerEmailsToSearch = useMemo(() => customerEmails?.split(','), [customerEmails]);

  useEffect(() => {
    if (!(customerEmailsToSearch?.length && initialCustomers?.length)) return;

    const customerList = cloneDeep(initialCustomers);
    const foundCustomer = customerList.find(customer =>
      customerEmailsToSearch.some(email => email?.toLowerCase()?.trim() === customer?.email?.toLowerCase()?.trim())
    );

    if (!foundCustomer) {
      // toast.error('Customer not found');
      return;
    }

    const index = customerList.findIndex(c => c._id === foundCustomer._id);

    if (index !== -1) {
      const [customer] = customerList.splice(index, 1);
      customerList.unshift(customer);
    }

    setCustomers(customerList);
    setParentCustomer(foundCustomer);
  }, [initialCustomers, customerEmailsToSearch]);

  return (
    <>
      <Container fluid className={'h-100 py-2 px-md-3 bg-light'}>
        <SearchBox
          containerClass="bg-white rounded px-2 py-3"
          filterOptions={searchFilterOptions}
          sortByOptions={sortOptions}
          maxLimitOptions={maxLimitOptions}
          onSearchOptionsChange={fetchCustomers}
          disabled={loading}
          recommendationGetter={getCustomerRecommendation}
          showArchiveCheckbox
        />
        {loading && <HorizontalProgress text={`${translate('fetching_customers')}...`} />}
        {customers.length > 0 ? (
          <>
            <Row className="h-100 py-2 mx-0">
              {screenWidth >= 768 && (
                <Col
                  xs={0}
                  md={isSidebarCollapsed ? 0 : 2}
                  lg={isSidebarCollapsed ? 0 : 2}
                  xl={isSidebarCollapsed ? 0 : 2}
                  className="p-0 pb-5 overflow-auto h-100 overlapping-list d-none d-md-block"
                >
                  <Card>
                    <Card.Body className="px-1 py-2">
                      <CustomerList
                        selectedStatus={selectedStatus}
                        setSelectedStatus={setSelectedStatus}
                        customers={customers}
                        activeId={parentCustomer ? parentCustomer['_id'] : ''}
                        onSelect={customer => {
                          delete customer.loadingError;
                          setActiveCustomer(customer);
                          setParentCustomer(customer);
                        }}
                        onHoverInactive={() => {
                          setSidebarCollapsed(true);
                        }}
                        hoverDelay={5000}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              )}
              <Col
                xs={12}
                md={isSidebarCollapsed ? 12 : 10}
                lg={isSidebarCollapsed ? 12 : 10}
                xl={isSidebarCollapsed ? 12 : 10}
                className="px-1"
              >
                {parentCustomer && activeCustomer && (
                  <>
                    <CustomerCard
                      onViewCRMContactClick={onViewCRMContactClick}
                      adminCompTable={adminCompTable}
                      parentCustomer={parentCustomer}
                      activeCustomer={activeCustomer}
                      onActiveCustomerSwitched={onActiveCustomerSwitched}
                      onCompTableClick={() => setShowCompTable(true)}
                      onCustomerGeneralInfoClick={() => setShowCustomerGeneralInfo(true)}
                      onEditClick={() =>
                        setCustomerActionMeta({
                          customer: parentCustomer,
                          mode: 'edit',
                          strategyStartYear: parentCustomer['strategies'][0]?.initialYear || new Date().getFullYear(),
                          strategyStartMonth: parentCustomer['strategies'][0]?.initialMonth || 0,
                          strategyTotalYears: parentCustomer['strategies'][0]?.totalYears || 10
                        })
                      }
                      onUpdateStrategyTimeClick={() =>
                        setUpdateStrategyTimeMeta({
                          customer: parentCustomer,
                          mode: 'edit',
                          strategyStartYear: parentCustomer['strategies'][0]?.initialYear || new Date().getFullYear(),
                          strategyStartMonth: parentCustomer['strategies'][0]?.initialMonth || 0,
                          strategyTotalYears: parentCustomer['strategies'][0]?.totalYears || 10
                        })
                      }
                      onDeleteClick={isAdmin(role) ? onDeleteCustomerClick : undefined}
                      onArchiveClick={onArchiveCustomerClick}
                      onCompareStrategyClick={
                        activeCustomer['strategies']?.filter(s => !s.killed).length > 0 && onCompareStrategyClick
                      }
                      onLogsClick={onLogsClick}
                      onUndoClick={onUndoClick}
                      onRedoClick={onRedoClick}
                      onCollapseIconClick={alterExpandCollapse}
                      isSidebarCollapsed={isSidebarCollapsed}
                      onIrrCalculatorClick={onIrrCalculatorClick}
                      onPinuiBinuiClick={onPinuiBinuiClick}
                      onPaperApartmentClick={onPaperApartmentClick}
                      onRSUOptionsClick={onRSUOptionsClick}
                      onCustomerUpdate={onCustomerUpdate}
                      onReapplyCompClick={onReapplyCompClick}
                      onSelectedStrategyChange={setActiveCustomerStrategy}
                      onShareClick={onShareClick}
                      onViewFilesClick={onViewFilesClick}
                      onViewAppointmentClick={onViewAppointmentClick}
                      onUploadFileChange={onUploadFileChange}
                      isFileUploadingToCustomer={isFileUploadingToCustomer}
                      customerUpdating={customerUpdating || isFileUploadingToCustomer}
                      showCollapseIcon
                      makePhoneCall={makePhoneCall}
                      onAddProfileClick={onAddProfileClick}
                    />
                    <FileViewer
                      show={showFiles}
                      onHide={() => setShowFiles(false)}
                      customerForThisDriveFolder={activeCustomer}
                      onCustomerUpdate={onCustomerUpdate}
                      driveFolder={activeCustomer.driveFolder}
                      files={activeCustomer.files}
                      onFileDeleteClick={onFileDeleteClick}
                    />

                    <AlertModal
                      show={deleteFileModalMeta !== null}
                      alertText={translate('are_you_sure_delete_file', {
                        fileName:
                          deleteFileModalMeta?.file?.metadata?.originalName || deleteFileModalMeta?.file?.filename || ''
                      })}
                      showProgress={deleteFileModalMeta && deleteFileModalMeta.deletingFile}
                      progressText={`${translate('deleting_file')}...`}
                      continueButtonText={translate('delete')}
                      onDismissClick={() => setDeleteFileModalMeta(null)}
                      onHide={() => setDeleteFileModalMeta(null)}
                      onContinueClick={deleteFile}
                    />
                    <CustomerShareSidebar
                      customer={activeCustomer}
                      show={shareDialogMeta}
                      onHide={() => setShareDialogMeta(null)}
                      onUserRemoveClick={onShareUserRemoveClick}
                      userBeingAdded={shareDialogMeta?.userBeingAdded}
                      onUserBeingAddedChange={email =>
                        setShareDialogMeta({ ...shareDialogMeta, userBeingAdded: email })
                      }
                      onAddUserClick={onShareUserAddClick}
                      showProgress={shareDialogMeta?.showProgress}
                      translate={translate}
                    />
                    <CompTableSidebar
                      customer={activeCustomer}
                      show={showCompTable}
                      onHide={() => setShowCompTable(false)}
                      onCompUpdate={onCompUpdate}
                      translate={translate}
                    />
                    <CustomerGeneralInfoSidebar
                      customer={parentCustomer}
                      show={showCustomerGeneralInfo}
                      onHide={() => setShowCustomerGeneralInfo(false)}
                      onCustomerUpdate={onCustomerUpdate}
                      translate={translate}
                    />
                    {activeCustomer.strategies?.length > 0 && (
                      <ReapplyCompModal
                        show={reapplyCompModalMeta !== null}
                        presetFields={reapplyCompModalMeta?.presetFields}
                        strategies={reapplyCompModalMeta?.strategies}
                        selectedStrategies={reapplyCompModalMeta?.selectedStrategies}
                        selectedFields={reapplyCompModalMeta?.selectedFields}
                        onStrategyCheckChange={onReapplyCompStrategyCheckChange}
                        onFieldCheckChange={onReapplyCompFieldCheckChange}
                        onHide={() => setReapplyCompModalMeta(null)}
                        onStrategyAllCheck={onStrategyAllCheck}
                        onFieldsAllCheck={onFieldsAllCheck}
                        onApplyClick={reapplyComp}
                        showProgress={reapplyCompModalMeta?.showProgress}
                      />
                    )}
                    {[
                      {
                        show: showIrrCalculator,
                        onHide: setShowIrrCalculator,
                        key: IRR_CALCULATOR_KEY,
                        Calculator: IrrCalculatorSidebar
                      },
                      {
                        show: showPinuiBinuiIrrCalculator,
                        onHide: setShowPinuiBinuiIrrCalculator,
                        key: PINUI_BINUI_CALCULATOR_KEY,
                        Calculator: PinuiBinuiIrrCalculatorSidebar
                      },
                      {
                        show: showPaperApartmentCalculator,
                        onHide: setShowPaperApartmentCalculator,
                        key: PAPER_CALCULATOR_KEY,
                        Calculator: PaperApartmentCalculatorSidebar
                      },
                      {
                        show: showRSUOptionsCalculator,
                        onHide: setShowRSUOptionsCalculator,
                        key: RSU_OPTIONS_KEY,
                        Calculator: RSUOptionsCalculatorSidebar
                      }
                    ]
                      .filter(a => activeCustomer['allowedCalculators']?.includes(a.key))
                      .map(({ show, onHide, Calculator }) => (
                        <Calculator
                          translate={translate}
                          customer={activeCustomer}
                          show={show}
                          adminCompTable={adminCompTable}
                          onCustomerUpdate={onCustomerUpdate}
                          onHide={() => onHide(false)}
                          readOnlyMode={readOnlyMode}
                          assigneUsers={assigneUsers}
                        />
                      ))}
                    {activeCustomerStrategy && (
                      <ComparisonChartSidebar
                        customer={activeCustomer}
                        adminCompTable={adminCompTable}
                        show={showComparisonChart}
                        activeStrategy={activeCustomerStrategy}
                        onHide={() => setShowComparisonChart(false)}
                        translate={translate}
                      />
                    )}
                  </>
                )}
              </Col>
            </Row>
            <ViewAppointmentModal
              show={Boolean(viewAppointmentModalMeta)}
              onHide={() => setViewAppointmentModalMeta(null)}
              customer={parentCustomer}
            />
            {screenWidth < 768 && (
              <SlidingSideBar
                title={translate('customers')}
                onClose={() => setSidebarCollapsed(true)}
                placement="left"
                className="d-md-none"
                visible={!isSidebarCollapsed}
                smallScreenWidth={80}
              >
                <Card>
                  <Card.Body className="px-1 py-2">
                    <CustomerList
                      customers={customers}
                      activeId={parentCustomer ? parentCustomer['_id'] : ''}
                      onSelect={customer => {
                        delete customer.loadingError;

                        setActiveCustomer(customer);
                        setParentCustomer(customer);
                        setSidebarCollapsed(true);
                      }}
                      onHoverInactive={() => {
                        setSidebarCollapsed(true);
                      }}
                      hoverDelay={5000}
                    />
                  </Card.Body>
                </Card>
              </SlidingSideBar>
            )}
          </>
        ) : (
          !loading && <NotFound text="No customers added!" />
        )}
        {customers.length > 0 && (
          <AlertModal
            alertText={showAlertMeta ? showAlertMeta.alertText : ''}
            show={showAlertMeta !== null}
            onHide={() => setShowAlertMeta(null)}
            continueButtonVariant={showAlertMeta ? showAlertMeta.continueButtonVariant : ''}
            onDismissClick={() => setShowAlertMeta(null)}
            onContinueClick={showAlertMeta ? showAlertMeta.onContinueClick : ''}
            showProgress={showAlertMeta && showAlertMeta.showProgress}
            progressText={showAlertMeta ? showAlertMeta.progressText : ''}
          />
        )}
        <UpdateStrategyTimeModal
          translate={translate}
          show={updateStrategyTimeMeta !== null}
          onHide={() => setUpdateStrategyTimeMeta(null)}
          strategyStartMonth={updateStrategyTimeMeta?.strategyStartMonth}
          strategyStartYear={updateStrategyTimeMeta?.strategyStartYear}
          onStartMonthChange={e =>
            setUpdateStrategyTimeMeta({ ...updateStrategyTimeMeta, strategyStartMonth: e.target.value })
          }
          onStartYearChange={e =>
            setUpdateStrategyTimeMeta({ ...updateStrategyTimeMeta, strategyStartYear: e.target.value })
          }
          strategyTotalYears={updateStrategyTimeMeta?.strategyTotalYears}
          onTotalYearsChange={e =>
            setUpdateStrategyTimeMeta({ ...updateStrategyTimeMeta, strategyTotalYears: e.target.value })
          }
          onStrategyStartDateUpdateSubmit={onStrategyStartDateUpdateSubmit}
          showProgress={updatingStrategyTime}
        />
        <AddEditCustomerModal
          show={customerActionMeta !== null}
          onHide={() => setCustomerActionMeta(null)}
          onInviteClick={onInviteCustomerClick}
          mode={customerActionMeta?.mode || 'add'}
          onCloneClick={() => {
            setCustomerActionMeta({
              toBeClonedCustomer: parentCustomer,
              mode: 'clone',
              customer: {
                ...getCustomerForClone(parentCustomer),
                startDate: getFormattedDate(new Date())
              }
            });
          }}
          onShowAllStrategiesClick={onShowAllStrategiesClick}
          customer={customerActionMeta?.customer}
          showProgress={customerAddingUpdating}
          user={user}
          users={assigneUsers}
          onChange={onCustomerDetailChange}
          onViewCRMContactClick={onViewCRMContactClick}
          onCustomerSubmit={onCustomerSubmit}
        />

        <InviteCustomerModal
          show={inviteCustomerModalMeta !== null}
          hasAlreadyBeenInvited={inviteCustomerModalMeta?.hasAlreadyBeenInvited}
          password={inviteCustomerModalMeta?.password}
          onPasswordChange={password => setInviteCustomerModalMeta({ ...inviteCustomerModalMeta, password })}
          receiverEmails={inviteCustomerModalMeta?.receiverEmails}
          onReceiverEmailsChange={receiverEmails =>
            setInviteCustomerModalMeta({ ...inviteCustomerModalMeta, receiverEmails })
          }
          onHide={() => setInviteCustomerModalMeta(null)}
          showProgress={inviteCustomerModalMeta?.showProgress}
          onSubmit={onInviteCustomerSubmit}
        />
        <UserLogsModal
          disabled={customerUpdating}
          onUndoRedoClick={onUndoRedoClick}
          show={showLogsModal}
          user={parentCustomer}
          onHide={() => setShowLogsModal(false)}
        />
        <AddEditCustomerProfileModal
          show={Boolean(customerProfileMeta)}
          onHide={() => setCustomerProfileMeta(false)}
          showProgress={customerProfileMeta?.showProgress}
          editMode={customerProfileMeta?.mode === 'update'}
          initialValues={customerProfileMeta?.data}
          onSubmit={onSubmitCustomerProfile}
          onDeleteClick={onDeleteApartmentProfileClick}
        />
        <AlertModal
          show={Boolean(deleteApartmentProfileMeta)}
          showProgress={deleteApartmentProfileMeta?.showProgress}
          onHide={() => setDeleteApartmentProfileMeta(null)}
          onDismissClick={() => setDeleteApartmentProfileMeta(null)}
          onContinueClick={deleteApartmentProfile}
          alertText={translate('are_you_sure_you_want_to_delete_apartment_profile')}
        />
      </Container>
      {!readOnlyMode && (
        <FloatingButton
          Icon={PlusCircle}
          className="text-white"
          variant="success"
          text={translate('new_customer')}
          onClick={() =>
            setCustomerActionMeta({
              mode: 'add',
              customer: { startDate: getFormattedDate(new Date()), level: 2, status: 'New', showGeneralInfo: true }
            })
          }
        />
      )}
    </>
  );
};

export default Customer;
