import React, { useMemo } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import { checkIfUserCanEdit, isSystemUser } from '../../helpers/global';
import useAuth from '../../hooks/useAuth';
import CachedResponse from '../common/CachedResponse';
import NotFound from '../common/NotFound';
import RealEstateIRR from './Irr';
import PaperApartmentIRR from './PaperApartment';
import PinuiBinui from './PinuiBinui';
import RSUOptions from './RSUOptionsCalculator';

const Calculators = ({ assigneUsers }) => {
  const { user } = useAuth();
  const userRole = useMemo(() => user?.role, [user]);
  const readOnlyMode = useMemo(() => !checkIfUserCanEdit(user), [user]);

  return (
    <Switch>
      <Route exact path="/calculators/realestate-irr">
        <CachedResponse
          listEndpoint={'comps'}
          requestBody={{ filter: { customer: false } }}
          responseModifier={response => response[0]}
          render={compTable => (
            <RealEstateIRR assigneUsers={assigneUsers} adminCompTable={compTable} readOnlyMode={readOnlyMode} />
          )}
        />
      </Route>
      {isSystemUser(userRole) && (
        <>
          <Route exact path="/calculators/pinui-binui">
            <CachedResponse
              listEndpoint={'comps'}
              requestBody={{ filter: { customer: false } }}
              responseModifier={response => response[0]}
              render={compTable => (
                <PinuiBinui assigneUsers={assigneUsers} adminCompTable={compTable} readOnlyMode={readOnlyMode} />
              )}
            />
          </Route>
          <Route exact path="/calculators/paper-apartment">
            <CachedResponse
              listEndpoint={'comps'}
              requestBody={{ filter: { customer: false } }}
              responseModifier={response => response[0]}
              render={compTable => (
                <PaperApartmentIRR assigneUsers={assigneUsers} adminCompTable={compTable} readOnlyMode={readOnlyMode} />
              )}
            />
          </Route>
          <Route exact path="/calculators/rsu-options">
            <CachedResponse
              listEndpoint={'comps'}
              requestBody={{ filter: { customer: false } }}
              responseModifier={response => response[0]}
              render={compTable => (
                <RSUOptions assigneUsers={assigneUsers} adminCompTable={compTable} readOnlyMode={readOnlyMode} />
              )}
            />
          </Route>
        </>
      )}
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

export default withRouter(Calculators);
