import cities from './israel-cities.json';

export const API_URL = process.env.REACT_APP_API_URL;
export const APP_URL = process.env.REACT_APP_APP_URL;

export const LANDING_URL = 'https://ethica.finance';
export const BRAND_NAME = 'Ethica Finance';
export const UNAUTHORIZED_TEXT = 'You are not authorized to view this page';

// API RELATED CONSTANTS
export const ENDPOINTS = {
  FIND_CRM_CONTACT_FROM_EMAIL: '/users/find-crm-contact-from-email',
  GET_STARTED: '/users/register-freemium-customer',
  LOGIN: '/users/login',
  REGISTER: '/users/register',
  UPDATE_CREDENTIALS: '/users/update-credentials',
  GENERATE_REGISTER_OTP: '/users/generate-registration-otp',
  FORGOT_PASSWORD: '/users/send-password-reset-link',
  VERIFY_EMAIL: '/users/verify-email',
  CHANGE_PASSWORD: '/users/change-password',
  RESET_PASSWORD: '/users/reset-password',
  USERS_BASE: '/users/',
  USERS_ME: '/users/me',
  USERS_LOGOUT: '/users/logout',
  USERS_DELEGATION_ADD: '/users/delegations/add',
  USERS_DELEGATION_SUBTRACT: '/users/delegations/remove',
  SHARE_USER_ADD: '/users/share/add',
  SHARE_USER_SUBTRACT: '/users/share/remove',
  USERS_DETAILS: id => `/users/${id}`,
  USERS_LOGS: id => `/users/diff/${id}`,
  USERS_DIFF_ACTION: (id, action) => `/users/diff/${id}/${action}`,
  CRM_CONTACT_FROM_USER: id => `/users/find-crm-contact/${id}`,
  USERS_LIST: '/users/list',
  USERS_UPDATE: '/users/update-user-details',
  USERS_COPY_APARTMENT: '/users/copy-apartment',
  USERS_CREATE_DRIVE_FOLDER: '/users/create-drive-folder',
  COMPS_LIST: '/comps/list',
  COMPS_BASE: '/comps',
  COMPS_WITH_ID: id => `/comps/${id}`,
  APP_VIDEOS_LIST: '/app-videos/list',
  APP_VIDEOS_WITH_ID: id => `/app-videos/${id}`,
  FILE: '/files',
  FILE_WITH_ID: id => `/files/${id}`,
  FILE_STREAM_WITH_ID: id => `/files/stream/${id}`,
  GET_DRIVE_FILES: driveFolder => `/files/drive-files/${driveFolder}`,
  UTILS_CONVERT_CURRENCY: '/utils/convert-currency',
  DIAL_PHONE_NUMBER: '/utils/dial-phone-number',
  EXAMPLE_USER: id => `/users/get-example-customer/${id}`,
  SHARED_USER: id => `/users/get-shared-customer/${id}`,

  // apartments
  GET_APPARTMENTS: '/apartments/search',
  CREATE_APARTMENT: '/apartments',
  APARTMENT_WITH_ID: id => `/apartments/${id}`,
  CREATE_DRIVE_FOLDER: '/apartments/create-drive-folder',

  // google drive
  GET_FILES_OR_FOLDERS: ({ folder, query }) =>
    `/google-drive/list${folder && `?folder=${folder}`}${query && `?q=${query}`}`,
  CREATE_NEW_FOLDER: '/google-drive/create-folder',
  DELETE_FILE_OR_FOLDER: id => `/google-drive/delete/${id}`,
  UPLOAD_FILES: '/google-drive/upload'
};

export const IRR_CALCULATOR_KEY = 'irr';
export const PINUI_BINUI_CALCULATOR_KEY = 'pinui-binui';
export const PAPER_CALCULATOR_KEY = 'paper';
export const RSU_OPTIONS_KEY = 'rsu-options';

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const getValidUrl = link => {
  if (!link.startsWith('https://') && !link.startsWith('http://')) {
    link = 'https://' + link;
  }

  return link;
};

export const getDateWithTimezone = dateString => {
  try {
    return new Date(dateString.split('-').join('/')).toISOString();
  } catch (e) {}
};

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
export const DEFAULT_DATE_TIME_FORMAT = 'DD/MM/YYYY, HH:mm';
export const DEFAULT_DATE_TIME_FORMAT_NON_MOMENT = 'dd/MM/yyyy, HH:mm';

export const STATUS_INTEREST_IN_MY_COURSE = 'Interested In My Course';

export const monthSmall = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const assetCategoriesOptions = ['Cash', 'Real Estate', 'Stocks', 'Fixed Income', 'Anona', 'RSU', 'Other'];
export const primaryBankAccountName = 'Primary Bank Account';
export const oneOffEventTypeOptions = ['Asset', 'Liability'];
export const eventActionTypeOptions = ['Add', 'Subtract', 'Set'];
export const cashflowTypeOptions = ['Income', 'Expenditure'];
export const investmentTypeOptions = ['re-investment', 'gradual-selling'];
export const transferValueTypeOptions = ['fixed', 'relative', 'relative-down'];
export const transferFrequencyTypeOptions = ['monthly', 'quaterly', 'semi-yearly', 'yearly'];

export const stockTypeOptions = ['Pensioni', 'IB'];
export const apartmentTypeOptions = [
  'Classic Apartment',
  'Home Apartment',
  'Paper Apartment',
  'Pinui Binui Apartment',
  'Abroad Apartment',
  'Other'
];

export const maxTimeStamp = 8640000000000000;
export const yesNoOptions = ['Yes', 'No'];
export const rangeOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

//COMP TABLE RELATED FIELDS

export const APARTMENT_STATUS = ['Available', 'Bought By Our Client', 'Bought By Outsider'];

export const getVariableTableHeaders = translate => [
  { name: translate('name'), key: 'name', required: true },
  { name: translate('symbol'), key: 'symbol', required: true },
  { name: translate('value'), key: 'value', type: 'number' }
];

export const ApartmentProfileFields = {
  'paper-apartment': [
    { key: 'inputPrice', label: 'apartment_budget', type: 'minMax' },
    { key: 'city', label: 'city', type: 'multi-select', options: cities.map(c => ({ label: c.city, value: c.city })) },
    { key: 'estimatedTimeForFinishingContruction', label: 'time_for_finishing_construction', type: 'minMax' },
    { key: 'realEstateHoldingPeriodInYears', label: 'holding_time', type: 'minMax' },
    {
      key: 'developerCompanyScale',
      label: 'developer_has_to_be_a_big_company',
      type: 'range',
      min: 1,
      max: 10,
      objectKey: 'min'
    },
    {
      key: 'linearPayment',
      label: 'willingness_for_linear_payment',
      type: 'switch',
      trueValue: 'Yes',
      falseValue: 'No'
    },
    {
      key: 'buyingDecisionScale',
      label: 'buying_decision_has_to_be_done_fast',
      type: 'range',
      min: 1,
      max: 10,
      objectKey: 'min'
    },
    {
      key: 'developerLoan',
      label: 'willingness_for_developer_loan',
      type: 'switch',
      trueValue: 'Yes',
      falseValue: 'No'
    }
  ],

  'pinui-binui': [
    { key: 'inputPrice', label: 'apartment_budget', type: 'minMax' },
    { key: 'city', label: 'city', type: 'multi-select', options: cities.map(c => ({ label: c.city, value: c.city })) },
    { key: 'requiredCashForDeal', label: 'How_much_cash_available_for_deal', type: 'minMax' },

    {
      key: 'smallApartment',
      label: 'willingess_to_buy_small_apartment',
      type: 'switch',
      trueValue: 'Yes',
      falseValue: 'No'
    },
    {
      key: 'apartmentLooks',
      label: 'importance_of_apartment_looks',
      type: 'range',
      min: 1,
      max: 10,
      objectKey: 'min'
    },

    {
      key: 'earlyDeal',
      label: 'willingness_for_early_stage_deals',
      type: 'switch',
      trueValue: 'Yes',
      falseValue: 'No'
    },
    {
      key: 'largeRenovation',
      label: 'willingness_to_buy_apartment_with_large_scale_renovation',
      type: 'range',
      min: 1,
      max: 10,
      objectKey: 'min'
    },

    {
      key: 'noElevator',
      label: 'willingness_to_buy_floor_3_or_higher_without_elevator',
      type: 'switch',
      trueValue: 'Yes',
      falseValue: 'No'
    },
    {
      key: 'buyingDecisionScale',
      label: 'buying_decision_has_to_be_done_fast',
      type: 'range',
      min: 1,
      max: 10,
      objectKey: 'min'
    },
    {
      key: 'complicatedDeals',
      label: 'willingness_to_buy_complicated_deals',
      type: 'switch',
      trueValue: 'Yes',
      falseValue: 'No'
    }
  ]
};

export const presetTableHeaders = [
  { name: 'Name', key: 'name', required: true },
  {
    name: 'Asset Category',
    key: 'assetType',
    formType: 'dropdown',
    options: assetCategoriesOptions,
    dependentElems: ['stockType'],
    showDependentOn: 'Stocks'
  },
  {
    name: 'Stock Type',
    key: 'stockType',
    mapsToElem: 'stockType',
    colWidth: 12,
    formType: 'dropdown',
    options: stockTypeOptions
  },
  {
    name: 'Apartment Type',
    key: 'apartmentType',
    mapsToElem: 'apartmentType',
    options: apartmentTypeOptions,
    formType: 'dropdown',
    colWidth: 12,
    dependentElems: [
      [
        'valueOfApartmentToday',
        'estimatedTimeForFinishingContruction',
        'downPayment',
        'yearlyMaterialCostIndexRise',
        'remainingPayment'
      ]
    ],
    showDependentOnArray: ['Paper Apartment']
  },
  {
    name: 'Estimated time for finishing construction in years',
    titleKey: 'estimated_time_for_contruction_months',
    key: 'estimatedTimeForFinishingContruction',
    mapsToElem: 'estimatedTimeForFinishingContruction',
    roundDecimal: 0
  },
  { name: 'Down Payment', key: 'downPayment', mapsToElem: 'downPayment', isPercentageField: true },
  {
    name: 'Yearly materials cost index rise',
    key: 'yearlyMaterialCostIndexRise',
    mapsToElem: 'yearlyMaterialCostIndexRise',
    isPercentageField: true
  },
  {
    name: 'Remaining Payment',
    key: 'remainingPayment',
    mapsToElem: 'remainingPayment',
    options: ['One Final Payment', 'Gradual Payment'],
    optionValues: ['oneFinalPayment', 'gradualPayment'],
    formType: 'dropdown',
    colWidth: 12
  },
  {
    name: 'Expected Appreciation',
    key: 'expectedAppreciation',
    mapsToElem: 'returnAppreciation',
    isPercentageField: true
  },
  {
    name: 'Expected Cashflow',
    key: 'expectedCashflow',
    mapsToElem: 'returnCashflowPercentage',
    isPercentageField: true,
    numberField: 'returnCashflow'
  },
  {
    name: 'Liquidation Score',
    key: 'liquidationScore',
    mapsToElem: 'liquidationScore',
    formType: 'dropdown',
    options: [1, 2, 3]
  },
  { name: 'Crash Percentage', key: 'crashPercentage', mapsToElem: 'crashPercentage', isPercentageField: true },
  {
    crashPercentage: 'canSellPartially',
    name: 'Can Sell Partially?',
    key: 'canSellPartially',
    mapsToElem: 'canSellPartially',
    formType: 'checkbox',
    type: 'boolean',
    title: '',
    label: 'Can Sell Partially?',
    showLabel: true,
    showAsBadge: true
  },
  {
    name: 'Purchase Cost',
    key: 'purchaseCost',
    mapsToElem: 'purchaseCostPercentage',
    isPercentageField: true,
    numberField: 'purchaseCost'
  },
  {
    name: 'Sales Cost',
    key: 'salesCost',
    mapsToElem: 'salesCostPercentage',
    isPercentageField: true,
    numberField: 'salesCost'
  },
  {
    name: 'Loan Type',
    key: 'loanType',
    formType: 'dropdown',
    options: ['Annuity', 'Interest only', 'Balloon', 'Margin'],
    mapsToElem: 'type'
  },
  {
    name: 'Margin Floor',
    key: 'marginFloor',
    mapsToElem: 'marginFloor',
    isPercentageField: true
  },
  {
    name: 'Margin Ceiling',
    key: 'marginCeiling',
    mapsToElem: 'marginCeiling',
    isPercentageField: true
  },
  {
    name: 'Loan Value',
    key: 'loanValue',
    mapsToElem: 'valueInPercentage',
    isPercentageField: true,
    numberField: 'value',
    onlyForLoan: true
  },
  { name: 'Loan Interest', key: 'loanInterest', mapsToElem: 'interest', isPercentageField: true },
  {
    name: 'Interest Type',
    key: 'interestType',
    formType: 'dropdown',
    mapsToElem: 'typeOfInterest',
    options: ['Fixed', 'Variable', 'Mortgage Interest']
  },
  { name: 'Time to maturity', key: 'timeToMaturity', mapsToElem: 'timeToMaturity', roundDecimal: 0 }
];

export const getPresetTableHeaders = (translate, filterCategoriesForPresets = assetCategoriesOptions) => [
  { name: translate('name'), key: 'name', required: true },
  {
    name: translate('asset_category'),
    key: 'assetType',
    formType: 'dropdown',
    options: filterCategoriesForPresets,
    dependentElems: ['stockType', 'apartmentType'],
    showDependentOnArray: ['Stocks', 'Real Estate'],
    forAssetTypes: assetCategoriesOptions.filter(c => c !== 'Real Estate')
  },
  {
    name: translate('stock_type'),
    key: 'stockType',
    mapsToElem: 'stockType',
    colWidth: 12,
    formType: 'dropdown',
    options: stockTypeOptions,
    forAssetTypes: assetCategoriesOptions.filter(c => c !== 'Real Estate')
  },
  {
    name: translate('apartment_type'),
    key: 'apartmentType',
    mapsToElem: 'apartmentType',
    options: apartmentTypeOptions,
    formType: 'dropdown',
    colWidth: 12,
    dependentElems: [
      [
        'valueOfApartmentToday',
        'estimatedTimeForFinishingContruction',
        'downPayment',
        'yearlyMaterialCostIndexRise',
        'remainingPayment'
      ],
      ['estimatedTimeForFinishingContruction']
    ],
    showDependentOnArray: ['Paper Apartment', 'Pinui Binui Apartment'],
    forAssetTypes: ['Real Estate']
  },
  {
    name: translate('estimated_time_for_contruction_months'),
    titleKey: 'estimated_time_for_contruction_months',
    key: 'estimatedTimeForFinishingContruction',
    mapsToElem: 'estimatedTimeForFinishingContruction',
    forAssetTypes: ['Real Estate'],
    roundDecimal: 0
  },
  { name: translate('down_payment'), key: 'downPayment', mapsToElem: 'downPayment', forAssetTypes: ['Real Estate'] },
  {
    name: translate('yearly_materials_cost_index_rise_comp'),
    key: 'yearlyMaterialCostIndexRise',
    mapsToElem: 'yearlyMaterialCostIndexRise',
    forAssetTypes: ['Real Estate']
  },
  {
    name: translate('remaining_payment'),
    key: 'remainingPayment',
    mapsToElem: 'remainingPayment',
    options: ['One Final Payment', 'Gradual Payment'],
    optionValues: ['oneFinalPayment', 'gradualPayment'],
    formType: 'dropdown',
    forAssetTypes: ['Real Estate']
  },
  { name: translate('expected_appreciation'), key: 'expectedAppreciation', mapsToElem: 'returnAppreciation' },
  {
    name: translate('expected_cashflow'),
    key: 'expectedCashflow',
    mapsToElem: 'returnCashflowPercentage',
    isPercentageField: true,
    numberField: 'returnCashflow'
  },
  {
    name: translate('liquidation_score'),
    key: 'liquidationScore',
    mapsToElem: 'liquidationScore',
    formType: 'dropdown',
    options: [1, 2, 3]
  },
  { name: translate('crash_percentage'), key: 'crashPercentage', mapsToElem: 'crashPercentage' },
  {
    name: translate('can_sell_partially'),
    key: 'canSellPartially',
    mapsToElem: 'canSellPartially',
    formType: 'checkbox',
    type: 'boolean',
    title: '',
    label: 'Can Sell Partially?',
    showLabel: true,
    showAsBadge: true,
    colWidth: 12
  },
  {
    name: translate('purchase_cost'),
    key: 'purchaseCost',
    mapsToElem: 'purchaseCostPercentage',
    isPercentageField: true,
    numberField: 'purchaseCost'
  },
  {
    name: translate('sales_cost'),
    key: 'salesCost',
    mapsToElem: 'salesCostPercentage',
    isPercentageField: true,
    numberField: 'salesCost'
  },
  {
    name: translate('loan_type'),
    key: 'loanType',
    formType: 'dropdown',
    options: ['Annuity', 'Interest only', 'Balloon', 'Margin'],
    mapsToElem: 'type',
    dependentElems: [['marginFloor', 'marginCeiling']],
    showDependentOnArray: ['Margin'],
    colWidth: 12
  },
  {
    name: translate('margin_floor'),
    key: 'marginFloor',
    mapsToElem: 'marginFloor',
    isPercentageField: true
  },
  {
    name: translate('margin_ceiling'),
    key: 'marginCeiling',
    mapsToElem: 'marginCeiling',
    isPercentageField: true
  },
  {
    name: translate('loan_value'),
    key: 'loanValue',
    mapsToElem: 'valueInPercentage',
    isPercentageField: true,
    numberField: 'value',
    onlyForLoan: true
  },
  { name: translate('loan_interest'), key: 'loanInterest', mapsToElem: 'interest' },
  {
    name: translate('interest_type'),
    key: 'interestType',
    formType: 'dropdown',
    mapsToElem: 'typeOfInterest',
    options: ['Fixed', 'Variable', 'Mortgage Interest']
  },
  { name: translate('time_to_maturity'), key: 'timeToMaturity', mapsToElem: 'timeToMaturity', roundDecimal: 0 }
];

export const getTaxBracketFields = translate => [
  { name: translate('from'), key: 'from', required: false, type: 'number', formType: 'currency', isCurrency: true },
  { name: translate('to'), key: 'to', required: false, type: 'number', formType: 'currency', isCurrency: true },
  { name: translate('percentage'), key: 'percentage', required: true, isPercentageField: true, formType: 'number' }
];

export const getCashflowChangesFields = (translate, strategyYears) => [
  {
    name: translate('notes'),
    key: 'notes',
    type: 'text-area',
    title: 'Notes',
    row: 2,
    colWidth: 12
  },
  {
    name: translate('start_month'),
    key: 'startMonth',
    required: true,
    formType: 'dropdown',
    isMonth: true,
    options: months,
    optionValues: months.map((_, monthNum) => monthNum)
  },
  {
    name: translate('start_year'),
    key: 'startYear',
    required: true,
    formType: 'dropdown',
    options: strategyYears
  },
  {
    name: translate('end_month'),
    key: 'endMonth',
    formType: 'dropdown',
    isMonth: true,
    options: months,
    optionValues: months.map((_, monthNum) => monthNum)
  },
  {
    name: translate('end_year'),
    key: 'endYear',
    formType: 'dropdown',
    options: ['', ...strategyYears]
  },
  {
    name: translate('action'),
    key: 'action',
    required: true,
    formType: 'number',
    formType: 'dropdown',
    options: eventActionTypeOptions
  },
  {
    name: translate('value'),
    key: 'value',
    required: true,
    type: 'number',
    formType: 'currency',
    isCurrency: true
  }
];

export const fieldsWithFormulas = presetTableHeaders
  .filter(h => h.key !== 'name' && h.formType !== 'dropdown' && h.formType !== 'checkbox')
  .map(h => h.key);

export const getScenarioOptions = (translate, showStressTestAndArmaggedonToClient) =>
  [
    { name: translate('original'), changeInPercentage: 0 },
    { name: translate('good'), changeInPercentage: 0.5 },
    { name: translate('bad'), changeInPercentage: -0.5 },
    { name: translate('armageddon'), changeInPercentage: -0.5, isArmageddon: true }
  ].filter(({ isArmageddon }) => !isArmageddon || showStressTestAndArmaggedonToClient);

export const transferTypeOptions = [
  { label: 'Fixed', value: 'fixed' },
  { label: 'Set a ceiling value', value: 'relative' },
  { label: 'Set a floor value', value: 'relative-down' }
];

export const transferFrequencyOptions = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Quaterly', value: 'quaterly', monthsToConsider: [0, 3, 6, 9] },
  { label: 'Semi Yearly', value: 'semi-yearly', monthsToConsider: [0, 6] },
  { label: 'Yearly', value: 'yearly', monthsToConsider: [0] }
];

export const transferFrequencyToMonthsMap = {
  quaterly: [0, 3, 6, 9],
  'semi-yearly': [0, 6],
  yearly: [0]
};

export const commonChartConfig = isRtl => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom',
      rtl: isRtl,
      labels: {
        padding: 20,
        boxHeight: 6,
        pointStyle: 'circle',
        usePointStyle: true
      }
    },
    title: {
      display: false
    },
    tooltip: {
      position: 'average', // 'average' is more typical for pie charts
      mode: 'index',
      titleAlign: isRtl ? 'right' : 'left',
      bodyAlign: isRtl ? 'right' : 'left'
    }
  },
  interaction: {
    mode: 'nearest',
    intersect: true // Remove axis option, intersect true for pie charts
  }
});

export const chartColors = [
  '#FF8C00',
  '#00BFFF',
  '#F08080',
  '#8B4513',
  '#FF69B4',
  '#7CFC00',
  '#663399',
  '#BDB76B',
  '#778899',
  '#FFC0CB',
  '#FFD700',
  '#228B22',
  '#6A5ACD',
  '#20B2AA'
];

export const ANNUITY_LOAN_PRESET = {
  loanInterest: '[A2] + [A3]',
  interestType: 'Variable',
  timeToMaturity: '120'
};

export const INTEREST_ONLY_LOAN_PRESET = {
  loanInterest: '[A2] + [A3]',
  interestType: 'Variable',
  timeToMaturity: '60'
};

export const BALLOON_LOAN_PRESET = {
  loanInterest: '[A2] + [A3]',
  interestType: 'Variable',
  timeToMaturity: '60'
};

export const MARGIN_LOAN_PRESET = {
  loanInterest: '[A1] + [A3]',
  interestType: 'Variable',
  timeToMaturity: '120'
};

export const loanTypePresets = {
  Annuity: ANNUITY_LOAN_PRESET,
  'Interest only': INTEREST_ONLY_LOAN_PRESET,
  Balloon: BALLOON_LOAN_PRESET,
  Margin: MARGIN_LOAN_PRESET
};

export const PROGRAM_TYPES = [
  'Strategic planning',
  'Real estate guidance',
  'Real estate full service',
  'Multi-generational'
];

export const searchFilterOptions = [{ title: 'Active', options: ['Yes', 'No'], defaultChecked: true }];
export const getCustomerSortNameToField = translate => ({
  [translate('added_on')]: 'createdAt',
  [translate('alphabetically')]: 'name'
});

export const ADMIN_ROLE = 'Admin';
export const MANAGER_ROLE = 'Manager';
export const SALES_ROLE = 'Salesperson';
export const FINANCIAL_STRATEGIST_ROLE = 'Financial Strategist';
export const REAL_ESTATE_ANALYST_ROLE = 'Real Estate Analyst';
export const ALL_ROLES = [ADMIN_ROLE, MANAGER_ROLE, SALES_ROLE, FINANCIAL_STRATEGIST_ROLE, REAL_ESTATE_ANALYST_ROLE];

export const currencyOptions = [
  { symbol: 'USD', name: 'United States Dollar' },
  { symbol: 'EUR', name: 'Euro' },
  { symbol: 'AED', name: 'United Arab Emirates Dirham' },
  { symbol: 'AFN', name: 'Afghan Afghani' },
  { symbol: 'ALL', name: 'Albanian Lek' },
  { symbol: 'AMD', name: 'Armenian Dram' },
  { symbol: 'ANG', name: 'Netherlands Antillean Guilder' },
  { symbol: 'AOA', name: 'Angolan Kwanza' },
  { symbol: 'ARS', name: 'Argentine Peso' },
  { symbol: 'AUD', name: 'Australian Dollar' },
  { symbol: 'AWG', name: 'Aruban Florin' },
  { symbol: 'AZN', name: 'Azerbaijani Manat' },
  { symbol: 'BAM', name: 'Bosnia-Herzegovina Convertible Mark' },
  { symbol: 'BBD', name: 'Barbadian Dollar' },
  { symbol: 'BDT', name: 'Bangladeshi Taka' },
  { symbol: 'BGN', name: 'Bulgarian Lev' },
  { symbol: 'BHD', name: 'Bahraini Dinar' },
  { symbol: 'BIF', name: 'Burundian Franc' },
  { symbol: 'BMD', name: 'Bermudian Dollar' },
  { symbol: 'BND', name: 'Brunei Dollar' },
  { symbol: 'BOB', name: 'Bolivian Boliviano' },
  { symbol: 'BRL', name: 'Brazilian Real' },
  { symbol: 'BSD', name: 'Bahamian Dollar' },
  { symbol: 'BTN', name: 'Bhutanese Ngultrum' },
  { symbol: 'BWP', name: 'Botswana Pula' },
  { symbol: 'BYN', name: 'Belarusian Ruble' },
  { symbol: 'BZD', name: 'Belize Dollar' },
  { symbol: 'CAD', name: 'Canadian Dollar' },
  { symbol: 'CDF', name: 'Congolese Franc' },
  { symbol: 'CHF', name: 'Swiss Franc' },
  { symbol: 'CLF', name: 'Chilean Unit of Account (UF)' },
  { symbol: 'CLP', name: 'Chilean Peso' },
  { symbol: 'CNH', name: 'Chinese Yuan (Offshore)' },
  { symbol: 'CNY', name: 'Chinese Yuan' },
  { symbol: 'COP', name: 'Colombian Peso' },
  { symbol: 'CRC', name: 'Costa Rican Colón' },
  { symbol: 'CUC', name: 'Cuban Convertible Peso' },
  { symbol: 'CUP', name: 'Cuban Peso' },
  { symbol: 'CVE', name: 'Cape Verdean Escudo' },
  { symbol: 'CZK', name: 'Czech Koruna' },
  { symbol: 'DJF', name: 'Djiboutian Franc' },
  { symbol: 'DKK', name: 'Danish Krone' },
  { symbol: 'DOP', name: 'Dominican Peso' },
  { symbol: 'DZD', name: 'Algerian Dinar' },
  { symbol: 'EGP', name: 'Egyptian Pound' },
  { symbol: 'ERN', name: 'Eritrean Nakfa' },
  { symbol: 'ETB', name: 'Ethiopian Birr' },
  { symbol: 'FJD', name: 'Fijian Dollar' },
  { symbol: 'FKP', name: 'Falkland Islands Pound' },
  { symbol: 'GBP', name: 'British Pound Sterling' },
  { symbol: 'GEL', name: 'Georgian Lari' },
  { symbol: 'GGP', name: 'Guernsey Pound' },
  { symbol: 'GHS', name: 'Ghanaian Cedi' },
  { symbol: 'GIP', name: 'Gibraltar Pound' },
  { symbol: 'GMD', name: 'Gambian Dalasi' },
  { symbol: 'GNF', name: 'Guinean Franc' },
  { symbol: 'GTQ', name: 'Guatemalan Quetzal' },
  { symbol: 'GYD', name: 'Guyanese Dollar' },
  { symbol: 'HKD', name: 'Hong Kong Dollar' },
  { symbol: 'HNL', name: 'Honduran Lempira' },
  { symbol: 'HRK', name: 'Croatian Kuna' },
  { symbol: 'HTG', name: 'Haitian Gourde' },
  { symbol: 'HUF', name: 'Hungarian Forint' },
  { symbol: 'IDR', name: 'Indonesian Rupiah' },
  { symbol: 'IMP', name: 'Isle of Man Pound' },
  { symbol: 'INR', name: 'Indian Rupee' },
  { symbol: 'IQD', name: 'Iraqi Dinar' },
  { symbol: 'IRR', name: 'Iranian Rial' },
  { symbol: 'ISK', name: 'Icelandic Króna' },
  { symbol: 'JEP', name: 'Jersey Pound' },
  { symbol: 'JMD', name: 'Jamaican Dollar' },
  { symbol: 'JOD', name: 'Jordanian Dinar' },
  { symbol: 'JPY', name: 'Japanese Yen' },
  { symbol: 'KES', name: 'Kenyan Shilling' },
  { symbol: 'KGS', name: 'Kyrgyzstani Som' },
  { symbol: 'KHR', name: 'Cambodian Riel' },
  { symbol: 'KMF', name: 'Comorian Franc' },
  { symbol: 'KPW', name: 'North Korean Won' },
  { symbol: 'KRW', name: 'South Korean Won' },
  { symbol: 'KWD', name: 'Kuwaiti Dinar' },
  { symbol: 'KYD', name: 'Cayman Islands Dollar' },
  { symbol: 'KZT', name: 'Kazakhstani Tenge' },
  { symbol: 'LAK', name: 'Laotian Kip' },
  { symbol: 'LBP', name: 'Lebanese Pound' },
  { symbol: 'LKR', name: 'Sri Lankan Rupee' },
  { symbol: 'LRD', name: 'Liberian Dollar' },
  { symbol: 'LSL', name: 'Lesotho Loti' },
  { symbol: 'LYD', name: 'Libyan Dinar' },
  { symbol: 'MAD', name: 'Moroccan Dirham' },
  { symbol: 'MDL', name: 'Moldovan Leu' },
  { symbol: 'MGA', name: 'Malagasy Ariary' },
  { symbol: 'MKD', name: 'Macedonian Denar' },
  { symbol: 'MMK', name: 'Myanmar Kyat' },
  { symbol: 'MNT', name: 'Mongolian Tugrik' },
  { symbol: 'MOP', name: 'Macanese Pataca' },
  { symbol: 'MRU', name: 'Mauritanian Ouguiya' },
  { symbol: 'MUR', name: 'Mauritian Rupee' },
  { symbol: 'MVR', name: 'Maldivian Rufiyaa' },
  { symbol: 'MWK', name: 'Malawian Kwacha' },
  { symbol: 'MXN', name: 'Mexican Peso' },
  { symbol: 'MYR', name: 'Malaysian Ringgit' },
  { symbol: 'MZN', name: 'Mozambican Metical' },
  { symbol: 'NAD', name: 'Namibian Dollar' },
  { symbol: 'NGN', name: 'Nigerian Naira' },
  { symbol: 'NIO', name: 'Nicaraguan Córdoba' },
  { symbol: 'NOK', name: 'Norwegian Krone' },
  { symbol: 'NPR', name: 'Nepalese Rupee' },
  { symbol: 'NZD', name: 'New Zealand Dollar' },
  { symbol: 'OMR', name: 'Omani Rial' },
  { symbol: 'PAB', name: 'Panamanian Balboa' },
  { symbol: 'PEN', name: 'Peruvian Sol' },
  { symbol: 'PGK', name: 'Papua New Guinean Kina' },
  { symbol: 'PHP', name: 'Philippine Peso' },
  { symbol: 'PKR', name: 'Pakistani Rupee' },
  { symbol: 'PLN', name: 'Polish Złoty' },
  { symbol: 'PYG', name: 'Paraguayan Guarani' },
  { symbol: 'QAR', name: 'Qatari Rial' },
  { symbol: 'RON', name: 'Romanian Leu' },
  { symbol: 'RSD', name: 'Serbian Dinar' },
  { symbol: 'RUB', name: 'Russian Ruble' },
  { symbol: 'RWF', name: 'Rwandan Franc' },
  { symbol: 'SAR', name: 'Saudi Riyal' },
  { symbol: 'SBD', name: 'Solomon Islands Dollar' },
  { symbol: 'SCR', name: 'Seychellois Rupee' },
  { symbol: 'SDG', name: 'Sudanese Pound' },
  { symbol: 'SEK', name: 'Swedish Krona' },
  { symbol: 'SGD', name: 'Singapore Dollar' },
  { symbol: 'SHP', name: 'Saint Helena Pound' },
  { symbol: 'SLL', name: 'Sierra Leonean Leone' },
  { symbol: 'SOS', name: 'Somali Shilling' },
  { symbol: 'SRD', name: 'Surinamese Dollar' },
  { symbol: 'SSP', name: 'South Sudanese Pound' },
  { symbol: 'STD', name: 'São Tomé and Príncipe Dobra (pre-2018)' },
  { symbol: 'STN', name: 'São Tomé and Príncipe Dobra' },
  { symbol: 'SVC', name: 'Salvadoran Colón' },
  { symbol: 'SYP', name: 'Syrian Pound' },
  { symbol: 'SZL', name: 'Swazi Lilangeni' },
  { symbol: 'THB', name: 'Thai Baht' },
  { symbol: 'TJS', name: 'Tajikistani Somoni' },
  { symbol: 'TMT', name: 'Turkmenistani Manat' },
  { symbol: 'TND', name: 'Tunisian Dinar' },
  { symbol: 'TOP', name: 'Tongan Paʻanga' },
  { symbol: 'TRY', name: 'Turkish Lira' },
  { symbol: 'TTD', name: 'Trinidad and Tobago Dollar' },
  { symbol: 'TWD', name: 'New Taiwan Dollar' },
  { symbol: 'TZS', name: 'Tanzanian Shilling' },
  { symbol: 'UAH', name: 'Ukrainian Hryvnia' },
  { symbol: 'UGX', name: 'Ugandan Shilling' },
  { symbol: 'UYU', name: 'Uruguayan Peso' },
  { symbol: 'UZS', name: 'Uzbekistani Som' },
  { symbol: 'VES', name: 'Venezuelan Bolívar Soberano' },
  { symbol: 'VND', name: 'Vietnamese Dong' },
  { symbol: 'VUV', name: 'Vanuatu Vatu' },
  { symbol: 'WST', name: 'Samoan Tala' },
  { symbol: 'XAF', name: 'CFA Franc BEAC' },
  { symbol: 'XCD', name: 'East Caribbean Dollar' },
  { symbol: 'XOF', name: 'CFA Franc BCEAO' },
  { symbol: 'XPF', name: 'CFP Franc' },
  { symbol: 'YER', name: 'Yemeni Rial' },
  { symbol: 'ZAR', name: 'South African Rand' },
  { symbol: 'ZMW', name: 'Zambian Kwacha' },
  { symbol: 'ZWL', name: 'Zimbabwean Dollar' }
];

export const reinvestmentTransferFromLabel = {
  fixed: 'transfer_from',
  relative: 'set_ceiling_on',
  'relative-down': 'set_floor_on'
};

export const reinvestmentTransferToLabel = {
  fixed: 'transfer_to',
  relative: 'transfer_to',
  'relative-down': 'transfer_from'
};
