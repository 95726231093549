import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Folder, PersonCircle, PlusLg, Trash } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import { apartmentTypes } from '../../helpers/global';
import useLocalization from '../../hooks/useLocalization';
import AppModal from '../common/AppModal';
import GoogleDriveManager from '../common/google-drive-manager/GoogleDriveManager';
import AddEditApartmentForm from './AddEditApartmentForm';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import ViewCustomersMatchingApartmentModal from './ViewCustomersMatchingApartmentModal';
import CircularProgressBar from '../common/circular-progress';

const AddGoogleDrive = ({ onAddDriveFolder, creatingDriveFolder }) => {
  const { translate } = useLocalization();

  return (
    <div style={{ height: '100%' }} className="border rounded  d-flex align-items-center justify-content-center">
      <div className="w-75 gap-2 d-flex flex-column justify-content-center align-items-center">
        <h6 className="text-center">This apartment does not have any associated google drive folder.</h6>
        <div className="d-flex justify-content-center w-100 gap-2">
          <Button
            variant="success"
            disabled={creatingDriveFolder}
            className="text-white d-flex align-items-center gap-1"
            size="sm"
            onClick={onAddDriveFolder}
          >
            {!creatingDriveFolder && <PlusLg />}{' '}
            <h6 className="mb-0 smallFont">
              {translate(creatingDriveFolder ? 'creating_drive_folder' : 'click_to_create_folder')}
            </h6>
          </Button>
        </div>
      </div>
    </div>
  );
};

const AddEditApartmentModal = ({
  show,
  onHide,
  adminCompTable,
  editMode,
  initialData,
  onSubmit,
  showProgress,
  onDeleteApartmentClick,
  createDriveFolderInApartment,
  creatingDriveFolder,
  assigneUsers,
  creatingNewFolder
}) => {
  let { translate } = useLocalization();

  const [apartmentExtraInfo, setApartmentExtraInfo] = useState({});
  const [apartment, setApartment] = useState({});
  const [customerPickerModalMeta, setCustomerPickerModalMeta] = useState(null);
  const [openDriveManager, setOpenDriveManager] = useState(false);

  const [customersMatchingApartment, setCustomersMatchingApartments] = useState([]);
  const [fetchingCustomersMatchingApartments, setFetchingCustomersMatchingApartments] = useState(false);
  const [viewCustomersMatchingApartment, setViewCustomersMatchingApartments] = useState(false);

  useEffect(() => {
    setApartmentExtraInfo({
      _id: initialData?._id,
      type: initialData?.type,
      status: initialData?.status || '',
      city: initialData?.city || '',
      gushTatHelka: initialData?.gushTatHelka || '',
      customers: initialData?.customers || [],
      driveFolder: initialData?.driveFolder || null
    });
  }, [initialData]);

  let apartmentType = useMemo(() => apartmentExtraInfo?.type, [apartmentExtraInfo]);

  const apartmentValues = useMemo(() => {
    if (!show || !apartmentType) return null;
    if (apartmentType === apartmentTypes[0].value) return { irrAppartments: editMode ? [initialData] : [] };
    if (apartmentType === apartmentTypes[1].value) return { pinuiBinuiIrrAppartments: editMode ? [initialData] : [] };
    if (apartmentType === apartmentTypes[2].value)
      return { paperApartmentIrrApartments: editMode ? [initialData] : [] };
  }, [apartmentType, show, initialData]);

  const saveApartment = useCallback(() => {
    let apartmentInfo = { ...apartment, ...apartmentExtraInfo };

    onSubmit(apartmentInfo);
  }, [apartment, onSubmit, apartmentExtraInfo]);

  const createNewFolder = useCallback(() => {
    let apartmentInfo = { ...apartment, ...apartmentExtraInfo };

    onSubmit(apartmentInfo, true);
  }, [apartment, onSubmit, apartmentExtraInfo]);

  const selectCustomers = () => {
    let selectedCustomer = customerPickerModalMeta?.selectedCustomer;
    setApartmentExtraInfo({ ...apartmentExtraInfo, customers: selectedCustomer });
    setCustomerPickerModalMeta(null);
  };

  const onDriveFolderClick = () => {
    setOpenDriveManager(!openDriveManager);
  };

  const onCreateDriveFolderClick = () => {
    if (editMode) {
      createDriveFolderInApartment(apartmentExtraInfo?._id);
    }
  };

  const fetchCustomersMatchingApartments = async () => {
    try {
      setFetchingCustomersMatchingApartments(true);
      let apartmentId = apartmentExtraInfo?._id;
      const { response, error } = await makeApiRequests({
        endpoint: ENDPOINTS.USERS_LIST,
        requestBody: {
          filter: {
            role: 'Customer'
          },
          apartmentId
        }
      });
      if (error) {
        return toast.error(error);
      }
      setCustomersMatchingApartments(response);
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingCustomersMatchingApartments(false);
    }
  };

  const ApartmentOptions = useCallback(() => {
    return (
      <div className="mx-2 d-flex align-items-center gap-1">
        <>
          {editMode ? (
            <>
              <Button
                size="sm"
                className="d-flex align-items-center gap-1"
                variant={openDriveManager ? 'outline-danger' : 'outline-dark'}
                onClick={onDriveFolderClick}
              >
                <Folder />
                <h6 className="mb-0 smallFont">{translate(openDriveManager ? 'close_drive' : 'open_drive')}</h6>
              </Button>
              <Button
                size="sm"
                className="d-flex align-items-center gap-1"
                variant={'dark'}
                onClick={() => setViewCustomersMatchingApartments(true)}
              >
                <PersonCircle />
                <h6 className="mb-0 smallFont">View matching customers</h6>
              </Button>
            </>
          ) : (
            <Button
              size="sm"
              className="d-flex align-items-center gap-1"
              variant={'outline-dark'}
              onClick={createNewFolder}
              disabled={creatingNewFolder || showProgress}
            >
              {creatingNewFolder ? <CircularProgressBar size={1.2} /> : <Folder />}
              <h6 className="mb-0 smallFont">{creatingNewFolder ? 'Creating folder' : 'Create folder'}</h6>
            </Button>
          )}
        </>
      </div>
    );
  }, [editMode, createNewFolder, showProgress, creatingNewFolder, onDriveFolderClick, openDriveManager]);

  const ModalFooter = useCallback(() => {
    return (
      <div className="w-100 d-flex justify-content-between align-items-center mx-2">
        {' '}
        <div>
          {editMode && (
            <Button
              size="sm"
              disabled={showProgress || creatingDriveFolder || creatingNewFolder}
              className="d-flex align-items-center gap-1"
              variant="danger"
              onClick={() => onDeleteApartmentClick(initialData)}
            >
              <Trash /> <h6 className="mb-0 smallFont">{translate('delete')}</h6>
            </Button>
          )}
        </div>
        <div className="d-flex align-items-center gap-1">
          <Button
            disabled={showProgress || creatingDriveFolder || creatingNewFolder}
            size="sm"
            className="text-white"
            variant="danger"
            onClick={onHide}
          >
            {translate('close')}
          </Button>
          <Button
            disabled={showProgress || creatingDriveFolder || creatingNewFolder}
            size="sm"
            className="text-white"
            variant="success"
            onClick={saveApartment}
          >
            {translate(showProgress ? 'please_wait' : 'save')}
          </Button>
        </div>
      </div>
    );
  }, [
    show,
    editMode,
    onDeleteApartmentClick,
    initialData,
    apartment,
    showProgress,
    editMode,
    apartmentExtraInfo,
    saveApartment,
    creatingDriveFolder,
    onHide,
    creatingNewFolder
  ]);

  useEffect(() => {
    if (editMode && apartmentExtraInfo?._id) {
      fetchCustomersMatchingApartments();
    }
  }, [editMode, apartmentExtraInfo]);

  if (!show) return;

  return (
    <AppModal
      fullscreen
      modalBodyClassName="p-0"
      show={show}
      onHide={onHide}
      title={!editMode ? 'Add apartment' : 'Update apartment'}
      footerContent={ModalFooter}
      renderMoreInHeader={ApartmentOptions}
    >
      {openDriveManager ? (
        <div className="p-2 h-100">
          {apartmentExtraInfo?.driveFolder ? (
            <GoogleDriveManager googleDriveFolderId={apartmentExtraInfo?.driveFolder} />
          ) : (
            <AddGoogleDrive creatingDriveFolder={creatingDriveFolder} onAddDriveFolder={onCreateDriveFolderClick} />
          )}
        </div>
      ) : (
        <AddEditApartmentForm
          handleSelectCustomer={selectCustomers}
          editMode={editMode}
          apartmentType={apartmentType}
          apartmentValues={apartmentValues}
          setApartment={setApartment}
          adminCompTable={adminCompTable}
          apartmentExtraInfo={apartmentExtraInfo}
          setApartmentExtraInfo={setApartmentExtraInfo}
          customerPickerModalMeta={customerPickerModalMeta}
          setCustomerPickerModalMeta={setCustomerPickerModalMeta}
          assigneUsers={assigneUsers}
        />
      )}
      <ViewCustomersMatchingApartmentModal
        show={viewCustomersMatchingApartment}
        onHide={() => setViewCustomersMatchingApartments(false)}
        customers={customersMatchingApartment}
        showProgress={fetchingCustomersMatchingApartments}
      />
    </AppModal>
  );
};

export default AddEditApartmentModal;
