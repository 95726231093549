import React from 'react';
import { Button, Dropdown, FormCheck } from 'react-bootstrap';
import useLocalization from '../../../../../hooks/useLocalization';
import PercentageInput from '../../../../common/PercentageInput';
import SmallDropdownToggle from '../../../../common/SmallDropdownToggle';

const StrategyScenario = ({
  showStressTestAndArmaggedonToClient,
  readOnlyMode,
  stressTestApplied,
  setStressTestApplied,
  activeScenario,
  scenarioOptions,
  onActiveScenarioChange,
  onArmagedonClick,
  armageddonDetails,
  inflationApplied,
  onInflationApplyCheckedChange,
  inflationValue,
  onInflationValueChange
}) => {
  const { translate } = useLocalization();

  return (
    <div className="my-2">
      <div className="d-flex align-items-center mb-1">
        <div className="flex-grow-1">
          <h6 className="mb-0 smallFont text-muted fw-bold">{translate('current_scenario')}: </h6>
        </div>

        {showStressTestAndArmaggedonToClient && (
          <FormCheck
            style={{ minHeight: '0px' }}
            onChange={e => setStressTestApplied(e.target.checked)}
            checked={stressTestApplied}
            disabled={readOnlyMode}
            className="d-md-none align-items-center mid"
            inline
            label={<span className="mx-1">{translate('apply_stress_test')}</span>}
          />
        )}
      </div>
      <Dropdown className="d-inline-block mx-0">
        <SmallDropdownToggle variant="primary">{activeScenario.name}</SmallDropdownToggle>
        <Dropdown.Menu>
          {scenarioOptions.map(s => (
            <Dropdown.Item key={s.name} onClick={() => onActiveScenarioChange(s)}>
              {s.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      {showStressTestAndArmaggedonToClient && !readOnlyMode && (
        <>
          {activeScenario.isArmageddon && armageddonDetails && (
            <div className="d-inline-block mx-1">
              <Button
                style={{
                  padding: '0px 5px',
                  fontSize: 13
                }}
                size="sm"
                variant="danger"
                onClick={onArmagedonClick}
              >
                {translate('configure_armageddon')}
              </Button>
            </div>
          )}

          <FormCheck
            style={{ minHeight: '0px' }}
            onChange={e => setStressTestApplied(e.target.checked)}
            checked={stressTestApplied}
            className="mx-2 d-none d-md-inline-block align-items-center mid"
            inline
            label={<span className="mx-0">{translate('apply_stress_test')}</span>}
          />
        </>
      )}

      <div className="d-inline-flex align-items-center px-2 mid">
        <FormCheck
          style={{ minHeight: '0px' }}
          checked={inflationApplied}
          onChange={onInflationApplyCheckedChange}
          disabled={readOnlyMode}
          className="align-items-center mid"
          label={
            <span className="mx-0">
              {translate('apply_inflation')}
              {inflationApplied ? ':' : ''}
            </span>
          }
        />
        {inflationApplied && (
          <>
            <PercentageInput
              style={{ width: 50, fontSize: 13, minHeight: '0px' }}
              className="mx-1 p-0 px-1 border-0 bg-primary-light"
              size="sm"
              value={inflationValue}
              onChange={onInflationValueChange}
              autoFocus
            />
          </>
        )}
      </div>

      {!readOnlyMode && <div></div>}
    </div>
  );
};

export default StrategyScenario;
