import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'react-bootstrap';
import { LocalizeContextProvider } from 'react-locale-language';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import { getActiveLanguage, languageContent } from './helpers/localization';
import { UserProvider } from './context/UserContext';
import MobileScreenChecker from './pages/MobileScreenChecker';

createRoot(document.getElementById('root')).render(
  <LocalizeContextProvider value={languageContent} languageCode={getActiveLanguage()}>
    <Router>
      <ThemeProvider>
        <UserProvider>
          <MobileScreenChecker>
            <App />
          </MobileScreenChecker>
        </UserProvider>
      </ThemeProvider>
    </Router>
  </LocalizeContextProvider>
);
