import { useEffect, useRef } from 'react';

/**
 * useDebouncedEffect hook works like useEffect but with a debounce functionality.
 *
 * @param effect - The effect function to run.
 * @param deps - Dependency array, same as useEffect.
 * @param delay - The debounce delay in milliseconds.
 */
const useDebouncedEffect = (effect, deps, delay, skipInitialEffect) => {
  const isFirstRender = useRef(true);

  useEffect(() => {
    // Skip effect on the initial render
    if (skipInitialEffect && isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const handler = setTimeout(() => {
      effect();
    }, delay);

    // Cleanup function
    return () => {
      clearTimeout(handler);
    };
  }, [...deps, delay]); // Make sure to add delay here so it re-runs if delay changes
};

export default useDebouncedEffect;
