import { snakeCase } from 'lodash';
import {
  APARTMENT_STATUS,
  DEFAULT_DATE_TIME_FORMAT,
  IRR_CALCULATOR_KEY,
  PAPER_CALCULATOR_KEY,
  PINUI_BINUI_CALCULATOR_KEY,
  PROGRAM_TYPES,
  rangeOptions,
  REAL_ESTATE_ANALYST_ROLE,
  RSU_OPTIONS_KEY,
  STATUS_INTEREST_IN_MY_COURSE,
  yesNoOptions
} from './constants';
import { apartmentTypes, isAdmin } from './global';
import { Badge, Button } from 'react-bootstrap';
import { Pencil, PersonCircle, Trash } from 'react-bootstrap-icons';
import cities from './israel-cities.json';
export const customerFormFields = (
  translate,
  user,
  salespersons,
  financialStrategists,
  realEstateAnalysts,
  crmContact
) => {
  return [
    {
      key: 'name',
      label: 'name',
      type: 'text',
      cellSizeMD: 4,
      hide: Boolean(crmContact)
    },
    {
      key: 'phone',
      label: 'Phone Number',
      type: 'text',
      cellSizeMD: 4,
      hide: Boolean(crmContact)
    },
    {
      key: 'email',
      label: 'Email',
      type: 'text',
      cellSizeMD: 4,
      hide: Boolean(crmContact)
    },

    {
      key: 'secondaryName',
      label: 'Secondary Name',
      type: 'text',
      cellSizeMD: 4,
      hide: Boolean(crmContact)
    },
    {
      key: 'secondaryPhone',
      label: 'Secondary Phone Number',
      type: 'text',
      cellSizeMD: 4,
      hide: Boolean(crmContact)
    },
    {
      key: 'secondaryEmail',
      label: 'Secondary Email',
      type: 'text',
      cellSizeMD: 4,
      hide: Boolean(crmContact)
    },

    {
      key: 'address',
      label: 'Address',
      type: 'text',
      cellSizeMD: 12,
      hide: Boolean(crmContact)
    },
    {
      key: 'status',
      label: 'Status',
      options: [
        { value: 'New', label: translate('new') },
        { value: 'Active', label: translate('active') },
        { value: 'Past', label: translate('past') },
        { value: STATUS_INTEREST_IN_MY_COURSE, label: translate(STATUS_INTEREST_IN_MY_COURSE.toLocaleLowerCase()) }
      ],
      cellSizeMD: 3,
      type: 'dropdown',
      hideTitle: false
    },
    {
      key: 'programType',
      label: 'Program Type',
      type: 'dropdown',
      options: PROGRAM_TYPES?.map(pT => ({ value: pT, label: translate(snakeCase(pT)) || pT })),
      clearableOption: true,
      //   clearableOptionTitle:'Select One',
      cellSizeMD: 3
    },
    {
      key: 'level',
      label: 'Level',
      type: 'dropdown',
      options: [0, 1, 2, 3]?.map(level => ({ value: level, label: level })),
      cellSizeMD: 3
    },
    {
      key: 'startDate',
      label: 'Start Date',
      type: 'date',
      cellSizeMD: 3
    },
    {
      key: 'additionalInfo',
      label: 'Additional Information',
      type: 'text',
      cellSizeMD: 12
    },
    {
      key: 'allowedCalculators',
      label: 'Allowed Calculators',
      type: 'groupped-checkbox',
      options: [
        {
          label: translate(snakeCase('Irr')),
          value: IRR_CALCULATOR_KEY
        },
        {
          label: translate(snakeCase('Pinui Binui')),
          value: PINUI_BINUI_CALCULATOR_KEY
        },
        {
          label: translate(snakeCase('Paper Apartment')),
          value: PAPER_CALCULATOR_KEY
        },
        {
          label: translate(snakeCase('RSU Options')),
          value: RSU_OPTIONS_KEY
        }
      ],
      cellSizeMD: 12
    },

    {
      key: 'showGeneralInfo',
      label: 'General Information',
      type: 'checkbox',
      cellSizeMD: 6
    },
    {
      key: 'isPublic',
      label: 'Example user',
      type: 'checkbox',
      cellSizeMD: 6
    },
    {
      key: 'salesperson',
      label: 'Salesperson',
      type: 'multi-select',
      options: salespersons?.map(sp => ({ label: sp?.name, value: sp?._id })),
      maxItemCustomMessage: length => `${length} user${length === 1 ? '' : 's'} selected`,
      cellSizeMD: 4,
      hide: !isAdmin(user?.role) || crmContact
    },
    {
      key: 'financialStrategist',
      label: 'Financial Strategist',
      type: 'multi-select',
      options: financialStrategists?.map(sp => ({ label: sp?.name, value: sp?._id })),
      maxItemCustomMessage: length => `${length} user${length === 1 ? '' : 's'} selected`,
      cellSizeMD: 4,
      hide: !isAdmin(user?.role) || crmContact
    },
    {
      key: 'realEstateAnalyst',
      label: 'Real Estate Analyst',
      type: 'multi-select',
      options: realEstateAnalysts?.map(sp => ({ label: sp?.name, value: sp?._id })),
      maxItemCustomMessage: length => `${length} user${length === 1 ? '' : 's'} selected`,
      cellSizeMD: 4,
      hide: !isAdmin(user?.role) || crmContact
    }
  ]?.filter(f => !f?.hide);
};

export const editCrmContactDetailsFields = (salespersons, financialStrategists, realEstateAnalysts) => {
  return [
    {
      key: 'firstName',
      label: 'First Name',
      cellSizeMD: 4
    },
    {
      key: 'lastName',
      label: 'Last Name',
      cellSizeMD: 4
    },
    {
      key: 'address',
      label: 'Address',
      type: 'text',
      cellSizeMD: 4
    },
    {
      key: 'phoneNumbers',
      label: 'Phone Numbers',
      type: 'phone',
      cellSizeMD: 6,
      isMultiple: true
    },
    {
      key: 'emails',
      label: 'Emails',
      type: 'email',
      cellSizeMD: 6,
      isMultiple: true
    },
    {
      key: 'salesperson',
      label: 'Salesperson',
      type: 'multi-select',
      options: salespersons?.map(sp => ({ label: sp?.name, value: sp?._id })),
      maxItemCustomMessage: length => `${length} user${length === 1 ? '' : 's'} selected`,
      cellSizeMD: 4
    },
    {
      key: 'financialStrategist',
      label: 'Financial Strategist',
      type: 'multi-select',
      options: financialStrategists?.map(sp => ({ label: sp?.name, value: sp?._id })),
      maxItemCustomMessage: length => `${length} user${length === 1 ? '' : 's'} selected`,
      cellSizeMD: 4
    },
    {
      key: 'realEstateAnalyst',
      label: 'Real Estate Analyst',
      type: 'multi-select',
      options: realEstateAnalysts?.map(sp => ({ label: sp?.name, value: sp?._id })),
      maxItemCustomMessage: length => `${length} user${length === 1 ? '' : 's'} selected`,
      cellSizeMD: 4
    }
  ];
};

export const apartmentTableColumns = ({ translate, users = [] }) => {
  const statusToColorMap = {
    'Bought By Our Client': 'dark',
    'Bought By Outsider': 'info',
    Available: 'success'
  };
  let rangeOpt = [...rangeOptions?.map(o => ({ label: o, value: o }))];
  let yesNoOpt = [...yesNoOptions?.map(o => ({ label: o, value: o }))];
  const allLabels = [
    {
      key: 'type',
      label: 'Apartment Type',
      valueSelector: app => apartmentTypes?.find(a => a?.value === app?.type)?.label || '',
      width: 150,
      type: 'text',
      searchOptions: apartmentTypes,
      isMultiSelect: false
    },
    {
      key: 'realEstateAnalyst',
      label: translate('real_estate_analyst'),
      valueSelector: app => users?.find(u => u._id === app?.realEstateAnalyst)?.name || '-',
      disableSort: true,
      searchOptions: users
        ?.filter(u => u.role === REAL_ESTATE_ANALYST_ROLE)
        ?.map(u => ({ label: u.name, value: u._id })),
      width: 150
    },
    {
      key: 'city',
      label: translate('city'),
      type: 'text',
      searchOptions: cities.map(c => ({ label: c.city, value: c.city })),
      isMultiSelect: true,
      maxItemCustomMessage: length => `${length} ${length > 1 ? 'cities' : 'city'}`,
      width: 150
    },
    {
      key: 'address',
      label: translate('address'),
      type: 'text',
      width: 150,
      disableSort: true
    },
    {
      key: 'status',
      label: translate('status'),
      type: 'text',
      width: 180,
      disableSort: true,
      searchOptions: APARTMENT_STATUS?.map(s => ({ label: s, value: s })),
      cellRenderer: apartment => (
        <Badge bg={statusToColorMap[apartment.status] || 'primary'}>{apartment.status || '-'}</Badge>
      )
    },
    {
      key: 'gushTatHelka',
      label: 'Gush helka tat helka',
      type: 'text',
      width: 150,
      disableSort: true
    },
    {
      key: 'customer',
      label: 'Customers',
      cellRenderer: app =>
        app?.customers?.length ? (
          <div className="d-flex align-items-center text-center flex-wrap gap-1 my-1">
            {app?.customers?.map(c => (
              <div key={c?._id} className="bg-primary d-flex gap-1 align-items-center text-white hover rounded p-1">
                <PersonCircle /> <h6 className="smaller fw-bold mb-0">{c?.name || c?.email || 'No name'}</h6>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center">-</div>
        ),
      width: 200,

      disableSort: true
    },
    {
      label: translate('area_in_square_meters'),
      key: 'areaInSquareMeters',
      type: 'number',
      apartmentProfileKey: 'smallApartment',
      width: 200
    },
    { label: translate('buying_price'), key: 'inputPrice', type: 'number', width: 150 },
    { label: translate('appraised_value'), key: 'appraisedValue', type: 'number', width: 150 },
    { label: translate('value_of_apartment_today'), key: 'valueOfApartmentToday', type: 'number', width: 150 },
    {
      label: translate('cost_of_time_to_delivery_after_buying'),
      key: 'costOfTimeToDeliveryAfterBuying',
      type: 'number',
      width: 200
    },
    { label: translate('monthly_rent'), key: 'monthlyRent', type: 'number', width: 150 },
    {
      label: translate('time_to_delivery_after_buying'),
      key: 'timeToDeliveryAfterBuyingInMonths',
      type: 'number',
      width: 200
    },
    {
      label: translate('holding_period'),
      key: 'realEstateHoldingPeriodInYears',
      type: 'number',
      width: 200
    },
    {
      label: translate('estimated_time_for_demolition'),
      key: 'estimatedTimeForDemolition',
      type: 'number',
      width: 200
    },
    {
      label: translate('estimated_time_for_finishing_contruction_from_demolition'),
      key: 'estimatedTimeForFinishingContruction',
      type: 'number',
      width: 300
    },
    {
      label: translate('required_cash_for_deal'),
      key: 'requiredCashForDeal',
      type: 'number',
      searchOptions: rangeOpt,
      width: 150
    },
    {
      label: translate('developer_loan'),
      key: 'developerLoan',
      type: 'text',
      searchOptions: yesNoOpt,
      width: 100
    },
    {
      label: translate('linear_payment'),
      key: 'linearPayment',
      type: 'text',
      searchOptions: yesNoOpt,
      width: 100
    },
    {
      label: 'developer_company_scale',
      key: 'developerCompanyScale',
      type: 'number',
      searchOptions: rangeOpt,
      width: 150
    },
    {
      label: translate('buying_decision_scale'),
      key: 'buyingDecisionScale',
      type: 'number',
      searchOptions: rangeOpt,
      width: 150
    },
    {
      label: translate('apartment_looks'),
      key: 'apartmentLooks',
      type: 'number',
      searchOptions: rangeOpt
    },
    {
      label: translate('large_scale_renovation'),
      key: 'largeRenovation',
      type: 'number',
      searchOptions: rangeOpt,
      width: 150
    },
    {
      label: translate('early_stage_deals'),
      key: 'earlyDeal',
      type: 'text',
      searchOptions: yesNoOpt,
      width: 100
    },
    {
      label: translate('has_3_floor_or_higher_without_elevator'),
      key: 'noElevator',
      type: 'text',
      searchOptions: yesNoOpt,
      width: 200
    },
    {
      label: translate('complicated_deals'),
      key: 'complicatedDeals',
      type: 'text',
      searchOptions: yesNoOpt
    },
    {
      key: 'createdAt',
      label: 'Created at',
      type: 'date',
      width: 150
    }
  ];

  return allLabels;
};
