import React, { useContext, useMemo } from 'react';
import { Redirect, Route, Switch, useLocation, withRouter } from 'react-router';
import Admin from '../components/admin';
import BrowseAppartments from '../components/appartment/BrowseAppartments';
import Calculators from '../components/calculators';
import CachedResponse from '../components/common/CachedResponse';
import Header from '../components/common/Header';
import NotFound from '../components/common/NotFound';
import Customer from '../components/customer';
import Profile from '../components/profile';
import { UserContext } from '../context/UserContext';
import { ALL_ROLES } from '../helpers/constants';
import { isCustomer, isSystemUser } from '../helpers/global';

const AuthenticatedApp = ({}) => {
  const { isUserLoggedIn, user, viewingUser } = useContext(UserContext);
  const authRole = useMemo(() => (viewingUser ?? user)?.['role'], [user, viewingUser]);

  const location = useLocation();
  if (!isUserLoggedIn) {
    return <Redirect from="/" to="/login" />;
  }

  if (location.pathname === '/') {
    return <Redirect from="/" to={isCustomer(authRole) ? '/customer' : '/admin'} />;
  }

  const RoutesWithUsers = ({ assigneUsers }) => {
    return (
      <>
        <Header />
        <Switch>
          {isCustomer(authRole) ? (
            <Route path="/customer">
              <Customer assigneUsers={assigneUsers} />
            </Route>
          ) : (
            <Route path="/admin">
              <Admin assigneUsers={assigneUsers} />
            </Route>
          )}
          {/* <Route path="/customer">
          <Customer />
        </Route> */}
          <Route path="/calculators">
            <Calculators assigneUsers={assigneUsers} />
          </Route>
          <Route path="/profile">
            <Profile />
          </Route>
          {isSystemUser(authRole) && (
            <Route path="/apartments">
              <CachedResponse
                listEndpoint={'comps'}
                requestBody={{ filter: { customer: false } }}
                responseModifier={response => response[0]}
                render={compTable => <BrowseAppartments assigneUsers={assigneUsers} adminCompTable={compTable} />}
              />
            </Route>
          )}
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </>
    );
  };

  return isCustomer(authRole) ? (
    <RoutesWithUsers assigneUsers={[]} />
  ) : (
    <CachedResponse
      listEndpoint={'users'}
      requestBody={{ filter: { role: ALL_ROLES } }}
      responseModifier={response => response}
      render={assigneUsers => <RoutesWithUsers assigneUsers={assigneUsers} />}
    />
  );
};

export default withRouter(AuthenticatedApp);
