import * as Yup from 'yup';
import { investmentTypeOptions, transferFrequencyTypeOptions, transferValueTypeOptions } from '../helpers/constants';

const investmentOptionSchema = Yup.object().shape({
  type: Yup.string()
    .oneOf(investmentTypeOptions)
    .required('type is required'),
  notes: Yup.string().trim(),
  transferValueType: Yup.string()
    .oneOf(transferValueTypeOptions)
    .required('Transfer Type is required'),
  frequency: Yup.string()
    .oneOf(transferFrequencyTypeOptions)
    .required('Transfer Frequency is required'),
  fromId: Yup.string().trim(),
  toId: Yup.string().trim(),
  startYear: Yup.number().required('Please select a year'),
  startMonth: Yup.number()
    .min(0)
    .max(11)
    .required('Start month is required'),
  endYear: Yup.number().nullable(),
  endMonth: Yup.number().nullable(),
  continueAfterSell: Yup.boolean()
});

export default investmentOptionSchema;
