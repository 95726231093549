import React from 'react';

const UnderlineButton = ({
  Icon,
  onClick,
  text,
  variant = 'dark',
  iconSize = 10,
  className = '',
  fontSize = 'tiny',
  disabled,
  underline = true
}) => {
  return (
    <span
      className={`d-inline-block px-1 text-${disabled ? 'muted' : variant} ${
        !disabled ? 'hover-light' : ''
      } ${underline ? 'underline' : ''} rounded ${fontSize} ${className}`}
      size="sm"
      onClick={e => !disabled && onClick && onClick(e)}
    >
      {Icon && <Icon size={iconSize} className=" mx-1" />}
      <span className="align-center">{text}</span>
    </span>
  );
};

export default UnderlineButton;
