import React, { useMemo } from 'react';
import Switch from 'react-switch';

const SwitchInput = ({ value, onChange, falseValue = false, trueValue = true, label }) => {
  const internalValue = useMemo(() => {
    return value === trueValue;
  }, [value, trueValue]);

  return (
    <>
      {label && <span className="mx-2 mid">{label}</span>}
      <Switch
        checked={internalValue}
        onChange={checked => onChange(checked ? trueValue : falseValue)}
        height={17}
        width={50}
        offHandleColor="#d1e6cc"
        onHandleColor="#d1e6cc"
      />
    </>
  );
};

export default SwitchInput;
