import { snakeCase } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { Alert, Button, Col, Form, FormCheck, Modal, Row } from 'react-bootstrap';
import { LocalizeContext } from 'react-locale-language';
import { monthSmall, months, transferTypeOptions } from '../../../../../helpers/constants';
import HorizontalProgress from '../../../../common/HorizontalProgress';

const fieldsToHide = ['linkedBankAccount', 'linkedApartment', 'buyPercentage', 'sellPercentage', 'fromId', 'toId'];

const fieldsToReplace = {
  linkedBankAccountName: 'linkedBankAccount',
  linkedApartmentName: 'linkedApartment',
  onlyApartment: 'is_only_apartment',
  isSellAutomaticallyCalculated: 'selling_tax_calculated_automatically',
  isBuyAutomaticallyCalculated: 'buying_tax_calculated_automatically',
  buy: 'buying_tax',
  sell: 'selling_tax',
  relatedAssets: 'linked_asset',
  cashflowChanges: 'one_off_change',
  fromInstrumentName: 'transfer_applied_to',
  toInstrumentName: 'balance_with',
  transferValueType: 'transfer_type',
  yearlyMaterialCostIndexRise: 'yearly_materials_cost_index_rise_comp',
  paperApartmentPayments: 'payments',
  affectsRiskManagement: 'is_salary'
};

const getValuesToShow = (key, subObject, originalObject = {}) => {
  let newValue = subObject[key];
  let originalValue = originalObject[key];

  if (key.toLowerCase().includes('month') && newValue !== null && newValue !== undefined) {
    return months[newValue];
  }

  if (key === 'transferValueType') {
    return transferTypeOptions.find(o => o.value === newValue)?.label;
  }

  if (Array.isArray(newValue)) {
    return newValue.join(', ');
  }

  if ('boolean' === typeof newValue) {
    return newValue ? 'Yes' : 'No';
  }

  if (typeof originalValue === 'number' && !newValue) {
    newValue = '0';
  }

  return newValue?.toString();
};

const convertChangedFieldsToKeyValueArray = (fieldsToShow, translate, originalObject) => {
  return Object.keys(fieldsToShow)
    .filter(k => !fieldsToHide.includes(k))

    .flatMap(key => {
      let keyToShow = translate(snakeCase(fieldsToReplace[key] || key)) || fieldsToReplace[key] || key;

      if (key === 'cashflowChanges') {
        return [
          {
            key,
            keyToShow,
            value:
              fieldsToShow[key].length > 0
                ? fieldsToShow[key]
                    .map(
                      (c, index) => `${index + 1}. From: ${monthSmall[c.startMonth]} ${c.startYear}, Value: ${c.value}`
                    )
                    .join(',  ')
                : '-'
          }
        ];
      }

      if (key === 'paperApartmentPayments') {
        return [
          {
            key,
            keyToShow,
            value:
              fieldsToShow[key].length > 0
                ? fieldsToShow[key]
                    .map((p, index) => `${index + 1}. Date: ${monthSmall[p.month]} ${p.year}, Value: ${p.amount}`)
                    .join(',  ')
                : '-'
          }
        ];
      }

      if (fieldsToShow[key] && !Array.isArray(fieldsToShow[key]) && typeof fieldsToShow[key] === 'object') {
        return {
          key,
          keyToShow,
          value: `<div class='mt-1 p-2 border rounded bg-light'>${Object.keys(fieldsToShow[key])
            .filter(k => !fieldsToHide.includes(k))
            .map(subKey => {
              let subKeyToShow = fieldsToReplace[subKey] || subKey;
              subKeyToShow = translate(snakeCase(subKeyToShow)) || subKeyToShow;

              return subKeyToShow + ': ' + getValuesToShow(subKey, fieldsToShow[key], originalObject[key]);
            })
            .join('<br/>')}</div>`
        };
      }

      return [
        {
          key,
          keyToShow,
          value: getValuesToShow(key, fieldsToShow, originalObject)
        }
      ];
    })
    .sort(({ keyToShow: k1 }, { keyToShow: k2 }) => k1.localeCompare(k2));
};

const StrategyGroupSelectorModal = ({
  show,
  strategies = [],
  selectedStrategies = [],
  onStrategyCheckedChange,
  onHide,
  showProgress,
  onAllStrategyCheckedChange,
  problemStrategies = [],
  warningStrategies = [],
  fieldsToShow = {},
  originalObject = {},
  selectedFields = [],
  onSelectedFieldCheckChange,
  progressText,
  onSubmit
}) => {
  const { translate, langCode } = useContext(LocalizeContext);
  const strategyOptions = useMemo(
    () =>
      strategies.map(s => ({
        ...s,
        warning: warningStrategies
          .filter(ws => ws.strategyName === s.name)
          .map(ws => `[${ws.warning}]`)
          .join(' ')
      })),
    [strategies, warningStrategies]
  );

  const changedKeyValue = useMemo(() => {
    if (!fieldsToShow) return [];

    return convertChangedFieldsToKeyValueArray(fieldsToShow, translate, originalObject);
  }, [fieldsToShow, langCode]);

  return (
    <Modal className="bg-dark bg-opacity-25" show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton={!showProgress}>
        <Modal.Title>
          <h6 className="mb-0">Update Multiple Strategies</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-auto p-4">
        {show && (
          <>
            <h6 className="text-muted mid">
              Following are the strategies that belong to the same group as the active strategy. Please select the
              strategies that you want to apply the same action to.
            </h6>

            <Row className="my-3 border rounded px-1 py-2 mx-1">
              {strategyOptions.length > 0 ? (
                <>
                  <Col xs={12}>
                    <FormCheck
                      disabled={showProgress}
                      className="mid mb-0"
                      label={'Select All'}
                      checked={strategyOptions.every(s => selectedStrategies.includes(s._id))}
                      onChange={e => onAllStrategyCheckedChange(e.target.checked)}
                    />
                    <hr className="mt-0 mb-1" />
                  </Col>
                  {strategyOptions.map(s => (
                    <Col xs={12} key={s._id} className="mt-2">
                      <FormCheck
                        disabled={showProgress}
                        className="mid"
                        label={
                          <b>
                            {s.name} {s.warning}
                          </b>
                        }
                        checked={selectedStrategies.includes(s._id)}
                        onChange={() => onStrategyCheckedChange(s._id)}
                      />
                    </Col>
                  ))}
                </>
              ) : (
                <h6 className="mb-0 text-center text-muted smallFont">
                  <i>No strategies to show!</i>
                </h6>
              )}
            </Row>

            {changedKeyValue.length > 0 && (
              <>
                <h6 className="text-muted mid">Please select the fields to be updated in the selected strategies:</h6>
                <div className="px-1 py-2">
                  {changedKeyValue.map(({ key, keyToShow, value }, index) => (
                    <Form.Check
                      checked={selectedFields.includes(key)}
                      label={
                        <h6 key={key} className="mt-2 smallFont">
                          <b>
                            {index + 1}. {keyToShow}:{' '}
                          </b>
                          <span dangerouslySetInnerHTML={{ __html: value || 'N/A' }}></span>
                        </h6>
                      }
                      onChange={e => onSelectedFieldCheckChange(key)}
                    />
                  ))}
                </div>
              </>
            )}

            {problemStrategies.length > 0 && (
              <>
                <h6 className="text-muted mid">The group edit could not be applied to the following strategies:</h6>
                <Alert bg="warning" className="px-1 py-2">
                  {problemStrategies.map(({ strategyName, problem }, index) => (
                    <h6 key={strategyName} className="mt-2 smallFont">
                      <b>
                        {index + 1}. {strategyName}:{' '}
                      </b>
                      {problem}
                    </h6>
                  ))}
                </Alert>
              </>
            )}
          </>
        )}

        {showProgress && <HorizontalProgress text={progressText} />}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={showProgress}
          size="sm"
          variant="success"
          className="mr-2 text-white"
          onClick={() =>
            onSubmit(
              selectedStrategies,
              selectedFields.reduce((acc, key) => {
                return { ...acc, [key]: fieldsToShow[key] };
              }, {})
            )
          }
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default StrategyGroupSelectorModal;
