export const languageContent = {
  English: {
    payments: 'Payments',
    down_payment: 'Down Payment',
    paying_tax_investing_somewhere: 'Paying the tax and investing somewhere else',
    paying_tax_at_period_end: 'Paying tax at period end',
    tax: 'Tax',
    pay_installment_cost: 'Pay installment cost',
    pay_indexing_cost: 'Pay indexing cost',
    gradual_payment: 'Gradual Payment',
    pay: 'Pay',
    target_irr: 'Target IRR',
    real_estate_irr: 'Real Estate IRR',
    irr: 'IRR',
    irr_no_reconstruction: 'IRR in case there is no reconstruction',
    net_yearly_return_on_rent: 'Net yearly return on rent',
    yearly_return_from_rent: 'Yearly return from rent',
    return_cashflow: 'Expected Return (Cash Flow)',
    total_roi: 'Total roi',
    return_appreciation: 'Expected Return (Appreciation)',
    bad: 'Bad',
    current_scenario: 'Current Scenario',
    good: 'Good',
    original: 'Original',
    armageddon: 'Armageddon',
    armageddon_scenario: 'Armageddon Scenario',
    margin_ceiling: 'Margin Ceiling',
    real_estate_holding_period: 'Real estate holding period',
    real_estate_holding_period_months: 'Real estate holding period (months)',
    grace_period: 'Grace Period',
    configure: 'Configure',
    role: 'Role',
    description: 'Description',
    expected_cashflow: 'Expected Cashflow',
    cashflow: 'Cashflow',
    multi_generational: 'Multi-generational',
    strategic_planning: 'Strategic planning',
    frequency: 'Frequency',
    end_date_calculated_automatically: 'End date calculated automatically?',
    end_date: 'End Date',
    added_on: 'Added On',
    start_date: 'Start Date',
    share_with_new_user: 'Share with new user',
    show_general_info_to_client: 'Show General Info To Client?',
    share_strategies: 'Share Strategies',
    share: 'Share',
    annuity: 'Annuity',
    yearly: 'Yearly',
    buying_year: 'Buying Year',
    end_year: 'End Year',
    selling_year: 'Selling Year',
    start_year: 'Start Year',
    configure_armageddon: 'Configure Armageddon',
    change: 'Change',
    year: 'Year',
    kept_remaining_cash: 'Kept remaining cash <b>{amount}</b>',
    saving_cashflow_changes: 'Saving cashflow changes',
    maintain_margin: 'Maintain margin',
    save: 'Save',
    secondary_name: 'Secondary Name',
    realtor_seller_name: 'Realtor/ seller name + phone number',
    name: 'Name',
    name_optional: 'Name (Optional)',
    parameters: 'Parameters',
    show_apartments_for: 'Show apartments for',
    sent_remaining_cash: 'Sent remaining cash <b>{amount}</b> to linked bank account <b>{accountName}</b>',
    welcome_to_ETICA_financials: 'Hello! Welcome to Ethica Finance',
    welcome_message: 'Welcome to Ethica Finance',
    rent: 'Rent',
    clone_strategy: 'Clone Strategy {strategy}',
    clone: 'Clone',
    duplicate: 'Duplicate',
    rent_pre_contruction: 'Rent Preconstruction',
    rent_post_contruction: 'Rent Post Construction',
    total_income_from_rent_minus_monthly_payment: 'Total income from rent minus total monthly payments',
    total_rent: 'Total Rent',
    return_cashflow_monthly: 'Expected Monthly Cashflow',
    monthly_rent: 'Monthly rent',
    rent_during_contruction: 'Rent During Construction (default is as pre- demolition)',
    forgot_password: 'Forgot password?',
    marginal_rate_of_income: 'Marginal rate of income',
    capital_appreciation_tax: 'Capital appreciation tax',
    one_off_change: 'One Off Change',
    interest_rate_changes: 'Interest Rate Changes',
    one_off_change_by: 'One off change by',
    heads_up: 'Heads Up!',
    update_start_date_note_for_strategies:
      'Please note, This will update the start month and year of each strategy in the customer.',
    area_in_square_meters: 'Area In Square Meters',
    unarchive: 'Unarchive',
    saving_variable: 'Saving Variable',
    saving_admin: 'Saving Admin',
    saving_taxbrackets: 'Saving Tax Bracket',
    saving_customer: 'Saving Customer',
    saving_preset: 'Saving Preset',
    archiving_strategy: 'Archiving strategy',
    future_value_pre_tax: 'Future value pre-tax',
    net_value: 'Net value',
    total_value: 'Total value',
    value_of_stocks_with_marginal_tax_rate: 'Value of stocks with marginal tax rate',
    value_of_stocks_with_capital_aprreciation_tax_rate: 'Value of stocks with capital aprreciation tax rate',
    value_of_apartment_today: 'Value of received apartment today',
    value_after_renovation: 'Value after renovation',
    appreciation: 'Appreciation',
    margin_floor: 'Margin Floor',
    level: 'Level',
    buying: 'Buying',
    mortgage_interest: 'Mortgage interest',
    interest: 'Interest',
    loan_interest: 'Loan Interest',
    profit_from_selling_brfore_tax: 'Profit from selling (before tax)',
    profit_from_selling_after_tax: 'Profit from selling (after tax)',
    total_net_profit: 'Total net profit',
    profit_at_excersising_pre_tax: 'Profit at excersising pre tax',
    profit: 'Profit',
    pensioni: 'Pensioni',
    quaterly: 'Quaterly',
    asset_name: 'Link Asset',
    link_a_new_liability: 'Link a new liability',
    stock_KSH: 'stock KSH',
    video_link: 'Video Link',
    total_loan_decrease: 'Total loan decrease',
    asset_category: 'Asset Category',
    category: 'Asset Category',
    apartment_floor_number: "Apartment's floor number",
    files_from_drive: 'Files From Drive',
    other_files: 'Other Files',
    set_a_ceiling_value: 'Set a ceiling value',
    set_a_floor_value: 'Set a floor value',
    set: 'Set',
    get_reset_email: 'Get Reset Email',
    fixed: 'Fixed',
    view_files: 'View Files',
    view_profile: 'View Profile',
    yearly_growth_rate: 'Yearly Growth Rate (%)',
    balance_sheet_growth_with_time: 'Balance Sheet Growth With Time',
    add_new_strategy: 'Add New Strategy',
    viewing_user: 'Viewing User',
    open_drive_folder: 'Open Drive Folder',
    profile_details: 'Profile Details',
    difference: 'Difference',
    active: 'Active',
    actions: 'Actions',
    edit_mode: 'Edit Mode',
    action: 'Action',
    filters: 'Filters',
    stock_portfolio: 'stock portfolio',
    end_value: 'End value',
    monthly_value: 'Monthly Value',
    initial_value: 'Initial Value',
    value: 'Value',
    edit: 'Edit',
    yearly_appreciation: 'Yearly appreciation',
    annual_appreciation: 'Annual Appreciation',
    costs: 'Costs',
    expected_value_appreciation_from_change: 'Expected Value Appreciation From Additional Costs',
    expected_appreciation: 'Expected Appreciation',
    value_added_by_renovation: 'Value Added by Renovation',
    do_we_need_to_pay_material_cost_index: 'Do we need to pay material cost index?',
    renovation_cost: 'Renovation cost',
    total_deal_cost: 'Total deal cost',
    cost_of_retailer_lawyer: 'Cost of realtor, lawyer, buying tax and all related costs',
    realtor_cost_in_percentage_terms_pre_vat: 'Realtor cost (in percentage terms pre V.A.T)',
    cost_of_time_to_delivery_after_buying: 'Cost of time to delivery after buying',
    cost_of_vacancy_and_annual_repairs: 'Cost of vacancy and annual repairs',
    realtor_cost: 'Realtor cost (including VAT)',
    purchase_cost: 'Purchase Cost',
    additional_buy_cost: 'Additional Buying Cost',
    sales_cost: 'Sales Cost',
    additional_sell_cost: 'Additional Selling Cost',
    related_costs: 'Related costs (lawyer, apraiser etc.)',
    additional_costs_lawyer_apraiser: 'Additional costs (lawyer, apraiser etc.)',
    additional_cost: 'Additional Cost',
    additional_purchase_cost_for: 'Additional Purchase Cost for',
    additional_costs_for_making_the_apartment_ready_for_rent_paper_apartment:
      'Additional costs for making the apartment ready for rent',
    city: 'City',
    drive_folder_not_created: 'A drive folder for this client has not yet been created. Tap here to create one',
    initial_account_balance: 'Initial Account Balance',
    update_reinvestment: 'Update Reinvestment',
    update_strategy_name: 'Update Strategy Name',
    update_cashflow_change: 'Update Cashflow Change',
    group_update: 'Group Update',
    update_variable: 'Update Variable',
    update_admin: 'Update Admin',
    update_taxbrackets: 'Update Tax Bracket',
    update_customer: 'Update Customer',
    resort_to_default: 'Resort to default',
    update_notes: 'Update Notes',
    reapply_comp: 'Reapply Comp',
    update_preset: 'Update Preset',
    updating_preset: 'Updating Preset',
    update: 'Update',
    updating_armageddon_scenario: 'Updating Armageddon Scenario',
    update_strategy_start_time: 'Update Strategy Start Time',
    updating_cashflow_changes: 'Updating cashflow changes',
    to: 'To',
    past: 'Past',
    symbol: 'Symbol',
    old_password: 'Old Password',
    new_password: 'New Password',
    password: 'Password',
    action_summary: 'Action Summary',
    loan_summary: 'Loan Summary',
    one_off_event_summary: 'One Off Event Summary',
    end_with_asset: 'End with asset',
    end_with_maturity: 'End with maturity',
    end: 'End',
    stock_type: 'Stock Type',
    program_type: 'Program Type',
    type_of_interest: 'Interest Type',
    interest_type: 'Interest Type',
    transfer_type: 'Transfer Type',
    loan_type: 'Loan Type',
    apartment_type: 'Apartment Type',
    type: 'Type',
    total_assets: 'Total Assets',
    total_liabilities: 'Total Liabilities',
    total_income: 'Total Income',
    total_expenditure: 'Total Expenditure',
    total: 'Total',
    rearrange_strategies: 'Rearrange Strategies',
    close: 'Close',
    shown_to_customer: 'Shown To Customer',
    ignored_assets: 'Ignored Assets',
    related_assets: 'Related Assets',
    linked_asset: 'Linked Asset ',
    assets: 'Assets',
    asset_liquidated_on: 'Asset liquidated on <b>{date}</b>',
    asset_is_linked_ib_stock: 'This asset is the linked IB stock of the liability. So trying partial selling',
    asset: 'Asset',
    can_sell_partially: 'Can Sell Partially?',
    percentage_of_payment_delayed_to_delivery: 'Percentage of payment delayed to delivery',
    risk_management: 'Risk Management',
    manage_users: 'Manage Users',
    stocks: 'Stocks',
    net_after_excersising_and_selling_stocks: 'Net after excersising and selling stocks',
    net: 'Net',
    real_estate: 'Real Estate',
    selected: 'Selected',
    advanced: 'Advanced',
    new_variables: 'New Variables',
    variables: 'Variables',
    user_already_invited:
      'This user has already been invited once. Proceeding will update the current user id and password of the user.',
    mortgage_to_be_taken: 'Mortgage to be taken (this will depend on several options)',
    maximal_mortgage_available: 'Maximal Mortgage Available',
    mortgage: 'Mortgage',
    is_salary: 'Is Salary?',
    unarchiving_customer: 'Unarchiving customer',
    something_went_wrong_try_again: 'Something went wrong! Click Here to try again',
    margin_call: 'Margin Call',
    showing: 'Showing',
    normal_state: 'Normal State',
    detailed_mode: 'Detailed Mode',
    extreme_case: 'Extreme Case',
    depressed_state: 'Depressed State',
    mode: 'Mode',
    status: 'Status',
    updating_strategy_name: 'Updating strategy name',
    updating_video_link: 'Updating Video Link...',
    updating_variable: 'Updating Variable',
    updating_admin: 'Updating Admin',
    updating_taxbrackets: 'Updating Tax Bracket',
    updating_customer: 'Updating Customer',
    updating_notes: 'Updating Notes',
    archiving_customer: 'Archiving customer',
    vat: 'V.A.T',
    total_years: 'Total Years',
    number_floor_in_building: 'Number of floors in the building',
    user_id: 'User Id',
    secondary_phone_number: 'Secondary Phone Number',
    phone_number: 'Phone Number',
    months_to_sustain_negative: 'Months to Sustain Negative Cashflow (After Crash)',
    number_of_rooms: 'number of rooms',
    buying_tax: 'Buying Tax',
    buying_tax_for: 'Buying Tax for',
    realtor_cost_for: 'Realtor Cost for',
    tax_on_excersising: 'Tax on excersising (selling stocks)',
    tax_on_rent: 'Tax on rent',
    tax_calculated_automatically: 'Tax calculated automatically?',
    buying_tax_calculated_automatically: 'Buying Tax calculated automatically?',
    selling_tax_calculated_automatically: 'Selling Tax calculated automatically?',
    total_tax: 'Total Tax',
    tax_amount: 'Tax Amount',
    tax_and_additional_costs: 'Tax and additional costs',
    selling_tax: 'Selling Tax',
    stock: 'STOCK',
    admins: 'Admins',
    new_admin: 'New Admin',
    sorting_by: 'Sorting By',
    partially_sold_from_asset:
      'Partially sold <b>{amount}</b> from asset <b>{assetName}</b> to meet the margin requirements.',
    partial_selling_not_possible: 'Partial Selling is not possible for this stock. Trying complete liquidation',
    selling: 'Selling',
    sell: 'Sell',
    sort_by: 'Sort By',
    fetching_admins: 'Fetching admins',
    apartment_info: 'Apartment Info',
    additional_information: 'Additional Information',
    general_information: 'General Info',
    who_is_typical_renter_for_apartment: 'Who is the typical renter for the apartment?',
    allowed_calculators: 'Allowed Calculators',
    calculators: 'Calculators',
    pinui_binui: 'Pinui Binui',
    irr_calculator: 'IRR Calculator',
    target_price_calculator: 'Target Price Calculator',
    paper_apartment: 'Paper apartment',
    rsu_options: 'RSU and Options',
    options_calculator: 'Options calculator',
    rsu_calculator: 'RSU calculator',
    group_delete_cashflow: 'Group delete cashflow',
    group_delete_only_asset: 'Group delete only Asset',
    group_delete_liability: 'Group delete liability',
    group_delete_reinvestment: 'Group delete reinvestment',
    group_delete_linked_liabilities_also: 'Group delete linked liabilities also',
    delete_only_asset: 'Delete only Asset',
    delete_linked_liabilities_also: 'Delete linked liabilities also',
    delete: 'Delete',
    wanted_price_per_sqm: 'Wanted price per SQM',
    buying_price: 'Buying Price',
    options_strike_price: 'Options strike price',
    current_stock_price: 'Current stock price (Publicly traded companies)',
    selling_price: 'Selling price',
    price_per_square_meter: 'Price per square meter',
    target_price: 'Target Price',
    stock_value_at_options_receival_date: 'Stock value at options receival date (If publicly traded company then 0)',
    do_we_need_to_pay_material_cost_index_paper_calculator: 'Do we need to pay material cost index?',
    asked_price: 'Asked Price',
    inviting_user: 'Inviting user',
    cash: 'Cash',
    adding_strategy: 'Adding strategy',
    deleting_file: 'Deleting file',
    deleting_variable: 'Deleting Variable',
    deleting_admin: 'Deleting admin',
    deleting_taxbrackets: 'Deleting Bracket',
    deleting_customer: 'Deleting customer',
    deleting_preset: 'Deleting Preset',
    deleting_strategy: 'Deleting strategy',
    new_tax_bracket: 'New Bracket',
    selling_tax_brackets_only_apartment: 'Selling Tax Brackets [Only Apartment]',
    selling_tax_brackets: 'Selling Tax Brackets',
    buying_tax_brackets_only_apartment: 'Buying Tax Brackets [Only Apartment]',
    buying_tax_brackets: 'Buying Tax Brackets',
    taxbrackets: 'Tax Brackets',
    yearly_materials_cost_index_rise_paper_calculator: 'Yearly materials cost index rise',
    yearly_materials_cost_index_rise_comp: 'Yearly materials cost index rise',
    additional_costs_for_making_the_apartment_ready_for_rent:
      'Additional costs for making the apartment ready for rent',
    stress_test: 'Stress Test',
    resetting_comp: 'Resetting comp',
    balance_sheet: 'Balance Sheet',
    from: 'From',
    took_from_asset: 'Took <b>{amount}</b> from asset <b>{assetName}</b>',
    took_from_linked_bank_account: 'Took <b>{amount}</b> from linked bank account <b>{accountName}</b>',
    customers: 'Customers',
    clone_customer: 'Clone Customer',
    new_customer: 'New Customer',
    asset_has_linked_loans:
      'The asset you are trying to delete has {n} loans linked to it. Do you also want to delete the loans?',
    asset_has_negative_value: 'Asset <b>{assetName}</b> has negative value on <b>{date}</b>',
    asset_has_no_money: 'Asset <b>{assetName}</b> has no value',
    meeting_video_link: 'Meeting Video Link',
    real_estate_guidance: 'Real estate guidance',
    press_alt_to_move: 'Press alt on Windows or option on Mac to move the chart around',
    logs: 'Logs',
    no_stocks_with_linked_liabilities: 'No stocks with linked liabilities found!',
    could_not_use_asset_loan_is_greater:
      'Could not use this asset, Loan is greater than liquidated value of this asset. Liquidated Value: <b>{liquidationAmount}</b>, LOAN Value: <b>{loanAmount}</b>',
    no_strategies_created: 'No strategies created',
    no_notes_added: 'No notes added',
    no: 'No',
    address: 'Address',
    cushions: 'Cushions',
    available_level1_funds_before_crash: 'Available Level1 Funds (Before Crash)',
    available_level1_funds_after_crash: 'Available Level1 Funds (After Crash)',
    yes: 'Yes',
    stock_amount: 'Stock amount',
    options_to_cash_after_two_years: 'Options to cash (after two years of holding)',
    options_to_cash_pre_two_years: 'Options to cash (Pre two years of holding)',
    setup_mode: 'Setup Mode',
    already_have_an_account: 'Already have an account? Click here to login',
    remaining_payment: 'Remaining Payment',
    mortgage_left_at_the_end: 'Mortgage left at the end of period of holding',
    one_final_payment: 'One Final Payment',
    not_enough_assets: 'Remaining amount is <b>{amount}</b>. Not enough assets to liquidate',
    some_investment_are_not_applied:
      'Some of the investments might not have been applied because this strategy has cyclic investments. Please correct them below',
    assets_decrease: 'Assets Decrease',
    value_lost_in_months: 'Value lost in 6 months',
    depreciations: 'Depreciations',
    maximal_drawdown: 'Maximal Drawdown',
    target: 'Target',
    creating_an_account: 'Creating an account for {email}',
    upload_files_to_this_apartment: 'Upload Files To This Apartment',
    loading_file: 'Loading file',
    fetching_customers: 'Fetching Customers',
    liquidation_table: 'Liquidation Table',
    opening_balance: 'Opening Balance',
    linked_bank_account: 'Linked Bank Account',
    primary_bank_account: 'Primary Bank Account',
    semi_yearly: 'Semi Yearly',
    asset_class_distribution: 'Asset Class Distribution',
    quick_search: 'Quick Search...',
    search: 'Search',
    back_to_site: 'Back to site',
    monthly: 'Monthly',
    buying_month: 'Buying Month',
    end_month: 'End Month',
    selling_month: 'Selling Month',
    start_month: 'Start Month',
    starting_month: 'Starting Month',
    month: 'Month',
    new: 'New',
    estimated_time_for_finishing_contruction: 'Estimated time for finishing construction from demolition',
    estimated_time_for_finishing_contruction_from_demolition_months:
      'Estimated time for finishing construction from demolition in months',
    estimated_time_for_contruction: 'Estimated time for finishing construction',
    estimated_time_for_contruction_months: 'Estimated time for finishing construction in months',
    estimated_time_for_demolition: 'Estimated time for demolition',
    estimated_time_for_demolition_month: 'Estimated time for demolition in months',
    mortgage_time_to_maturity: 'Mortgage time to maturity',
    time_to_maturity: 'Time To Maturity (Months)',
    time_to_delivery_after_buying: 'Time to delivery after buying (months)',
    holding_period_in_years: 'Holding period in years',
    this_will_also_delete_related_investments: 'This will also delete their related investments (if any).',
    app_videos: 'App Videos',
    video_not_found_please_contact_admin: 'Video not found, Please contact the admin of the site',
    logout: 'Logout',
    start: 'Start',
    liability_ends_with: 'Liability Ends With',
    duplicate_linked_liabilities: 'This asset also has liabilities linked to it. Do you also want to duplicate them?',
    liabilities: 'Liabilities',
    sign_in_to_continue: 'Sign in to continue',
    login: 'Login',
    reinvestments: 'Reinvestments',
    cashflow_impacts: 'Cashflow Impacts',
    compare_strategies: 'Compare Strategies',
    strategies_comparison: 'Strategies Comparison',
    leave_empty_to_view_results: 'Leave empty to view recent results...',
    show_stress_test_to_client: 'Show Stress Test to Client?',
    show_all_strategies_from_customer: 'Show all strategies to customer',
    show_archived_to_client: 'Show Archived to Client?',
    show: 'Show',
    meeting_video: 'Meeting Video',
    still_insufficient_amount_for_buying: 'Materials cost indexing to be paid at construction end',
    show_explainatory_video: 'Show Explainatory Video',
    show_archived: 'Show Archived',
    difference_between_value_and_price: 'Difference between appraised value and asked price',
    apply_stress_test: 'Apply Stress Test',
    additional_comments: 'Additional comments',
    notes: 'Notes',
    upload: 'Upload',
    upload_files: 'Upload Files',
    reinvestment_and_gradual_selling: 'Reinvestment and Gradual Selling ',
    transactions: 'Transactions',
    archive: 'Archive',
    hide_zero_rows: 'Hide rows with 0 cashflow',
    hide_explainatory_video: 'Hide Explainatory Video',
    hide_all_strategies_from_customer: 'Hide all strategies from customer',
    hide: 'Hide',
    negative_amount_is: 'Negative amount is <b>{amount}</b>',
    remaining_amount_trying_asset: 'Remaining amount is <b>{amount}</b>. Trying asset <b>{assetName}</b>',
    required_amount_for_margin_is: 'Required amount for margin requirements is <b>{amount}</b>',
    asset_liquidated_from_to: 'Asset liquidated from <b>{date1}</b> to <b>{date2}</b>',
    asset_linked_asset_of_loan:
      'Current asset is linked asset of the loan. Needs to be liquidated. Liquidated Value: <b>{liquidatedValue}</b>. Remaining Loan Amount: <b>{loanValue}</b>',
    comp_table: 'Comp Table',
    user_has_not_signed_up_yet: 'User has not signed up yet',
    continue: 'Continue',

    convert_from_another_currency: 'Convert from another currency',
    convert: 'Convert',
    customer_shared: 'This customer has been shared with the following users.',
    customer_not_shared: 'This customer has not been shared with other users yet!',
    margin_loan_against: 'Margin Loan Against',
    fixed_income: 'Fixed Income',
    loan_to_asset: 'Loan To Asset',
    loan_against: 'Loan Against',
    loan: 'LOAN',
    total_income_from_rent_after_vacancy_and_tax: 'Total income from rent (after vacancy and tax)',
    total_income_from_rent: 'Total income from rent',
    income: 'Income',
    enter_otp_here: 'Enter OTP Here',
    all: 'All',
    holding_postponing_tax: 'Holding and postponing the tax',
    register: 'Register',
    subtract: 'Subtract',
    transfer_applied_to: 'Transfer Applied To',
    apply: 'Apply',
    reapply: 'Reapply',
    total_monthly_payments: 'Total monthly mortgage payments',
    monthly_mortgage_payment: 'Maximal Monthly mortgage payment',
    invite_user: 'Invite User',
    the_weakest_month_and_year: 'The weakest month and year of this strategy is',
    invite: 'Invite',
    cleared_linked_loan: 'Cleared linked loan of value: <b>{clearedLoan}</b>.',
    cleared_linked_loan_remaining_cash:
      'Cleared linked loan of value: <b>{clearedLoan}</b>. Remaining cash: <b>{remainingCash}</b>',
    download: 'Download',
    expenditure: 'Expenditure',
    add_cashflow: 'Add Cashflow',
    update_cashflow: 'Update Cashflow',
    add_new_change: 'Add new change',
    add_new_cashflow_change: 'Add Cashflow Change',
    group_add: 'Group Add',
    add_value: 'Add value',
    add_new_asset: 'Add New Asset',
    add_asset: 'Add Asset',
    add_apartment: 'Add Appartment',
    add_new_variable: 'Add New Variable',
    add_new_admin: 'Add New Admin',
    add_new_taxbrackets: 'Add New Tax Bracket',
    add_new_customer: 'Add New Customer',
    add_new: 'Add New',
    add_liability: 'Add Liability',
    add_reinvestment: 'Add Reinvestment',
    add_new_preset: 'Add New Preset',
    add: 'Add',
    equity_received: 'Equity Received',
    total_equity_for_buying: 'Total equity for buying',
    equity_for_taking_mortgage: 'Equity for taking mortgage',
    equity_to_assets: 'Equity To Assets',
    total_equity_to_put_in_the_deal: 'Total equity to put in the deal',
    equity: 'Equity',
    equity_for: 'Equity for',
    required_equity_for_excercising: 'Required equity for excercising (strike)',
    app_configuration: 'App Configuration',
    preset: 'Preset',
    strategies_archived: 'The following strategies were archived!',
    is_rent: 'Is Rent?',
    is_there_an_balcony: 'Is there a balcony?',
    is_there_an_elevator: 'Is there an elevator?',
    is_there_a_safe_room: 'Is there a safe room?',
    is_there_parking: 'Is there parking? Is it legaly registered?',
    is_there_garden: 'Is there a garden?',
    is_only_apartment: 'Is Only apartment',
    is_it_the_only_apartment_buying: 'Is it the only apartment when buying',
    is_it_the_only_apartment_selling: 'Is it the only apartment when selling',
    is_apartment_properly_registered: 'Is the apartment properly registered?',
    is_there_construction_without_permit: 'is there any construction without permit?',
    is_apartment_registered_on_seller_name: "Is the apartment registered on the seller's name?",
    is_apartment_abroad: 'Is Apartment Abroad?',
    are_you_sure_you_want_to_archive_strategy: 'Are you sure you want to archive this strategy ({strategy})?',
    are_you_sure_you_want_to_unarchive_strategy: 'Are you sure you want to unarchive this strategy ({strategy})?',
    are_you_sure_you_want_to_unselect_strategy: 'Are you sure you want to unselect this strategy ({strategy})?',
    are_you_sure_you_want_to_select_strategy: 'Are you sure you want to select this strategy ({strategy})?',
    liquidation_score: 'Liquidation Score',
    rate_from_1_to_10: 'Rate from 1 to 10 (1- Terrible 10- Excellent)',
    how_easy_to_find_renters: 'How easy is it to find renters for the apartment?',
    how_well_maintained: 'How well maintained is the apartment?',
    how_good_is_location: 'How good is the location?',
    how_good_are_current_renters: 'How good are the current renters?',
    linked_apartment: 'Linked Apartment',
    apartment: 'Apartment',
    tenant_stops_paying: 'Tenant stops paying',
    charts: 'Charts',
    interest_only: 'Interest only',
    cashflow_appreciation: 'Cashflow Appreciation',
    loan_provider: 'Loan Provider',
    apartment_size: "apartment's size",
    apartment_size_after_reconstruction: 'Apartment size after reconstruction',
    loan_value: 'Loan Value',
    new_presets: 'New Presets',
    presets: 'Presets',
    highest_earner: 'Highest Earner',
    balance_with: 'Balance With',
    weakest: 'Weakest',
    yearly_materials_cost_index_rise: 'Yearly materials cost index rise',
    balloon: 'Balloon',
    home: 'Home',
    unarchiving_strategy: 'Unarchiving strategy',
    unselecting_strategy: 'Unselecting strategy',
    disable_reinvestment: 'Disable all reinvestments',
    unselect: 'Unselect',
    are_you_sure_you_want_to_delete_taxbrackets:
      'Are you sure you want to delete this Bracket? This action cannot be undone',
    linked_bank_account_has_no_money: 'Linked bank account <b>{accountName}</b> has no money',
    select_unselect_all: 'Select/Unselect All',
    select: 'Select',
    selecting_strategy: 'Selecting strategy',
    please_provide_a_primary_bank_account: 'Please provide a primary bank account for this strategy',
    are_you_sure_you_want_to_unarchive_customer:
      'Are you sure you want to unarchive the customer ({userName}) ? This action cannot be undone',
    are_you_sure_you_want_to_delete_cashflow:
      'Are you sure you want to delete this Cashflow? This action cannot be undone',
    are_you_sure_you_want_to_delete_asset: 'Are you sure you want to delete this Asset? This action cannot be undone',
    are_you_sure_you_want_to_delete_liability:
      'Are you sure you want to delete this Liability? This action cannot be undone',
    are_you_sure_you_want_to_delete_investment:
      'Are you sure you want to delete this investment option? This action cannot be undone',
    are_you_sure_you_want_to_delete_one_off_event:
      'Are you sure you want to delete this One Off Event? This action cannot be undone',
    are_you_sure_you_want_to_archive_customer:
      'Are you sure you want to archive the customer ({userName}) ? This action cannot be undone',
    are_you_sure_you_want_to_reapply:
      'Are you sure to reapply the comp table to this strategy? We recommend you make a clone of the original strategy first',
    are_you_sure_you_want_to_delete_admin:
      'Are you sure you want to delete this Admin ({admin})? This action cannot be undone',
    are_you_sure_you_want_to_delete_customer:
      'Are you sure you want to delete the customer ({userName}) ? This action cannot be undone',
    are_you_sure_delete_file:
      'Are you sure to delete this file ({fileName}). You will lose access to this file permanently?',
    are_you_sure_you_want_to_delete_variable:
      'Are you sure you want to delete this Variable? This action cannot be undone',
    are_you_sure_you_want_to_delete_preset: 'Are you sure you want to delete this Preset? This action cannot be undone',
    are_you_sure_you_want_to_delete_strategy:
      'Are you sure you want to delete this Strategy ({strategy})? This action cannot be undone',
    are_you_sure_reset_comp_table:
      'Are you sure you want to reset the Comp Table to default? This action cannot be undone!',
    confirm_password: 'Confirm Password',
    strategy_archive: 'Strategy ARCHIVE',
    archived: 'Archived',
    reset_to_default: 'Reset To Default',
    strategies: 'Strategies',
    new_strategy: 'New Strategy',
    strategy: 'Strategy',
    anona: 'Anona',
    please_provide_a_primary_bank_account_for_this_strategy: 'Please provide a primary bank account for this strategy',
    please_provide_your_email_address: 'Please provide your email address to continue',
    alphabetically: 'Alphabetically',
    real_estate_full_service: 'Real estate full service',
    reset_zoom: 'Reset Zoom',
    no_name: 'No name',
    no_files_to_show: 'No files to show!',
    no_asset: 'No Asset',
    margin_not_met: 'Margin for {stockType} loan <b>{loanName}</b> has not been met on <b>{date}</b>',
    nothing_to_show: 'Nothing to show!',
    dont_have_an_account: "Don't have an account? Click here to register",
    no_incomes: 'No Incomes',
    no_expenditure: 'No Expenditures',
    no_email: 'No email',
    recipient_emails: 'Recipient Emails',
    secondary_email: 'Secondary Email',
    email: 'Email',
    other: 'Other',
    mortgage_percentage: 'Mortgage percentage',
    percentage: 'Percentage',
    problems_detected: 'Problems Detected',
    appraised_value: 'Appraised Value',
    crash_percentage: 'Crash Percentage',
    loses_place_of_work: 'Loses a place of work',
    showing_n_items: '{n} items',
    please_wait_n_secs: 'Please wait {timer} seconds before requesting a new OTP...',
    did_not_receive_send_otp_again: "Didn't receive OTP? Click here to send again...",
    logging_in: 'Logging In',
    checking_email: 'Checking email',
    creating_account: 'Creating account',
    please_wait: 'Please wait...',
    value_in_currency: 'Value in {currency}',
    welcome_to_ETICA_crm: 'Hello! Welcome to Ethica CRM',
    contacts: 'Contacts',
    how_to_use_app: 'How to use App?',
    comments: 'Comments',
    contracts: 'Contracts',
    inbounds: 'Inbounds',
    emails: 'Emails',
    phone_calls: 'Phone Calls',
    no_comments_to_show: 'No comments to show!',
    no_contracts_to_show: 'No contracts to show!',
    type_your_comment_here: 'Type your comment here...',
    reset: 'Reset',
    are_you_sure_delete_comment: 'Are you sure you want to delete this comment? This action cannot be undone',
    admin: 'Admin',
    products: 'Products',
    add_new_product: 'Add New Product',
    update_product: 'Update Product',
    price: 'Price',
    template_link: 'Template Link on Prospero',
    are_you_sure_you_want_to_delete_products:
      'Are you sure you want to delete the product ({productName}) ? This action cannot be undone',
    send_new_contract: 'Send New Contract',
    edit_and_send_contract: 'Edit & Send',
    send: 'Send',
    quote: 'Quote',
    anona_monthly_payment: 'Anona Monthly Payment',
    copy_apartment: 'Copy Apartment',
    inviting_user: 'Inviting User',
    copy: 'Copy',
    select_customer_to_copy_apartment: 'Select Customer to copy this apartment to',
    select_customer: 'Select Customer',
    view_as_client: 'View as Client',
    'interested in my course': 'Interested In My Course',
    please_fill_the_form_to_continue: 'Please fill the form to continue',
    creating_user: 'Creating User',
    first_name: 'First Name',
    last_name: 'Last Name',
    user_id_get_started: 'User ID (automatically filled, will be used to sign in again)',
    password_get_started: 'Choose password',
    user_id_with_info: 'User Id',
    undo: 'Undo',
    redo: 'Redo',
    are_you_sure_archive: 'Are you sure you want to archive?',
    whatsapp: 'WhatsApp',
    example_user: 'Example User',
    example_open: 'Example',
    customer_not_found: 'Customer Not Found',
    info_about_the_neighborhood: 'Info about the neighborhood',
    transportation: 'Transportation',
    parking: 'Parking',
    physical_condition: 'Physical Condition',
    dangerous_building: 'Dangerous building?',
    number_of_air_directions: 'Number of air directions',
    developer_company: 'Developer Company',
    developer_website: 'Developer Website',
    signature_percentage: 'Signature percentage',
    show_liqudation_table_to_client: 'Show liquidation table to client?',
    show_extreme_case_to_client: 'Show Extreme case to client?',
    show_cashflow_impact_to_client: 'Show Cashflow impact to client?',
    show_cushion_to_client: 'Show Cushion to client?',
    import: 'Import',
    need_help_email_us: 'Need help? Email Us',
    update_strategy_time: 'Update Strategy Time',
    updating_strategy_time: 'Updating strategy time',
    email_get_started: 'Email (Will be used to sign in again)',
    please_reset_your_password: 'Please create a password',
    hello: 'Hello!',
    submit: 'Submit',
    Send_Password_Reset_Link: 'Send Password Reset Link',
    sending: 'Sending...',
    salesperson: 'Sales person',
    financial_strategist: 'Financial Strategist',
    real_estate_analyst: 'Real Estate Analyst',
    crm_details: 'CRM Details',
    this_customer_is_linked: 'This Customer is linked with the following CRM contact',
    matching_contact_found:
      'A matching contact has been found in CRM. The customer will be linked to the following contact.',
    rent_increase: 'Rent Increase Yearly',
    break_down: 'Breakdown',
    tax_on_cashflow: 'Tax on Cashflow',
    phone: 'Phone',
    estimated_time_for_finishing_contruction_from_demolition:
      'Estimated time for finishing construction from demolition',
    deposits: 'Deposits',
    partial_sales: 'Partial Sales',
    general: 'General',
    apartments: 'Apartments',
    drive: 'Drive',
    close_drive: 'Close drive folder',
    open_drive: 'Open drive folder',
    click_to_create_folder: 'Click here to create a folder',
    creating_drive_folder: 'Creating drive folder...',
    are_you_sure_to_delete_this_file: 'Are you sure you want to delete this file?',
    profile: 'Profiles',
    are_you_sure_you_want_to_delete_apartment_profile: 'Are you sure you want to delete this apartment profile?',
    grant_month: 'Grant Month',
    grant_year: 'Grant Year',
    vesting_month: 'Vesting Month',
    vesting_year: 'Vesting Year',
    stock_value: 'Stock Value',
    initial_stock_value: 'Initial Stock Value',
    marginal_tax: 'Marginal Tax',
    apartment_budget: 'Apartment budget',
    time_for_finishing_construction: 'Time for finishing construction',
    holding_time: 'Holding time (including construction period)',
    willingness_for_developer_loan: 'Willingness for developer loan',
    willingness_for_linear_payment: 'Willingness for linear payment',
    developer_has_to_be_a_big_company: 'Developer has to be a big company',
    buying_decision_has_to_be_done_fast: 'Buying decision has to be done fast',
    How_much_cash_available_for_deal: 'How much cash available for deal?',
    willingness_for_early_stage_deals: 'Willingness for early stage deals',
    importance_of_apartment_looks: 'Importance of apartment looks',
    willingness_to_buy_apartment_with_large_scale_renovation:
      'Willingness to buy apartment with large scale renovation',
    willingness_to_buy_floor_3_or_higher_without_elevator: 'Willingness to buy floor 3 or higher without elevator',
    willingness_to_buy_complicated_deals: 'Willingness to buy complicated deals',
    willingess_to_buy_small_apartment: 'Willingess to buy small apartment (50 sqm or less)',
    apartment_looks: 'Apartment looks',

    // saasdasd
    holding_period: 'Holding period',
    required_cash_for_deal: 'Required cash for deal',
    developer_loan: 'Developer loan',
    linear_payment: 'Linear payment',
    developer_company_scale: 'Developer company scale',
    buying_decision_scale: 'Buying decision scale',
    large_scale_renovation: 'Large scale renovation',
    early_stage_deals: 'Early stage deal',
    has_3_floor_or_higher_without_elevator: 'Has 3 or higher floor without elevator',
    complicated_deals: 'Complicated deal',
    months: 'Months',
    years: 'Years',
    profile_fields: 'Profile fields',
    transfer_from: 'Transfer from',
    set_ceiling_on: 'Set ceiling on',
    set_floor_on: 'Set floor on',
    transfer_to: 'Transfer to',
    continue_after_sell: 'Continue After Sell?',
    after_tax: 'After Tax?',
    apply_inflation: 'Apply Inflation'
  },
  Hebrew: {
    transfer_from: 'העבר מ',
    set_ceiling_on: 'קבע תקרה על',
    set_floor_on: 'קבע רצפה ל',
    transfer_to: 'העבר ל',

    months: 'חודשים',
    years: 'שנים',
    apartment_budget: 'תקציב דירה',
    time_for_finishing_construction: 'זמן למסירה',
    holding_time: 'זמן החזקה (כולל תקופת בנייה) ',
    willingness_for_developer_loan: 'יש ללקוח נכונות לקבל הלוואת קבלן?',
    willingness_for_linear_payment: 'נכונות לתשלום לינארי?',
    developer_has_to_be_a_big_company: 'עד כמה חשוב לך שהיזם יהיה יזם גדול ומוכר',
    buying_decision_has_to_be_done_fast: 'צריך לקבל החלטה ולהגיש בקשת רכישה מהר מאוד)',
    How_much_cash_available_for_deal: 'כמה מזומן ניתן להכניס לעסקה?',
    willingness_for_early_stage_deals:
      'נכונות לעסקאות שנמצאות בשלבים ראשוניים של התחדשות עירונית (צפי ליותר מ- 7 שנים להריסה) ',

    importance_of_apartment_looks: 'עד כמה חשובה לך האסטתיקה של הדירה? ',
    willingness_to_buy_apartment_with_large_scale_renovation: 'עד כמה אתה מוכן לקנות דירה שדורשת שיפוץ משמעותי?',
    willingness_to_buy_floor_3_or_higher_without_elevator:
      'האם אתה מוכן לקנות דירה שנמצאת בקומות הגבוהות של הבניין (+3) ללא מעלית',
    willingness_to_buy_complicated_deals: 'האם אתה מוכן לקנות עסקאות מסובכות (מבנים מסוכנים, מושע)',
    willingess_to_buy_small_apartment: 'האם אתה מוכן לקנות דירה קטנה? (מדובר בדירה שהיא פחות מ- 50 מ"ר)',
    rent_increase: 'עליית שכירות שנתית',
    estimated_time_for_finishing_contruction_from_demolition: 'זמן בנייה משוער',
    please_fill_the_form_to_continue: 'אנא מלא את הטופס כדי להמשיך',
    first_name: 'שם פרטי',
    last_name: 'שם משפחה',
    payments: 'תשלומים',
    down_payment: 'תשלום ראשון (Downpayment)',
    paying_tax_investing_somewhere: 'תשלום מס והשקעה חלופית',
    paying_tax_at_period_end: 'תשלום מס בתום התקופה',
    tax: 'תשלום מס',
    pay_installment_cost: 'תשלום לקבלן',
    pay_indexing_cost: 'תשלום הצמדה למדד',
    gradual_payment: 'תשלום הדרגתי',
    pay: 'תשלום',
    target_irr: 'תשואת יעד',
    real_estate_irr: 'תשואה שנתית על הון עצמי',
    irr: 'תשואה שנתית על הון עצמי',
    irr_no_reconstruction: 'תשואה שנתית במקרה בו לא תהיה התחדשות',
    net_yearly_return_on_rent: 'תשואה פירותית נטו',
    yearly_return_from_rent: 'תשואה פירותית ברוטו',
    return_cashflow: 'תשואה פירותית',
    total_roi: 'תשואה כוללת על הון עצמי',
    return_appreciation: 'תשואה הונית',
    bad: 'תרחיש שלילי',
    current_scenario: 'תרחיש נוכחי',
    good: 'תרחיש חיובי',
    original: 'תרחיש בסיס',
    armageddon: 'תרחיש ארמגדון',
    armageddon_scenario: 'תרחיש ארמגדון',
    margin_ceiling: 'תקרת מרג’ין',
    real_estate_holding_period: 'תקופת החזקה צפוייה',
    grace_period: 'תקופת גרייס (בחודשים)',
    configure: 'תצוגה',
    role: 'תפקיד',
    description: 'תיאור',
    expected_cashflow: 'תזרים צפוי',
    cashflow: 'תזרים מזומנים ',
    multi_generational: 'תוכנית בין דורית',
    strategic_planning: 'תוכנית אסטרטגית',
    frequency: 'תדירות',
    end_date_calculated_automatically: 'תאריך סיום מחושב אוטומטית?',
    end_date: 'תאריך סיום',
    added_on: 'תאריך יצירה',
    start_date: 'תאריך התחלה',
    share_with_new_user: 'שתף עם משתמש חדש',
    show_general_info_to_client: 'שתף מידע כללי עם הלקוח?',
    share_strategies: 'שתף אסטרטגיות',
    share: 'שתף',
    annuity: 'שפיצר',
    yearly: 'שנתי',
    buying_year: 'שנת קנייה',
    end_year: 'שנת סיום',
    selling_year: 'שנת מכירה',
    start_year: 'שנת התחלה',
    configure_armageddon: 'שנה הנחות יסוד ארמגדון',
    change: 'שנה',
    year: 'שנה',
    kept_remaining_cash: 'שמרנו יתרת מזומנים <b>{amount}</b>',
    saving_cashflow_changes: 'שמירת שינויים בתזרים',
    maintain_margin: "שמירת מרג'ין",
    save: 'שמור',
    secondary_name: 'שם נוסף',
    realtor_seller_name: 'שם המתוווך\\ בעל הדירה',
    name: 'שם',
    sent_remaining_cash: 'שלחנו יתרת מזומנים <b>{amount}</b> לחשבון בנק <b>{accountName}</b>',
    welcome_to_ETICA_financials: 'שלום וברוכים הבאים לאתיקה פיננסים!',
    rent: 'שכר דירה',
    clone_strategy: 'שכפל אסטרטגיה {strategy}',
    clone: 'שכפל',
    duplicate: 'שכפל',
    rent_pre_contruction: 'שכירות לפני פינוי',
    rent_post_contruction: 'שכירות לאחר טופס 4',
    total_income_from_rent_minus_monthly_payment: 'שכירות כוללת בקיזוז החזרי משכנתה כוללים',
    total_rent: 'שכירות כוללת',
    return_cashflow_monthly: 'שכירות חודשית צפוייה',
    monthly_rent: 'שכירות חודשית',
    rent_during_contruction: 'שכירות בזמן פינוי (ברירת מחדל: כמו בשגרה)',
    forgot_password: 'שכחת סיסמא?',
    marginal_rate_of_income: 'שיעור מס שולי',
    capital_appreciation_tax: 'שיעור מס רווחי הון',
    one_off_change: 'שינויים חד פעמיים',
    interest_rate_changes: 'שינויים בריבית',
    one_off_change_by: 'שינוי',
    heads_up: 'שים לב!',
    update_start_date_note_for_strategies: 'שים לב, החודש והשנה של כל אסטרטגיה אצל הלקוח יעודכנו',
    area_in_square_meters: 'שטח במ"ר',
    unarchive: 'שחזר',
    saving_variable: 'שומר משתנים',
    saving_admin: 'שומר מנהל',
    saving_taxbrackets: 'שומר מדרגת מס',
    saving_customer: 'שומר לקוח',
    saving_preset: 'שומר הגדות יסוד',
    archiving_strategy: 'שומר אסטרטגיה בארכיון',
    future_value_pre_tax: 'שווי עתידי לפני מס',
    net_value: 'שווי נטו',
    total_value: 'שווי כולל',
    value_of_stocks_with_marginal_tax_rate: 'שווי המניות החייבות במס שולי',
    value_of_stocks_with_capital_aprreciation_tax_rate: 'שווי המניות החייבות במס רווחי הון',
    value_of_apartment_today: 'שווי דירת תמורה כיום',
    value_after_renovation: 'שווי אחרי שיפוץ',
    appreciation: 'שבח',
    margin_floor: 'רצפת מרג’ין',
    level: 'רמת הרשאה',
    buying: 'רכישה',
    mortgage_interest: 'ריבית משכנתה',
    interest: 'ריבית ',
    loan_interest: 'ריבית ',
    profit_from_selling_brfore_tax: 'רווח ממכירה (לפני מס)',
    profit_from_selling_after_tax: 'רווח ממכירה (אחרי מס)',
    total_net_profit: 'רווח כולל נטו',
    profit_at_excersising_pre_tax: 'רווח במימוש לפני מס',
    profit: 'רווח',
    pensioni: 'רגיל',
    quaterly: 'רבעוני',
    asset_name: 'קשר נכס',
    link_a_new_liability: 'קשר התחייבות חדשה',
    stock_KSH: 'קרן השתלמות',
    video_link: 'קישורי וידאו',
    total_loan_decrease: 'קיטון בחוב לאורך התקופה',
    asset_category: 'קטגוריה',
    category: 'קטגוריה',
    apartment_floor_number: 'קומת הדירה',
    files_from_drive: 'קבצים בתיקייה',
    other_files: 'קבצים אחרים',
    set_a_ceiling_value: 'קבע תקרה',
    set_a_floor_value: 'קבע רצפה',
    set: 'קבע',
    get_reset_email: 'קבל מייל לשחזור סיסמא',
    fixed: 'קבועה',
    view_files: 'צפה בקבצים',
    view_profile: 'צפה בפרופיל',
    yearly_growth_rate: 'צמיחה שנתית (%)',
    balance_sheet_growth_with_time: 'צמיחה במאזן לאורך זמן',
    add_new_strategy: 'צור אסטרטגיה חדשה',
    viewing_user: 'צופה במשתמש',
    open_drive_folder: 'פתח תיקיה בדרייב',
    profile_details: 'פרטי משתמש',
    difference: 'פער',
    active: 'פעיל',
    actions: 'פעולות',
    edit_mode: 'פעולה חדשה',
    action: 'פעולה',
    filters: 'פילטרים',
    stock_portfolio: 'פורטפוליו מניות',
    end_value: 'ערך סיום',
    monthly_value: 'ערך חודשי',
    initial_value: 'ערך התחלתי',
    value: 'ערך',
    edit: 'ערוך',
    yearly_appreciation: 'עליית ערך שנתית צפוייה',
    annual_appreciation: 'עליית ערך שנתית',
    expected_value_appreciation_from_change: 'עליית ערך צפויה בעקבות תוספות ושדרוגים (לא כולל עלות)',
    expected_appreciation: 'עליית ערך צפויה',
    value_added_by_renovation: 'עליית ערך בעקבות שיפוץ',
    do_we_need_to_pay_material_cost_index:
      'עליית ערך אזורית לא רלוונטית בצורה שונה מהותית מעליית הערך של הדירה הספציפית.',
    renovation_cost: 'עלות שיפוץ',
    total_deal_cost: 'עלות עסקה כוללת',
    cost_of_retailer_lawyer: 'עלות מתווך, עו"ד, מס רכישה וכל העלויות הנלוות',
    realtor_cost_in_percentage_terms_pre_vat: 'עלות מתווך (באחוזים לפי מע"מ)',
    cost_of_time_to_delivery_after_buying: 'עלות זמן המכירה לאחר רכישה',
    cost_of_vacancy_and_annual_repairs: 'עלות vacancy ותיקונים שנתיים',
    realtor_cost: 'עלויות תיווך',
    purchase_cost: 'עלויות נלוות לרכישה',
    additional_buy_cost: 'עלויות נלוות לרכישה',
    sales_cost: 'עלויות נלוות למכירה',
    additional_sell_cost: 'עלויות נלוות למכירה',
    related_costs: 'עלויות נלוות (עו"ד, שמאי וכו\')',
    additional_cost: 'עלויות נלוות',
    additional_purchase_cost_for: 'עלויות נלוות',
    additional_costs_for_making_the_apartment_ready_for_rent_paper_apartment: 'עלויות להכנת הדירה להשכרה',
    city: 'עיר',
    drive_folder_not_created: 'עוד לא נוצרה תיקייה בדרייב. לחץ כדי ליצור',
    initial_account_balance: 'עו"ש התחלתי',
    update_reinvestment: 'עדכן תקרות ורצפות',
    update_strategy_name: 'עדכן שם אסטרטרגיה',
    update_cashflow_change: 'עדכן שינוי בתזרים',
    group_update: 'עדכן קבוצתית',
    update_variable: 'עדכן משתנה',
    update_admin: 'עדכן מנהל',
    update_taxbrackets: 'עדכן מדרגת מס',
    update_customer: 'עדכן לקוח',
    resort_to_default: 'עדכן לברירת מחדל',
    update_notes: 'עדכן הערות',
    reapply_comp: 'עדכן הנחות יסוד',
    update_preset: 'עדכן הגדרות יסוד',
    updating_preset: 'עדכן ברירות מחדל',
    update: 'עדכן',
    updating_armageddon_scenario: 'עדכון תרחיש ארמגדון',
    update_strategy_start_time: 'עדכון תאריך התחלת אסטרטגיה',
    updating_cashflow_changes: 'עדכון שינויים בתזרים ',
    to: 'עד',
    past: 'עבר',
    symbol: 'סמל',
    old_password: 'סיסמא ישנה',
    new_password: 'סיסמא חדשה',
    password: 'סיסמא',
    action_summary: 'סיכום פעולות',
    loan_summary: 'סיכום הלוואות',
    one_off_event_summary: 'סיכום אירועים חד פעמיים',
    end_with_asset: 'סיים עם נכס',
    end_with_maturity: 'סיים לפי מועד פירעון',
    end: 'סיום',
    stock_type: 'סוג תיק ני"ע',
    program_type: 'סוג תוכנית',
    type_of_interest: 'סוג ריבית',
    interest_type: 'סוג ריבית',
    transfer_type: 'סוג העברה',
    loan_type: 'סוג הלוואה',
    apartment_type: 'סוג דירה',
    type: 'סוג',
    total_assets: 'סה”כ נכסים',
    total_liabilities: 'סה”כ התחייבויות',
    total_income: 'סה”כ הכנסה',
    total_expenditure: 'סה”כ הוצאה',
    total: 'סה"כ',
    rearrange_strategies: 'סדר אסטרטגיות מחדש ',
    close: 'סגור',
    shown_to_customer: 'נראה ללקוח',
    ignored_assets: 'נכסים שלא נקנו',
    related_assets: 'נכסים קשורים',
    linked_asset: 'נכסים קשורים',
    assets: 'נכסים',
    asset_liquidated_on: 'נכס מונזל בתאריך <b>{date}</b>',
    asset_is_linked_ib_stock: "נכס זה קשור לחשבון מרג'ין. מנסה הנזלה חלקית",
    asset: 'נכס',
    can_sell_partially: 'ניתן למכור חלקית?',
    percentage_of_payment_delayed_to_delivery:
      'ניתן לחשוב על זה כעל 20% כברירת מחדל בתחילה והיתרה בקבלת טופס 4. ניתן להגדיר לשנה מסויימת לאורך הדרך תשלום מסויים וזה ירד מיתרת התשלום בטופס 4.',
    risk_management: 'ניהול סיכונים',
    manage_users: 'ניהול משתמשים',
    stocks: 'ני"ע',
    net_after_excersising_and_selling_stocks: 'נטו לאחר מימוש ומכירת מניות',
    net: 'נטו',
    real_estate: 'נדל”ן',
    selected: 'נבחר',
    advanced: 'מתקדם',
    new_variables: 'משתנים חדשים',
    variables: 'משתנים',
    user_already_invited: 'משתמש זה כבר הוזמן פעם אחת. המשך יאפס את פרטי המשתמש ויזמין אותו מחדש',
    mortgage_to_be_taken: 'משכנתה שתילקח',
    maximal_mortgage_available: 'משכנתה מקסימלית',
    mortgage: 'משכנתה',
    is_salary: 'משכורת',
    unarchiving_customer: 'משחזר לקוח',
    something_went_wrong_try_again: 'משהו השתבש, לחץ כאן כדי לנסות שוב',
    margin_call: "מרג'ין קול",
    showing: 'מציג',
    normal_state: 'מצב רגיל',
    detailed_mode: 'מצב פיתוח',
    extreme_case: 'מצב משבר פיננסי',
    depressed_state: 'מצב משבר פיננסי',
    mode: 'מצב',
    status: 'מצב',
    updating_strategy_name: 'מעדכן שם אסטרטגיה',
    updating_video_link: 'מעדכן קישורי וידאו',
    updating_variable: 'מעדכן משתנים',
    updating_admin: 'מעדכן מנהל',
    updating_taxbrackets: 'מעדכן מדרגת מס',
    updating_customer: 'מעדכן לקוח',
    updating_notes: 'מעדכן הערות',
    archiving_customer: 'מעביר לארכיון',
    vat: 'מע"מ',
    total_years: 'מספר שנים',
    number_floor_in_building: 'מספר קומות בבניין',
    user_id: 'מספר משתמש',
    secondary_phone_number: 'מספר טלפון נוסף',
    phone_number: 'מספר טלפון',
    months_to_sustain_negative: 'מספר חודשים בהם ניתן לשאת בתזרים שלילי מההון (אחרי משבר)',
    number_of_rooms: 'מספר חדרים',
    buying_tax: 'מס רכישה',
    buying_tax_for: 'מס רכישה',
    tax_on_excersising: 'מס על מימוש (מכירת מניות)',
    tax_on_rent: 'מס על השכירות',
    tax_calculated_automatically: 'מס מחושב אוטומטית?',
    total_tax: 'מס כולל',
    tax_amount: 'מס כולל',
    tax_and_additional_costs: 'מס ועלויות נוספות',
    selling_tax: 'מס במכירה',
    stock: 'מניה',
    admins: 'מנהלים',
    new_admin: 'מנהל חדש',
    sorting_by: 'ממיין לפי',
    partially_sold_from_asset: "מכרנו חלקית <b>{amount}</b> מתוך נכס <b>{assetName}</b> כדי לעמוד בדרישות המרג'ין",
    partial_selling_not_possible: 'מכירה חלקית לא אפשרית לנכס זה. מנסה מכירה מלאה',
    selling: 'מכירה',
    sell: 'מכירה',
    sort_by: 'מיין לפי',
    fetching_admins: 'מייבא מנהלים',
    apartment_info: 'מידע על הדירה',
    additional_information: 'מידע נוסף',
    general_information: 'מידע כללי',
    who_is_typical_renter_for_apartment: 'מי השוכר הקלאסי לנכס? סטודנטים וזוגות צעירים\\ משפחות ומבוגרים\\ תיירים',
    allowed_calculators: 'מחשבונים זמינים',
    calculators: 'מחשבונים',
    pinui_binui: 'מחשבון פינוי בינוי',
    irr_calculator: 'מחשבון מחיר יעד',
    target_price_calculator: 'מחשבון מחיר יעד',
    paper_apartment: 'מחשבון דירה על הנייר',
    rsu_options: 'מחשבון אופציות ו- RSU',
    options_calculator: 'מחשבון אופציות',
    rsu_calculator: 'מחשבון RSU',
    group_delete_cashflow: 'מחק קבוצתית תזרים מזומנים',
    group_delete_only_asset: 'מחק קבוצתית נכס בלבד',
    group_delete_liability: 'מחק קבוצתית התחייבות',
    group_delete_reinvestment: 'מחק קבוצתית השקעה מחדש, רצפות ותקרות',
    group_delete_linked_liabilities_also: 'מחק קבוצתית גם התחייבויות',
    delete_only_asset: 'מחק נכס בלבד',
    delete_linked_liabilities_also: 'מחק גם התחייבויות',
    delete: 'מחק',
    wanted_price_per_sqm: 'מחיר רצוי למטר רבוע',
    buying_price: 'מחיר רכישה',
    options_strike_price: 'מחיר סטרייק של האופציות',
    current_stock_price: 'מחיר מנייה נוכחי (חברות ציבוריות)',
    selling_price: 'מחיר מכירה',
    price_per_square_meter: 'מחיר למ"ר',
    target_price: 'מחיר יעד',
    stock_value_at_options_receival_date: 'מחיר המנייה במועד מתן האופציות (חברות פרטיות- 0)',
    do_we_need_to_pay_material_cost_index_paper_calculator: 'מחיר הדירה צמוד למדד?',
    asked_price: 'מחיר ביקוש',
    inviting_user: 'מזמין משתמש',
    cash: 'מזומן',
    adding_strategy: 'מוסיף אסטרטגיה',
    deleting_file: 'מוחק קובץ',
    deleting_variable: 'מוחק משתנה',
    deleting_admin: 'מוחק מנהל',
    deleting_taxbrackets: 'מוחק מדרגת מס',
    deleting_customer: 'מוחק לקוח',
    deleting_preset: 'מוחק ברירת מחדל',
    deleting_strategy: 'מוחק אסטרטגיה',
    new_tax_bracket: 'מדרגת מס חדשה',
    selling_tax_brackets_only_apartment: 'מדרגות מס שבח [דירה יחידה]',
    selling_tax_brackets: 'מדרגות מס שבח',
    buying_tax_brackets_only_apartment: 'מדרגות מס רכישה [דירה יחידה]',
    buying_tax_brackets: 'מדרגות מס רכישה',
    taxbrackets: 'מדרגות מס',
    yearly_materials_cost_index_rise_paper_calculator: 'מדד תשומות הבנייה שנתי',
    yearly_materials_cost_index_rise_comp: 'מדד תשומות הבנייה',
    additional_costs_for_making_the_apartment_ready_for_rent:
      'מדד התשומות יחושב רק על החלק שלא שולם עדיין ובאחוזים משווי הרכישה כלומר 40% מתוך החוב יוצמד והיתרה לא. כאשר חלק משולם הוא משולם בצורה שווה משני הרכיבים. אפשר להזין פשוט מדד נמוך יותר.',
    stress_test: 'מבחן לחץ',
    resetting_comp: 'מאפס טבלת הגדרות יסוד',
    balance_sheet: 'מאזן',
    from: 'מ',
    took_from_asset: 'לקחנו <b>{amount}</b> מחשבון בנק <b>{assetName}</b>',
    took_from_linked_bank_account: 'לקחנו <b>{amount}</b> מחשבון בנק <b>{accountName}</b>',
    customers: 'לקוחות',
    clone_customer: 'לקוח משוכפל',
    new_customer: 'לקוח חדש',
    asset_has_linked_loans: 'לנכס שאתה מנסה למחוק יש {n} התחייבויות קשורות. אתה רוצה למחוק גם את ההתחייבויות?',
    asset_has_negative_value: 'לנכס <b>{assetName}</b> יתרה שלילית בתאריך <b>{date}</b>',
    asset_has_no_money: 'לנכס <b>{assetName}</b> אין שווי',
    meeting_video_link: 'לינק הקלטת פגישה',
    real_estate_guidance: 'ליווי נדל”ן',
    press_alt_to_move: 'לחץ על ALT בווינדוס או על Option בMac כדי להזיז את התרשים',
    logs: 'לוגים',
    no_stocks_with_linked_liabilities: 'לא נמצאו ני"ע עם הלוואות כנגדם',
    could_not_use_asset_loan_is_greater:
      'לא ניתן להשתמש בנכס זה. יתרת הלוואה גדולה משווי נכס <b>{liquidationAmount}</b>, LOAN Value: <b>{loanAmount}</b>',
    no_strategies_created: 'לא נוצרו אסטרטגיות',
    no_notes_added: 'לא נוספו הערות',
    no: 'לא ',
    address: 'כתובת',
    cushions: 'כריות',
    available_level1_funds_before_crash: 'כספים נזילים מדרגה 1 (לפני משבר)',
    available_level1_funds_after_crash: 'כספים נזילים מדרגה 1 (אחרי משבר)',
    yes: 'כן',
    stock_amount: 'כמות מניות',
    options_to_cash_after_two_years: 'כדאיות מימוש אופציות למזומן (כאשר עברו שנתיים מקבלת האופציות)',
    options_to_cash_pre_two_years: 'כדאיות מימוש אופציות למזומן (כאשר *לא* עברו שנתיים מקבלת האופציות)',
    setup_mode: 'כבר קיים',
    already_have_an_account: 'כבר יש לך חשבון? לחץ כאן להתחבר',
    remaining_payment: 'יתרת תשלום',
    mortgage_left_at_the_end: 'יתרת משכנתה בתום התקופה',
    one_final_payment: 'יתרה בטופס 4',
    not_enough_assets: 'יתרה <b>{amount}</b> אין מספיק נכסים להנזיל',
    some_investment_are_not_applied: 'יתכן שחלק מההשקעות לא יושמו בגלל היגיון מעגלי. אנא בדוק סגירות',
    assets_decrease: 'ירידת ערך נכסים',
    value_lost_in_months: 'ירידת ערך ב6 חודשים',
    depreciations: 'ירידות ערך',
    maximal_drawdown: 'ירידה מקסימלית',
    target: 'יעד',
    creating_an_account: 'יוצר חשבון עבור {email}',
    upload_files_to_this_apartment: 'טען קבצים לדירה זו',
    loading_file: 'טוען קבצים',
    fetching_customers: 'טוען לקוחות',
    liquidation_table: 'טבלת הנזלה',
    opening_balance: 'חשבון פתיחה',
    linked_bank_account: 'חשבון משויך',
    primary_bank_account: 'חשבון בנק ראשי',
    semi_yearly: 'חצי- שנתי',
    asset_class_distribution: 'חלוקה בסוגי נכסים',
    quick_search: 'חיפוש מהיר',
    search: 'חיפוש',
    back_to_site: 'חזרה לאתר',
    monthly: 'חודשי',
    buying_month: 'חודש קנייה',
    end_month: 'חודש סיום',
    selling_month: 'חודש מכירה',
    start_month: 'חודש התחלה',
    starting_month: 'חודש התחלה',
    month: 'חודש',
    new: 'חדש',
    estimated_time_for_finishing_contruction: 'זמן משוער לסיום הבנייה ממועד ההריסה (בשנים)',
    estimated_time_for_contruction: 'זמן משוער לסיום בנייה',
    estimated_time_for_contruction_months: 'זמן משוער לסיום בנייה (בחודשים)',
    estimated_time_for_demolition: 'זמן משוער להריסת מבנה קיים',
    mortgage_time_to_maturity: 'זמן לפרעון',
    time_to_maturity: 'זמן לפרעון',
    time_to_delivery_after_buying: 'תקופת פינוי בחודשים (חודשים)',
    holding_period_in_years: 'זמן החזקה בשנים',
    this_will_also_delete_related_investments: 'זה ימחק גם את ההשקעות מחדש\\ רצפות\\ תקרות המקושרות',
    app_videos: 'וידאויים',
    video_not_found_please_contact_admin: 'וידאו לא נמצא',
    logout: 'התנתק',
    start: 'התחלה',
    liability_ends_with: 'התחייבות מסתיימת עם',
    duplicate_linked_liabilities: 'התחייבויות מקושרות לנכס הזה, האם תרצה לשכפל אותן?',
    liabilities: 'התחייבויות',
    sign_in_to_continue: 'התחבר כדי להמשיך',
    login: 'התחבר',
    reinvestments: 'השקעה מחדש',
    cashflow_impacts: 'השפעה על תזרים',
    compare_strategies: 'השוואת אסטרטגיות',
    strategies_comparison: 'השוואת אסטרטגיות',
    leave_empty_to_view_results: 'השאר ריק לראות תוצאות אחרונות',
    show_stress_test_to_client: 'הראה מבחן לחץ ללקוח',
    show_all_strategies_from_customer: 'הראה את כל האסטרטגיות ללקוח',
    show_archived_to_client: 'הראה ארכיון ללקוח',
    show: 'הראה',
    meeting_video: 'הקלטת פגישה',
    still_insufficient_amount_for_buying: 'הצמדת מדד לתשלום בסוף תקופת הבנייה',
    show_explainatory_video: 'הצג סרטון הסבר',
    show_archived: 'הצג ארכיון',
    difference_between_value_and_price: 'הפרש בין שווי שמאי ומחיר יעד',
    apply_stress_test: 'הפעל מבחן לחץ',
    additional_comments: 'הערות נוספות',
    notes: 'הערות',
    upload: 'העלה',
    upload_files: 'העלאת קבצים',
    reinvestment_and_gradual_selling: 'העברות, תקרות ורצפות',
    transactions: 'העברות',
    archive: 'העבר לארכיון',
    hide_zero_rows: 'הסתר שורות ללא תזרים מזומנים',
    hide_explainatory_video: 'הסתר סרטון הסבר',
    hide_all_strategies_from_customer: 'הסתר את כל האסטרטגיות מהלקוח',
    hide: 'הסתר',
    negative_amount_is: 'הסכום השלילי הוא <b>{amount}</b>',
    remaining_amount_trying_asset: 'הסכום הנותר הוא <b>{amount}</b> מנסה נכס <b>{assetName}</b>',
    required_amount_for_margin_is: "הסכום הנדרש לשמירה על דרישות מרג'ין הוא <b>{amount}</b>",
    asset_liquidated_from_to: 'הנכס מונזל מתאריך <b>{date1}</b> עד תאריך <b>{date2}</b>',
    asset_linked_asset_of_loan:
      'הנכס הנוכחי שקשור להלוואה צריך להיות מונזל. שווי מונזל <b>{liquidatedValue}</b> יתרת הלוואה <b>{loanValue}</b>',
    comp_table: 'הנחות יסוד',
    user_has_not_signed_up_yet: 'המשתמש עוד לא נרשם למערכת',
    continue: 'המשך',
    convert_from_another_currency: 'המר ממטבע אחר',
    convert: 'המר',
    customer_shared: 'הלקוח הזה שותף עם המשתמשים',
    customer_not_shared: 'הלקוח הזה לא שותף עם משתמשים נוספים',
    margin_loan_against: 'הלוואת מרג’ין כנגד',
    fixed_income: 'הלוואות חברתיות',
    loan_to_asset: 'הלוואה לנכס',
    loan_against: 'הלוואה כנגד',
    loan: 'הלוואה',
    total_income_from_rent_after_vacancy_and_tax: 'הכנסה כוללת משכירות (לאחר מיסים וvacancy)',
    total_income_from_rent: 'הכנסה כוללת משכירות',
    income: 'הכנסה',
    enter_otp_here: 'הכנס את הסיסמא החד פעמית שנשלחה לך למייל כאן',
    all: 'הכל',
    holding_postponing_tax: 'הישארות בחברה ודחיית מס',
    register: 'הירשם',
    subtract: 'החסר',
    transfer_applied_to: 'החל על\\ מקור העברה',
    apply: 'החל',
    reapply: 'החל',
    total_monthly_payments: 'החזר משכנתה כולל',
    monthly_mortgage_payment: 'החזר משכנתה חודשי',
    invite_user: 'הזמן משתמש',
    the_weakest_month_and_year: 'הזמן החלש ביותר הוא',
    invite: 'הזמן',
    cleared_linked_loan: 'הורדנו שווי <b>{clearedLoan}</b> מההלוואה הקשורה',
    cleared_linked_loan_remaining_cash:
      'הורדנו שווי  <b>{clearedLoan}</b> מההלוואה הקשורה. יתרת מזומנים <b>{remainingCash}</b>',
    download: 'הורד',
    expenditure: 'הוצאה',
    add_cashflow: 'הוסף תזרים מזומנים',
    update_cashflow: 'הוסף תזרים מזומנים',
    add_new_change: 'הוסף שינוי חדש',
    add_new_cashflow_change: 'הוסף שינוי בתזרים',
    group_add: 'הוסף קבוצתית',
    add_value: 'הוסף ערך',
    add_new_asset: 'הוסף נכס חדש',
    add_asset: 'הוסף נכס',
    add_apartment: 'הוסף נכס',
    add_new_variable: 'הוסף משתנה חדש',
    add_new_admin: 'הוסף מנהל חדש',
    add_new_taxbrackets: 'הוסף מדרגת מס חדשה',
    add_new_customer: 'הוסף לקוח חדש',
    add_new: 'הוסף חדש',
    add_liability: 'הוסף התחייבות',
    add_reinvestment: 'הוסף השקעה תקרות ורצפות',
    add_new_preset: 'הוסף ברירת מחדל חדשה',
    add: 'הוסף',
    equity_received: 'הון שהתקבל',
    total_equity_for_buying: 'הון עצמי לרכישה',
    equity_for_taking_mortgage: 'הון עצמי לעיניין לקיחת משכנתה',
    equity_to_assets: 'הון עצמי לנכסים',
    total_equity_to_put_in_the_deal: 'הון עצמי כולל לעסקה',
    equity: 'הון עצמי',
    equity_for: 'הון עצמי',
    required_equity_for_excercising: 'הון נדרש למימוש (סטרייק)',
    app_configuration: 'הגדרות',
    preset: 'סוג נכס',
    strategies_archived: 'האסטרטגיות הבאות נשמרו בארכיון!',
    is_rent: 'האם מדובר בשכירות?',
    is_there_an_balcony: 'האם יש מרפסת? פרט',
    is_there_an_elevator: 'האם יש מעלית? פרט',
    is_there_a_safe_room: 'האם יש ממ"ד? פרט',
    is_there_parking: 'האם יש חניה? פרט (בטאבו\\ בשימוש)',
    is_there_garden: 'האם יש גינה? פרט',
    is_only_apartment: 'האם זו דירה יחידה?',
    is_it_the_only_apartment_buying: 'האם זו דירה יחידה במעמד הרכישה?',
    is_it_the_only_apartment_selling: 'האם זו דירה יחידה במעמד המכירה?',
    is_apartment_properly_registered: 'האם הנכס רשום בטאבו? (אם לא פרט)',
    is_there_construction_without_permit: 'האם הכל נבנה בהיתר? (אם לא, פרט לגבי חריגות הבנייה)',
    is_apartment_registered_on_seller_name: 'האם הדירה רשומה על שם המוכר? אם לא אז על שם מי?',
    is_apartment_abroad: 'האם הדירה בחו"ל?',
    are_you_sure_you_want_to_archive_strategy: 'האם אתה בטוח שברצונך לשמור את האסטרטגיה בארכיון?',
    are_you_sure_you_want_to_unarchive_strategy: 'האם אתה בטוח שברצונך להוציא את האסטרטגיה מהארכיון?',
    are_you_sure_you_want_to_unselect_strategy: 'האם אתה בטוח שברצונך לבטל את בחירת האסטרטגיה?',
    are_you_sure_you_want_to_select_strategy: 'האם אתה בטוח שברצונך לבחור באסטרטגיה?',
    liquidation_score: 'דרגת הנזלה',
    rate_from_1_to_10: 'דרג מ-1 עד 10 (1- גרוע 10- מצויין)',
    how_easy_to_find_renters: 'דירוג עד כמה קל למצוא שוכרים לנכס?',
    how_well_maintained: 'דירוג מצב תחזוקת הנכס',
    how_good_is_location: 'דירוג מיקום בעיר',
    how_good_are_current_renters: 'דירוג השוכרים הקיימים (אם יש)',
    linked_apartment: 'דירה מקושרת',
    apartment: 'דירה',
    tenant_stops_paying: 'דייר מפסיק לשלם',
    charts: 'גרפים',
    interest_only: 'גרייס',
    cashflow_appreciation: 'גידול שנתי',
    loan_provider: 'גוף מלווה',
    apartment_size: 'גודל הנכס',
    apartment_size_after_reconstruction: 'גודל אחרי התחדשות',
    loan_value: 'גובה הלוואה',
    new_presets: 'ברירות מחדל חדשות',
    presets: 'ברירות מחדל',
    highest_earner: 'בעל השכר הגבוה יותר',
    balance_with: 'בעזרת העבר ל',
    weakest: 'בנקודה החלשה ביותר',
    yearly_materials_cost_index_rise:
      'בנוסף ייתכן שתהיה או שלא תהיה הצמדה למדד תשומות הבנייה. צריכים מקום לשנות את ערך המדד.',
    balloon: 'בלון',
    home: 'בית',
    unarchiving_strategy: 'ביטול שמירת האסטרטגיה בארכיון',
    unselecting_strategy: 'ביטול בחירה באסטרטגיה',
    disable_reinvestment: 'בטל השקעות מחדש',
    unselect: 'בטל בחירה',
    are_you_sure_you_want_to_delete_taxbrackets: 'בטוח שברצונך למחוק מדרגת מס זו? פעולה זו בלתי הפיכה',
    linked_bank_account_has_no_money: 'בחשבון בנק קשור <b>{accountName}</b> אין כסף',
    select_unselect_all: 'בחר הכל/בטל בחירה בהכל ',
    select: 'בחר',
    selecting_strategy: 'בחירת אסטרטגיה',
    please_provide_a_primary_bank_account: 'בבקשה בחר חשבון ראשי לאסטרטגיה',
    are_you_sure_you_want_to_unarchive_customer: 'אתה בטוח שברצונך לשחזר את {{שם לקוח}}',
    are_you_sure_you_want_to_delete_cashflow: 'אתה בטוח שברצונך למחוק תזרים מזומנים זה? פעולה זו בלתי הפיכה',
    are_you_sure_you_want_to_delete_asset: 'אתה בטוח שברצונך למחוק נכס זה? פעולה זו בלתי הפיכה',
    are_you_sure_you_want_to_delete_liability: 'אתה בטוח שברצונך למחוק התחייבות זו ? פעולה זו בלתי הפיכה',
    are_you_sure_you_want_to_delete_investment: 'אתה בטוח שברצונך למחוק אפשרות השקעה זו? פעולה זו בלתי הפיכה',
    are_you_sure_you_want_to_delete_one_off_event: 'אתה בטוח שברצונך למחוק אירוע חד פעמי זה? פעולה זו בלתי הפיכה',
    are_you_sure_you_want_to_archive_customer: 'אתה בטוח שברצונך להעביר לארכיון את {{שם לקוח}}?',
    are_you_sure_you_want_to_reapply:
      'אתה בטוח שאתה רוצה לעדכן את הנחות היסוד של האסטרטגיה הזו? אנחנו ממליצים ליצור עותק שלה קודם',
    are_you_sure_you_want_to_delete_admin: 'אתה בטוח שאתה רוצה למחוק את מנהל {admin} אי אפשר לבטל פעולה זו',
    are_you_sure_you_want_to_delete_customer: 'אתה בטוח שאתה רוצה למחוק את לקוח {userName} אי אפשר לבטל פעולה זו',
    are_you_sure_delete_file: 'אתה בטוח שאתה רוצה למחוק את הקובץ ({fileName})? אין דרך חזרה',
    are_you_sure_you_want_to_delete_variable: 'אתה בטוח שאתה רוצה למחוק את המשתנה הזה? אין דרך חזרה',
    are_you_sure_you_want_to_delete_preset: 'אתה בטוח שאתה רוצה למחוק את ברירת המחדל הזו? אין דרך חזרה',
    are_you_sure_you_want_to_delete_strategy: 'אתה בטוח שאתה רוצה למחוק את אסטרטגיה {strategy} אי אפשר לבטל פעולה זו',
    are_you_sure_reset_comp_table: 'אתה בטוח שאתה רוצה לאפס את הטבלה הזו? אין דרך חזרה',
    confirm_password: 'אשר סיסמא',
    strategy_archive: 'ארכיון אסטרטגיות',
    archived: 'ארכיון',
    reset_to_default: 'אפס לברירת מחדל',
    strategies: 'אסטרטגיות',
    new_strategy: 'אסטרטגיה חדשה',
    strategy: 'אסטרטגיה',
    anona: 'אנונה',
    please_provide_a_primary_bank_account_for_this_strategy: 'אנא ספק חשבון בנק ראשי לאסטרטגיה זו',
    please_provide_your_email_address: 'אנא כתוב את האימייל שלך כדי להמשיך',
    alphabetically: 'אלפבתי',
    real_estate_full_service: 'איתור נדל”ן',
    reset_zoom: 'איפוס גודל תצוגה',
    no_name: 'אין שם',
    no_files_to_show: 'אין קבצים עדיין!',
    no_asset: 'אין נכס',
    margin_not_met: "אין מספיק יתרה לעמוד במרג'ין עבור נכס  {stockType} והלוואה <b>{loanName}</b> בתאריך <b>{date}</b>",
    nothing_to_show: 'אין מה להראות!',
    dont_have_an_account: 'אין לך חשבון? לחץ כאן להירשם',
    no_incomes: 'אין הכנסות',
    no_expenditure: 'אין הוצאות',
    no_email: 'אין אימייל',
    recipient_emails: 'אימיילים נמענים',
    secondary_email: 'אימייל נוסף',
    email: 'אימייל',
    other: 'אחר',
    mortgage_percentage: 'אחוזי מימון על הנכס הנרכש',
    percentage: 'אחוז',
    problems_detected: 'אותרו בעיות',
    appraised_value: 'אומדן שווי שמאי',
    crash_percentage: 'אובדן שווי במשבר',
    loses_place_of_work: 'אובדן מקום עבודה',
    showing_n_items: '{n} פריטים',
    how_to_use_app: 'לחץ לצפייה בסרטון הסבר',
    user_id_get_started: 'שם משתמש (ממולא אוטומטית, ישמש לכניסה מחדש למערכת)',
    password_get_started: 'בחר סיסמא',
    user_id_with_info: 'שם משתמש (לא כתובת המייל שלכם, קיבלתם אותו במייל ההרשמה)',
    show_apartments_for: 'הצג דירות עבור'
  }
};

export const languageOptions = Object.keys(languageContent);

export const getActiveLanguage = () => {
  return localStorage.getItem('language') || 'Hebrew';
};

export const setActiveLanguage = (lang = 'English') => {
  return localStorage.setItem('language', lang);
};

export const isRtl = language => {
  return language === 'Hebrew';
};
