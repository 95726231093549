import { snakeCase } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { Badge, FormCheck, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { ExclamationTriangle, PlusCircle } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import {
  monthSmall,
  reinvestmentTransferFromLabel,
  reinvestmentTransferToLabel
} from '../../../../../helpers/constants';
import { isNullOrUndefined } from '../../../../../helpers/global';
import { isRtl } from '../../../../../helpers/localization';
import LTRNumberCell from '../../../../common/LTRNumberCell';
import UnderlineButton from '../../../../common/UnderlineButton';
import { ActionCell } from './StrategyBalanceSheet';
import { isPrimaryBankAccount } from './helper';

const getReinvesmentHeaders = translate => [
  { key: 'from', label: '' },
  { key: 'to', label: '' },
  { key: 'startDate', label: translate('start_date') },
  { key: 'endDate', label: translate('end_date') },
  { key: 'value', label: translate('value') },
  { key: 'frequency', label: translate('frequency') },
  { key: 'continueAfterSell', label: translate('continue_after_sell') }
];

const getNameOfInstrument = (
  investment,
  assets,
  fixedIncomeAssets,
  liabilities,
  type = 'fromType',
  id = 'fromId',
  translate
) => {
  const instrument = (investment[type] === 'asset' ? [...assets, ...fixedIncomeAssets] : liabilities).find(
    asset => asset._id && asset._id === investment[id]
  );
  if (!instrument) return '-';

  return investment[type] === 'asset' && isPrimaryBankAccount(instrument)
    ? translate('primary_bank_account')
    : instrument.name;
};

const ReinvestmentSheet = ({
  editable,
  reInvestments,
  onAddReinvestmentOption,
  assets,
  fixedIncomeAssets,
  liabilities,
  onEditReinvestmentClick,
  onDeleteReinvestmentClick,
  onDisableReinvestmentClick,
  onDisableAllReinvestmentsCheck,
  hasCyclicInvestments,
  disabled
}) => {
  const { translate, langCode } = useContext(LocalizeContext);

  const reinvesmentHeaders = useMemo(() => getReinvesmentHeaders(translate), [langCode]);

  const isRTL = useMemo(() => isRtl(langCode), [langCode]);

  const renderTooltip = props => (
    <Tooltip color="danger" {...props}>
      {translate('some_investment_are_not_applied')}
    </Tooltip>
  );

  return (
    <div className="py-3">
      <div className="d-flex">
        <div className="flex-grow-1">
          <h6 className="">
            <b>{translate('reinvestments')}</b>
            {editable && (
              <UnderlineButton
                text={translate('add_reinvestment')}
                Icon={PlusCircle}
                fontSize="mid"
                variant="success"
                className="mx-1"
                disabled={disabled}
                onClick={onAddReinvestmentOption}
              />
            )}
            {hasCyclicInvestments && (
              <OverlayTrigger placement="top" overlay={renderTooltip}>
                <ExclamationTriangle size={20} className="mx-2 text-danger" />
              </OverlayTrigger>
            )}
          </h6>
        </div>
        <div className="d-none d-md-block">
          <FormCheck
            size={'sm'}
            label={<b className="text-dark mid">{translate('disable_reinvestment')}</b>}
            checked={reInvestments.every(r => r.disabled)}
            onChange={e => onDisableAllReinvestmentsCheck(e.target.checked)}
          />
        </div>
      </div>
      <div className="d-flex d-md-none mb-1">
        <FormCheck
          size={'sm'}
          label={<b className="text-dark mid">{translate('disable_reinvestment')}</b>}
          checked={reInvestments.every(r => r.disabled)}
          onChange={e => onDisableAllReinvestmentsCheck(e.target.checked)}
        />
      </div>
      {reInvestments.length > 0 ? (
        <Table responsive bordered className="smallFont mb-2">
          <thead>
            <tr className="bg-dark text-white">
              {reinvesmentHeaders.map(h => (
                <th style={{ minWidth: 100 }}>{h.label}</th>
              ))}
              <th style={{ minWidth: 100 }}>{translate('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {reInvestments.map(investment => (
              <tr className="bg-white" key={investment._id}>
                <td style={{ minWidth: 100 }}>
                  <Badge bg="secondary" className={`text-${isRTL ? 'end' : 'start'} mb-1 text-dark d-block smallFont`}>
                    {translate(reinvestmentTransferFromLabel[investment.transferValueType])}:
                  </Badge>
                  <span className="">
                    {getNameOfInstrument(
                      investment,
                      assets,
                      fixedIncomeAssets,
                      liabilities,
                      'fromType',
                      'fromId',
                      translate
                    )}
                  </span>
                </td>
                <td style={{ minWidth: 100 }}>
                  <Badge bg="secondary" className={`text-${isRTL ? 'end' : 'start'} mb-1 text-dark d-block`}>
                    {translate(reinvestmentTransferToLabel[investment.transferValueType])}:
                  </Badge>
                  <span className="">
                    {getNameOfInstrument(
                      investment,
                      assets,
                      fixedIncomeAssets,
                      liabilities,
                      'toType',
                      'toId',
                      translate
                    )}
                  </span>
                </td>

                <td style={{ minWidth: 100 }}>{investment.startYear + ' ' + monthSmall[investment.startMonth]}</td>
                <td style={{ minWidth: 100 }}>
                  {!isNullOrUndefined(investment.endYear) && !isNullOrUndefined(investment.endMonth)
                    ? investment.endYear + ' ' + monthSmall[investment.endMonth]
                    : '-'}
                </td>
                <td style={{ minWidth: 100 }}>
                  <LTRNumberCell value={investment.value} />
                </td>

                <td style={{ minWidth: 100 }}>{translate(snakeCase(investment.frequency)) || 'N/A'}</td>
                <td>{investment.continueAfterSell ? translate('yes') : translate('no')}</td>
                <td>
                  <ActionCell
                    onEditClick={
                      editable &&
                      (() => {
                        onEditReinvestmentClick(investment);
                      })
                    }
                    onDeleteClick={editable && (() => onDeleteReinvestmentClick(investment))}
                    isItemNotApplied={investment.disabled}
                    onDisableClick={() => onDisableReinvestmentClick(investment)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <h6 className="p-5 text-center">{translate('nothing_to_show')}</h6>
      )}
    </div>
  );
};

export default ReinvestmentSheet;
