import { snakeCase } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { Badge, Button, Form, Modal } from 'react-bootstrap';
import { LocalizeContext } from 'react-locale-language';
import { reinvestmentTransferFromLabel, reinvestmentTransferToLabel } from '../../../../../helpers/constants';
import { isRtl } from '../../../../../helpers/localization';
import AppTable from '../../../../common/app-table/AppTable';
import HorizontalProgress from '../../../../common/HorizontalProgress';
import LTRNumberCell from '../../../../common/LTRNumberCell';
import { isPrimaryBankAccount } from './helper';

const getNameOfInstrument = (
  investment,
  assets,
  fixedIncomeAssets,
  liabilities,
  type = 'fromType',
  id = 'fromId',
  translate
) => {
  const instrument = (investment[type] === 'asset' ? [...assets, ...fixedIncomeAssets] : liabilities).find(
    asset => asset._id && asset._id === investment[id]
  );
  if (!instrument) return '-';

  return investment[type] === 'asset' && isPrimaryBankAccount(instrument)
    ? translate('primary_bank_account')
    : instrument.name;
};

const SyncFixedTransferModal = ({
  show,
  investments = [],
  assets = [],
  fixedIncomeAssets = [],
  liabilities = [],
  onInvestmentsChange = [],
  onHide,
  showProgress,
  onSubmit
}) => {
  const { translate, langCode } = useContext(LocalizeContext);

  const isRTL = useMemo(() => isRtl(langCode), [langCode]);

  const onInvestmentCheckedChange = investment => {
    investment.continueAfterSell = !investment.continueAfterSell;
    onInvestmentsChange([...investments]);
  };

  return (
    <Modal size="lg" className="bg-dark bg-opacity-25" show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton={!showProgress}>
        <Modal.Title>
          <h6 className="mb-0">Update Investments</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-auto p-4">
        {show && (
          <>
            <h6 className="text-muted mid">
              You have updated the date of the asset. Would you like to make changes to the following related
              investments:
            </h6>
            <AppTable>
              <thead>
                <tr className="bg-dark text-white">
                  {[
                    { key: 'from', label: '' },
                    { key: 'to', label: '' },
                    { key: 'value', label: translate('value') },
                    { key: 'transferValueType', label: translate('transfer_type') },
                    { key: 'continueAfterSell', label: translate('continue_after_sell') }
                  ].map(({ key, label }) => (
                    <th key={key}>{label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {investments.map(investment => (
                  <tr>
                    <td style={{ minWidth: 100 }}>
                      <Badge
                        bg="secondary"
                        className={`text-${isRTL ? 'end' : 'start'} mb-1 text-dark d-block smallFont`}
                      >
                        {translate(reinvestmentTransferFromLabel[investment.transferValueType])}:
                      </Badge>
                      <span className="">
                        {getNameOfInstrument(
                          investment,
                          assets,
                          fixedIncomeAssets,
                          liabilities,
                          'fromType',
                          'fromId',
                          translate
                        )}
                      </span>
                    </td>
                    <td style={{ minWidth: 100 }}>
                      <Badge bg="secondary" className={`text-${isRTL ? 'end' : 'start'} mb-1 text-dark d-block`}>
                        {translate(reinvestmentTransferToLabel[investment.transferValueType])}:
                      </Badge>
                      <span className="">
                        {getNameOfInstrument(
                          investment,
                          assets,
                          fixedIncomeAssets,
                          liabilities,
                          'toType',
                          'toId',
                          translate
                        )}
                      </span>
                    </td>
                    <td>
                      <LTRNumberCell value={investment.value} />
                    </td>
                    <td>{translate(snakeCase(investment.frequency)) || 'N/A'}</td>

                    <td align="center">
                      {investment.transferValueType === 'fixed' && (
                        <Form.Check
                          size={'sm'}
                          checked={investment.continueAfterSell}
                          onChange={() => onInvestmentCheckedChange(investment)}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </AppTable>
            <h6 className="text-muted mid">
              If you check "Continue After Sell", the end date of the investment will be set to infinite and the
              transfer will go to primary bank account.
            </h6>
          </>
        )}

        {showProgress && <HorizontalProgress text={translate('please_wait')} />}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={showProgress}
          size="sm"
          variant="success"
          className="mr-2 text-white"
          onClick={() => onSubmit()}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default SyncFixedTransferModal;
