import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Col, FormCheck, Row, Table } from 'react-bootstrap';
import { ArrowUpCircle, CircleFill } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import { getScenarioOptions, monthSmall } from '../../../../../../helpers/constants';
import DropdownSelector from '../../../../../common/DropdownSelector';
import LTRNumberCell from '../../../../../common/LTRNumberCell';
import LineChart from '../../../../../common/line-chart/LineChart';
import ArmageddonMultipleStrategyModal from '../../strategies/ArmageddonMultipleStrategyModal';
import {
  calculateBalanceSheet,
  cloneStrategy,
  findWeakestMonthYearOfStrategy,
  formatCurrency,
  getChartColor,
  getFinalYearOfStrategy,
  getMonthlyCompoundedDepreciation,
  getNextNYear,
  getValueAtMonthYear
} from '../helper';
import ChartContainer from './ChartContainer';
import { isRtl } from '../../../../../../helpers/localization';
import DataTable from '../../../../../common/data-table/DataTable';
import { strategyComparisionTableColumns } from '../../../../../../helpers/global';
import SwitchInput from '../../../../../common/SwitchInput';

export const getMetricOptions = translate => [
  { name: translate('equity'), key: 'equity' },
  { name: translate('assets'), key: 'assets' },
  { name: translate('liabilities'), key: 'liabilities' }
];

const createChartData = (
  strategies = [],
  activeMetric,
  activeScenario,
  armageddonDetails,
  translate,
  stressTestApplied,
  adminCompTable,
  customerCompTable,
  strategyYears,
  endAllAtTheEnd
) => {
  const labels = [];

  const getMonthlyValue = (strategy, sectionName, monthNum, year) => {
    return strategy[sectionName]
      .filter(a => !a.isInvalid && !a.toBeMerged)
      .reduce((prevValue, a) => prevValue + getValueAtMonthYear(a, monthNum, year), 0);
  };

  const strategyDatasets = [];

  strategies.forEach((originalStrategy, index) => {
    const clonedStrategy = cloneStrategy(originalStrategy);
    clonedStrategy.totalYears = strategyYears[strategyYears.length - 1] - clonedStrategy.initialYear + 1;
    if (activeScenario.changeInPercentage) {
      clonedStrategy.assets.forEach(a => {
        a.returnAppreciation = a.returnAppreciation + activeScenario.changeInPercentage * a.returnAppreciation;
      });
    }

    if (endAllAtTheEnd) {
      const finalYear = clonedStrategy.initialYear + clonedStrategy.totalYears - 1;
      const finalMonth = clonedStrategy.initialMonth - 1;

      //sell all if not sold at the end
      clonedStrategy.assets.forEach(a => {
        if (!a.sellingYear) {
          a.sellingYear = finalYear;
          a.sellingMonth = finalMonth;
        }
      });
      [...clonedStrategy.liabilities, ...clonedStrategy.fixedIncomeAssets].forEach(l => {
        if (!l.endYear) {
          l.endYear = finalYear;
          l.endMonth = finalMonth;
        }
      });
    }

    const armageddon = activeScenario.isArmageddon &&
      armageddonDetails && {
        ...armageddonDetails,
        assetDepreciations: armageddonDetails.strategyAssetDepreciations.find(
          s => s.strategyName === originalStrategy.name
        )?.assetDepreciations
      };

    const strategy = calculateBalanceSheet(
      clonedStrategy,
      armageddon,
      stressTestApplied,
      translate,
      false,
      adminCompTable,
      customerCompTable
    );

    const strategyColor = getChartColor(index);
    const thisStrategyDatapoints = [];

    const initialMonth = strategy.initialMonth;

    strategyYears.forEach((year, yearIndex) => {
      monthSmall.forEach((month, monthNum) => {
        if (yearIndex === 0 && monthNum < initialMonth) return;
        if (initialMonth > 0 && yearIndex === strategyYears.length - 1 && monthNum >= initialMonth) return;

        const label = `${month} ${year}`;
        if (!labels.includes(label)) labels.push(label);

        let value;
        if (activeMetric.key === 'equity') {
          const assetValueAtThisMonth = getMonthlyValue(strategy, 'assets', monthNum, year);
          const liabilityValueAtThisMonth = getMonthlyValue(strategy, 'liabilities', monthNum, year);
          value = assetValueAtThisMonth - liabilityValueAtThisMonth;
        } else {
          value = getMonthlyValue(strategy, activeMetric.key, monthNum, year);
        }

        thisStrategyDatapoints.push(value.toFixed(0));
      });
    });

    strategyDatasets.push({
      label: strategy.name,
      data: thisStrategyDatapoints,
      borderColor: strategyColor,
      backgroundColor: strategyColor
    });
  });

  return {
    labels,
    datasets: strategyDatasets
  };
};

const ComparisonTable = ({ translate, chartData, hoverIndex, visibleStrategies, isRTL }) => {
  const comparisonData = useMemo(() => {
    const dataIndex = hoverIndex ?? chartData.datasets[0].data.length - 1;

    return {
      label: chartData.labels[dataIndex],
      data: chartData.datasets
        .filter((_, index) => visibleStrategies[index])
        .sort((a, b) => Number(a.data[dataIndex]) - Number(b.data[dataIndex]))
        .map((d, index, datasets) => ({
          name: d.label,
          color: d.backgroundColor,
          value: d.data[dataIndex],
          diffFromPrevious: index === 0 ? d.data[dataIndex] : d.data[dataIndex] - datasets[index - 1].data[dataIndex]
        }))
    };
  }, [chartData, hoverIndex, visibleStrategies]);

  return (
    <>
      <div className="mid position-relative  my-2">
        <Table hover bordered className="mid   my-2">
          <thead className="sticky-header">
            <tr className="text-white bg-dark">
              <th className="">{translate('strategy')}</th> <th className="">{translate('value')}</th>
              <th className="">{translate('difference')}</th>
            </tr>
          </thead>
          <tbody>
            {comparisonData.data.map((r, index) => (
              <tr key={r.name}>
                <td className={`d-flex align-items-center  ${index !== 0 ? 'bg-success bg-opacity-25' : ''}`}>
                  <div style={{ width: 'fit-content' }}>
                    <CircleFill size={10} style={{ color: r.color }} />
                  </div>
                  <b className="mx-1"> {r.name}</b>
                </td>
                <td className={`${index !== 0 ? 'bg-success bg-opacity-25' : ''} `}>{formatCurrency(r.value)} </td>
                <td className={`${index !== 0 ? 'bg-success bg-opacity-25' : ''} `}>
                  {index !== 0 && !isRTL && <span className="fw-bold">+</span>}
                  <LTRNumberCell value={r.diffFromPrevious} />
                  {index !== 0 && isRTL && <span className="fw-bold">+</span>}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>{' '}
      <h6 className="mid fw-bold text-end">
        <i>Date: {comparisonData.label}</i>
      </h6>
    </>
  );
};

const ComparisonChart = ({
  strategies: originalStrategies = [],
  activeStrategy,
  adminCompTable,
  customerCompTable
}) => {
  const { translate, langCode } = useContext(LocalizeContext);
  const showStressTestAndArmaggedonToClient = useMemo(() => adminCompTable.appConfig.showStressTestToClient, [
    adminCompTable
  ]);
  const scenarioOptions = useMemo(() => getScenarioOptions(translate, showStressTestAndArmaggedonToClient), [
    langCode,
    showStressTestAndArmaggedonToClient
  ]);
  const metricOptions = useMemo(() => getMetricOptions(translate), [langCode]);
  const isRTL = useMemo(() => isRtl(langCode), [langCode]);

  const [chartData, setChartData] = useState();
  const [activeMetric, setActiveMetric] = useState(metricOptions[0]);
  const [activeScenario, setActiveScenario] = useState(scenarioOptions[0]);
  const [armageddonDetails, setArmageddonDetails] = useState(null);
  const [armageddonDetailsModalMeta, setArmageddonDetailsModalMeta] = useState(null);
  const [stressTestApplied, setStressTestApplied] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(null);

  const strategies = useMemo(() => originalStrategies.filter(s => !s.killed), [originalStrategies]);
  const chartStartYear = useMemo(() => Math.min(...strategies.map(s => s.initialYear)), [strategies]);
  const chartEndYear = useMemo(() => Math.max(...strategies.map(s => getFinalYearOfStrategy(s))), [strategies]);

  const strategyYears = useMemo(() => getNextNYear(chartStartYear, chartEndYear - chartStartYear + 1), [
    chartStartYear,
    chartEndYear
  ]);

  const [visibleStrategies, setVisibleStrategies] = useState(strategies.map(() => true));
  const [endAllAtTheEnd, setEndAllAtTheEnd] = useState(false);

  useEffect(() => {
    setChartData(
      createChartData(
        strategies,
        activeMetric,
        activeScenario,
        armageddonDetails,
        translate,
        stressTestApplied,
        adminCompTable,
        customerCompTable,
        strategyYears,
        endAllAtTheEnd
      )
    );
  }, [strategies, activeMetric, activeScenario, armageddonDetails, stressTestApplied, endAllAtTheEnd]);

  const onArmageddonStrategyAssetDepreciationChange = (strategyName, assetId, depreciation) => {
    const { strategyAssetDepreciations = [] } = armageddonDetailsModalMeta;

    const updatedAsset = strategyAssetDepreciations
      .find(s => s.strategyName === strategyName)
      .assetDepreciations.find(a => a._id === assetId);

    if (updatedAsset) {
      updatedAsset.depreciation = depreciation;
      setArmageddonDetailsModalMeta({ ...armageddonDetailsModalMeta, strategyAssetDepreciations });
    }
  };

  const onApplyArmageddon = () => {
    setArmageddonDetails({
      ...armageddonDetailsModalMeta,
      strategyAssetDepreciations: armageddonDetailsModalMeta.strategyAssetDepreciations.map(s => ({
        ...s,
        assetDepreciations: s.assetDepreciations.map(a => ({
          ...a,
          monthlyDepreciation: getMonthlyCompoundedDepreciation(18, a.depreciation)
        }))
      }))
    });
    setArmageddonDetailsModalMeta(null);
  };

  const onActiveScenarioChange = scenario => {
    setActiveScenario(scenario);
    if (scenario.isArmageddon) {
      setArmageddonDetails({
        startMonth: 0,
        startYear: Math.min(...strategies.map(s => s.initialYear)) + 1,
        strategyAssetDepreciations: strategies.map(s => ({
          strategyName: s.name,
          assetDepreciations: s.assets
            .filter(a => ['Stocks', 'Real Estate'].includes(a.category))
            .map(a => ({
              _id: a._id,
              name: a.name,
              depreciation: a.crashPercentage,
              monthlyDepreciation: getMonthlyCompoundedDepreciation(18, a.crashPercentage)
            }))
        }))
      });
    }
  };

  const onArmagedonClick = () => {
    setArmageddonDetailsModalMeta({ ...armageddonDetails });
  };

  const onArmageddonWeakestTimeClick = () => {
    const strategy = strategies.find(s => s._id === activeStrategy._id);
    const weakestMonthYear = findWeakestMonthYearOfStrategy(
      calculateBalanceSheet(strategy, null, null, translate, false, adminCompTable, customerCompTable)
    );

    setArmageddonDetailsModalMeta({
      ...armageddonDetailsModalMeta,
      startYear: weakestMonthYear.year,
      startMonth: weakestMonthYear.month
    });
  };

  return (
    <>
      <ChartContainer
        title={translate('strategies_comparison')}
        maxWidth={'100%'}
        renderAdditionalComponent={() => (
          <div className="d-flex align-items-center">
            {showStressTestAndArmaggedonToClient && (
              <FormCheck
                onChange={e => setStressTestApplied(e.target.checked)}
                checked={stressTestApplied}
                className="large mx-1"
                inline
                label={translate('apply_stress_test')}
              />
            )}
            <DropdownSelector
              className="mx-1"
              options={metricOptions.map(m => m.name)}
              value={activeMetric.name}
              onValueChange={metricName => setActiveMetric(metricOptions.find(m => m.name === metricName))}
            />
            <DropdownSelector
              className="mx-1"
              options={scenarioOptions.map(s => s.name)}
              value={activeScenario.name}
              onValueChange={scenarioName => onActiveScenarioChange(scenarioOptions.find(o => o.name === scenarioName))}
            />
            {showStressTestAndArmaggedonToClient && activeScenario.isArmageddon && armageddonDetails && (
              <div className="d-inline-block mx-1">
                <Button
                  style={{
                    padding: '0px 5px',
                    fontSize: 13
                  }}
                  size="sm"
                  variant="danger"
                  onClick={onArmagedonClick}
                >
                  {translate('configure_armageddon')}
                </Button>
              </div>
            )}

            <div className="bg-dark" style={{ height: 18, width: 1 }}></div>
            <SwitchInput
              onChange={value => setEndAllAtTheEnd(value)}
              value={endAllAtTheEnd}
              label={translate('after_tax')}
            />
          </div>
        )}
      >
        {chartData && (
          <Row>
            <Col xs={12} md={8} lg={8} className="px-2">
              <LineChart
                onMouseHover={hoverPoint => setHoverIndex(hoverPoint.index)}
                onMouseExit={() => setHoverIndex(null)}
                visibleLines={visibleStrategies}
                onVisibleLinesChange={setVisibleStrategies}
                chartData={chartData}
              />
            </Col>
            <Col xs={12} md={4} lg={4}>
              <ComparisonTable
                translate={translate}
                isRTL={isRTL}
                hoverIndex={hoverIndex}
                chartData={chartData}
                visibleStrategies={visibleStrategies}
              />
            </Col>
          </Row>
        )}
      </ChartContainer>
      {activeScenario.isArmageddon && (
        <ArmageddonMultipleStrategyModal
          strategyYears={strategyYears}
          show={armageddonDetailsModalMeta !== null}
          onHide={() => setArmageddonDetailsModalMeta(null)}
          strategyAssetDepreciations={armageddonDetailsModalMeta?.strategyAssetDepreciations}
          onStrategyAssetDepreciationsChange={onArmageddonStrategyAssetDepreciationChange}
          startYear={armageddonDetailsModalMeta?.startYear}
          onStartYearChange={year => setArmageddonDetailsModalMeta({ ...armageddonDetailsModalMeta, startYear: year })}
          startMonth={armageddonDetailsModalMeta?.startMonth}
          onStartMonthChange={month =>
            setArmageddonDetailsModalMeta({ ...armageddonDetailsModalMeta, startMonth: month })
          }
          onWeakestTimeClick={onArmageddonWeakestTimeClick}
          onApplyArmageddon={onApplyArmageddon}
          translate={translate}
        />
      )}
    </>
  );
};

export default ComparisonChart;
