import React, { useContext, useMemo, useState } from 'react';
import { Button, FormControl, FormSelect, Modal } from 'react-bootstrap';
import { PlusCircle, Trash } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import FormGenerator from '../../../../../form-generator/FormGenerator';
import { CheckBoxInput } from '../../../../../form-generator/components';
import {
  IRR_CALCULATOR_KEY,
  PAPER_CALCULATOR_KEY,
  PINUI_BINUI_CALCULATOR_KEY,
  monthSmall
} from '../../../../../helpers/constants';
import HorizontalProgress from '../../../../common/HorizontalProgress';
import AppTable from '../../../../common/app-table/AppTable';
import BuyingTax from './BuyingTax';
import SellingTax from './SellingTax';
import { addAssetForm, updateAssetForm } from './form';
import UnderlineButton from '../../../../common/UnderlineButton';
import { calculatorApartmentModalFields, formatCurrency } from './helper';
import { formatPercentage } from './helper';
import ExplainatoryVideoButton from '../../../../common/ExplainatoryVideoButton';
import useAuth from '../../../../../hooks/useAuth';
import { findIfUserIsInterestedInCourseAndOfSpecifiedLevel, isAdmin } from '../../../../../helpers/global';
import SlidingSideBar from '../../../../common/SlidingSideBar/SlidingSideBar';
import RealEstateIRR from '../../../../calculators/Irr';
import PinuiBinuiIRR from '../../../../calculators/PinuiBinui';
import PaperApartmentIRR from '../../../../calculators/PaperApartment';
import CurrencyInput from '../../../../common/CurrencyInput';

const getPaymentHeaders = (translate, strategyYears) => [
  {
    name: translate('month'),
    key: 'month',
    type: 'dropdown',
    options: monthSmall.map((m, i) => ({ option: m, value: i }))
  },
  {
    name: translate('year'),
    key: 'year',
    type: 'dropdown',
    options: strategyYears.map(y => ({ option: y, value: y }))
  },
  {
    name: translate('value'),
    type: 'number',
    format: 'currency',
    key: 'amount'
  },
  {
    name: '%',
    type: 'number',
    format: '%',
    key: '%'
  }
];

/** value,amount,  year, month,  vestingYear, vestingMonth*/
const getRSUHeaders = (translate, strategyYears) => [
  {
    name: translate('month'),
    key: 'month',
    type: 'dropdown',
    options: monthSmall.map((m, i) => ({ option: m, value: i }))
  },
  {
    name: translate('year'),
    key: 'year',
    type: 'number',
    options: strategyYears.map(y => ({ option: y, value: y }))
  },
  {
    name: translate('stock_amount'),
    type: 'number',
    format: 'currency',
    key: 'amount'
  },
  {
    name: translate('stock_value'),
    type: 'number',
    format: 'currency',
    key: 'value',
    withCurrencyConverter: true,
    minWidth: 200
  },
  {
    name: translate('vesting_month'),
    key: 'vestingMonth',
    type: 'dropdown',
    options: monthSmall.map((m, i) => ({ option: m, value: i }))
  },
  {
    name: translate('vesting_year'),
    key: 'vestingYear',
    type: 'number',
    options: strategyYears.map(y => ({ option: y, value: y }))
  }
];

const PaperApartmentPayments = ({
  translate,
  apartmentValue = 0,
  paperApartmentPayments = [],
  onPaymentsChange,
  onPaymentAddClick,
  onPaymentDeleteClick,
  strategyYears = []
}) => {
  const { langCode } = useContext(LocalizeContext);
  const paymentHeaders = useMemo(() => getPaymentHeaders(translate, strategyYears), [langCode, strategyYears]);
  const { user } = useAuth();
  const onChange = (payment, field, type, format, value) => {
    payment[field] = value;

    if (type === 'number') {
      if (format === '%') {
        //update number also
        payment.amount = apartmentValue * value * 0.01;
      } else {
        //update % also
        payment['%'] = apartmentValue === 0 ? 0 : ((value * 100) / apartmentValue).toFixed(2);
      }
    }

    onPaymentsChange && onPaymentsChange(paperApartmentPayments.map(p => (p.id === payment.id ? { ...payment } : p)));
  };

  return (
    <>
      <AppTable className="mb-0">
        <thead>
          <tr className="text-white bg-dark">
            {paymentHeaders.map(({ name }) => (
              <th style={{ minWidth: 100 }} key={name}>
                {name}
              </th>
            ))}
            {!findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1]) && (
              <th style={{ minWidth: 70 }}>{translate('actions')}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {paperApartmentPayments.length > 0 ? (
            <>
              {paperApartmentPayments.map(payment => (
                <tr key={payment.id}>
                  {paymentHeaders.map(({ name, type, options, key, format }) => (
                    <td className="bg-white p-0" key={name}>
                      {type === 'dropdown' && (
                        <FormSelect
                          className="mb-0"
                          autoComplete="off"
                          size="sm"
                          value={payment[key]}
                          onChange={e => {
                            onChange(payment, key, type, format, e.target.value);
                          }}
                        >
                          {options.map(o => (
                            <option key={o.value} value={o.value}>
                              {o.option}
                            </option>
                          ))}
                        </FormSelect>
                      )}
                      {type === 'number' && (
                        <FormControl
                          className="mb-0"
                          autoComplete="off"
                          type="text"
                          size="sm"
                          value={format === 'currency' ? formatCurrency(payment[key]) : formatPercentage(payment[key])}
                          onChange={e => {
                            let inputValue = Number(
                              e.target.value.replaceAll(format === 'currency' ? ',' : '%', '').trim() || 0
                            );
                            if (Number.isNaN(inputValue)) return;
                            //call on change
                            onChange(payment, key, type, format, inputValue);
                          }}
                        />
                      )}
                    </td>
                  ))}
                  {!findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1]) && (
                    <td className="p-0">
                      <div className="text-center">
                        <Button
                          variant="outline-danger"
                          className="px-1 py-0 "
                          onClick={() => onPaymentDeleteClick(payment)}
                        >
                          <Trash size={9} />
                        </Button>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={paymentHeaders.length + 1}>
                <h6 className="text-muted text-center mb-0 smallFont">{translate('nothing_to_show')}</h6>
              </td>
            </tr>
          )}
        </tbody>
      </AppTable>
      <div className="text-end">
        {!findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1]) && (
          <UnderlineButton
            fontSize="smallFont"
            variant="success"
            Icon={PlusCircle}
            text={translate('new')}
            onClick={onPaymentAddClick}
          />
        )}
      </div>
    </>
  );
};

const IrrCalculatorSidebar = ({
  customer,
  show,
  onHide,
  onSelectAppartment,
  translate,
  adminCompTable,
  selectMode
}) => {
  return (
    <SlidingSideBar
      fullScreen
      visible={show}
      onClose={onHide}
      showCloseButton
      title={`${translate('irr_calculator')} (${customer?.name})`}
    >
      {show && (
        <RealEstateIRR
          adminCompTable={adminCompTable}
          customer={customer}
          onSelectAppartment={onSelectAppartment}
          selectMode={selectMode}
        />
      )}
    </SlidingSideBar>
  );
};

const PinuiBinuiIrrCalculatorSidebar = ({
  customer,
  show,
  onHide,
  onSelectAppartment,
  translate,
  adminCompTable,
  selectMode
}) => (
  <SlidingSideBar
    fullScreen
    visible={show}
    onClose={onHide}
    showCloseButton
    title={`${translate('pinui_binui')} (${customer?.name})`}
  >
    {show && (
      <PinuiBinuiIRR
        adminCompTable={adminCompTable}
        customer={customer}
        onSelectAppartment={onSelectAppartment}
        selectMode={selectMode}
      />
    )}
  </SlidingSideBar>
);

const PaperApartmentCalculatorSidebar = ({
  customer,
  show,
  onHide,
  onSelectAppartment,
  translate,
  adminCompTable,
  selectMode
}) => (
  <SlidingSideBar
    fullScreen
    visible={show}
    onClose={onHide}
    showCloseButton
    title={`${translate('paper_apartment')} (${customer?.name})`}
  >
    {show && (
      <PaperApartmentIRR
        adminCompTable={adminCompTable}
        customer={customer}
        onSelectAppartment={onSelectAppartment}
        selectMode={selectMode}
      />
    )}
  </SlidingSideBar>
);

const apartmentTypeToKey = {
  'Classic Apartment': IRR_CALCULATOR_KEY,
  'Pinui Binui Apartment': PINUI_BINUI_CALCULATOR_KEY,
  'Paper Apartment': PAPER_CALCULATOR_KEY
};

const RSUGrants = ({
  translate,
  rsuGrants = [],
  onRSUGrantsChange,
  onRSUGrantsAddClick,
  onRSUGrantsDeleteClick,
  strategyYears = []
}) => {
  const { langCode } = useContext(LocalizeContext);
  const rsuHeaders = useMemo(() => getRSUHeaders(translate, strategyYears), [langCode, strategyYears]);
  const { user } = useAuth();

  const onChange = (payment, field, type, format, value) => {
    payment[field] = value;
    onRSUGrantsChange && onRSUGrantsChange(rsuGrants.map(p => (p.id === payment.id ? { ...payment } : p)));
  };

  return (
    <>
      <AppTable className="mb-0 no-sticky">
        <thead>
          <tr className="text-white bg-dark">
            {rsuHeaders.map(({ name, minWidth = 100 }) => (
              <th style={{ minWidth }} key={name}>
                {name}
              </th>
            ))}
            {!findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1]) && (
              <th style={{ minWidth: 70 }}>{translate('actions')}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {rsuGrants.length > 0 ? (
            <>
              {rsuGrants.map(payment => (
                <tr key={payment.id}>
                  {rsuHeaders.map(({ name, type, options, key, format, withCurrencyConverter }) => (
                    <td className="bg-white p-0" key={name}>
                      {type === 'dropdown' && (
                        <FormSelect
                          className="mb-0"
                          autoComplete="off"
                          size="sm"
                          value={payment[key]}
                          onChange={e => {
                            onChange(payment, key, type, format, e.target.value);
                          }}
                        >
                          {options.map(o => (
                            <option key={o.value} value={o.value}>
                              {o.option}
                            </option>
                          ))}
                        </FormSelect>
                      )}
                      {type === 'number' && (
                        <>
                          {format === 'currency' ? (
                            <CurrencyInput
                              size="sm"
                              className="mb-0"
                              autoComplete="off"
                              value={payment[key]}
                              returnZeroByDefault={false}
                              onChange={value => {
                                //call on change
                                onChange(payment, key, type, format, value);
                              }}
                              onCurrencyConvert={value => {
                                const currencyKey = `${key}CurrencyConversionInfo`;
                                const currencyValue = {
                                  conversionCurrency: value.fromCurrency,
                                  conversionRate: value.conversionRate,
                                  conversionAmount: value.amount
                                };
                                onChange(payment, currencyKey, type, format, currencyValue);
                              }}
                              currencyConversionInfo={
                                payment?.[`${key}CurrencyConversionInfo`]
                                  ? {
                                      fromCurrency: payment?.[`${key}CurrencyConversionInfo`].conversionCurrency,
                                      conversionRate: payment?.[`${key}CurrencyConversionInfo`].conversionRate,
                                      amount: payment?.[`${key}CurrencyConversionInfo`].conversionAmount
                                    }
                                  : null
                              }
                              withCurrencyConverter={withCurrencyConverter}
                            />
                          ) : (
                            <FormControl
                              className="mb-0"
                              autoComplete="off"
                              type="number"
                              size="sm"
                              value={payment[key]}
                              onChange={e => {
                                let inputValue = Number(e.target.value.trim() || 0);
                                if (Number.isNaN(inputValue)) return;
                                //call on change
                                onChange(payment, key, type, format, inputValue);
                              }}
                            />
                          )}
                        </>
                      )}
                    </td>
                  ))}
                  {!findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1]) && (
                    <td className="p-0">
                      <div className="text-center">
                        <Button
                          variant="outline-danger"
                          className="px-1 py-0 "
                          onClick={() => onRSUGrantsDeleteClick(payment)}
                        >
                          <Trash size={9} />
                        </Button>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={rsuHeaders.length + 1}>
                <h6 className="text-muted text-center mb-0 smallFont">{translate('nothing_to_show')}</h6>
              </td>
            </tr>
          )}
        </tbody>
      </AppTable>
      <div className="text-end">
        {!findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1]) && (
          <UnderlineButton
            fontSize="smallFont"
            variant="success"
            Icon={PlusCircle}
            text={translate('new')}
            onClick={onRSUGrantsAddClick}
          />
        )}
      </div>
    </>
  );
};

const AssetModal = ({
  show,
  toBeEditedAsset,
  onHide,
  strategy,
  showProgress,
  strategyYears = [],
  onAssetSubmit,
  onGroupAssetSubmit,
  onAddAssetWithLiabilityClick,
  onBuyingTaxChange,
  onBuyingTaxPercentageChange,
  onSellingTaxChange,
  onSellingTaxPercentageChange,
  buyingTaxAutoMode,
  onBuyingTaxCalculationModeChange,
  sellingTaxAutoMode,
  onSellingTaxCalculationModeChange,
  taxInfo,
  onlyApartment,
  onFirstApartmentCheckedChange,
  isPrimaryBankAccount,
  assetValue,
  assetCategory,
  assetEditMode,
  apartmentType,
  paperApartmentPayments = [],
  onPaperApartmentPaymentsChange,
  onPaperApartmentPaymentAddClick,
  onPaperApartmentPaymentDeleteClick,
  rsuGrants = [],
  onRSUGrantsChange,
  onRSUGrantsAddClick,
  onRSUGrantsDeleteClick,
  assetOptions = [],
  presetOptions = [],
  assetBuyingYear,
  assetSellingYear,
  incompleteYearsOfStrategy,
  activeCustomer,
  adminCompTable,
  onCalculatorApartmentImport
}) => {
  const isApartmentAbroad = useMemo(() => assetCategory === 'Real Estate' && apartmentType === 'Abroad Apartment', [
    apartmentType,
    assetCategory
  ]);
  const isPaperApartment = useMemo(() => assetCategory === 'Real Estate' && apartmentType === 'Paper Apartment', [
    apartmentType,
    assetCategory
  ]);

  const isRSU = useMemo(() => assetCategory === 'RSU', [assetCategory]);

  const { user } = useAuth();
  const { translate, langCode } = useContext(LocalizeContext);

  const [calculatorToShow, setCalculatorToShow] = useState(null);

  const onSelectAppartment = appartment => {
    onCalculatorApartmentImport &&
      onCalculatorApartmentImport(calculatorApartmentModalFields(strategy, appartment, apartmentType));
    setCalculatorToShow(null);
  };

  const onImportClick = () => {
    setCalculatorToShow(apartmentTypeToKey[apartmentType]);
  };

  const showImportButton = useMemo(() => {
    return (
      isAdmin(user?.role) &&
      assetCategory === 'Real Estate' &&
      apartmentTypeToKey[apartmentType] &&
      activeCustomer['allowedCalculators']?.includes(apartmentTypeToKey[apartmentType])
    );
  }, [user, assetCategory, apartmentType, activeCustomer]);

  window['onImportClick'] = onImportClick;

  const renderPaperApartmentPayments = isPaperApartment
    ? () => {
        return (
          <PaperApartmentPayments
            translate={translate}
            apartmentValue={assetValue}
            strategyYears={strategyYears}
            paperApartmentPayments={paperApartmentPayments}
            onPaymentsChange={onPaperApartmentPaymentsChange}
            onPaymentAddClick={onPaperApartmentPaymentAddClick}
            onPaymentDeleteClick={onPaperApartmentPaymentDeleteClick}
          />
        );
      }
    : null;

  const renderRSUGrants = isRSU
    ? () => {
        return (
          <RSUGrants
            translate={translate}
            strategyYears={strategyYears}
            rsuGrants={rsuGrants}
            onRSUGrantsChange={onRSUGrantsChange}
            onRSUGrantsAddClick={onRSUGrantsAddClick}
            onRSUGrantsDeleteClick={onRSUGrantsDeleteClick}
          />
        );
      }
    : null;

  const renderTaxes = taxInfo
    ? () => {
        return (
          <div className="">
            {!isApartmentAbroad && (
              <CheckBoxInput
                id="buyingOnlyApartment"
                size="sm"
                showLabel
                label="Is Only apartment?"
                checked={onlyApartment?.buying}
                onChangeFunction={e => e && onFirstApartmentCheckedChange('buying', e.target.checked)}
              />
            )}

            {taxInfo['buyingTax'] && (
              <BuyingTax
                disabled={!assetEditMode}
                assetValue={assetValue}
                buyingTax={taxInfo['buyingTax']}
                totalTax={taxInfo['totalBuyingTax']}
                totalTaxPercentage={taxInfo['totalBuyingTaxPercentage']}
                onTotalTaxChange={onBuyingTaxChange}
                onTotalTaxPercentageChange={onBuyingTaxPercentageChange}
                isApartmentAbroad={isApartmentAbroad}
                isTaxCalculatedAutomatically={buyingTaxAutoMode}
                onAutoCalculateModeChange={onBuyingTaxCalculationModeChange}
              />
            )}
            {!isApartmentAbroad && (
              <CheckBoxInput
                id="sellingOnlyApartment"
                size="sm"
                showLabel
                label="Is Only apartment?"
                checked={onlyApartment?.selling}
                onChangeFunction={e => e && onFirstApartmentCheckedChange('selling', e.target.checked)}
              />
            )}
            {taxInfo['sellingTax'] && (
              <SellingTax
                isApartmentAbroad={isApartmentAbroad}
                sellingTax={taxInfo['sellingTax']}
                onTotalTaxChange={onSellingTaxChange}
                onTotalTaxPercentageChange={onSellingTaxPercentageChange}
                isTaxCalculatedAutomatically={sellingTaxAutoMode}
                onAutoCalculateModeChange={onSellingTaxCalculationModeChange}
              />
            )}
          </div>
        );
      }
    : null;

  return (
    <Modal size="lg" show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton={!showProgress}>
        <Modal.Title>
          <h6 className="mb-0">{toBeEditedAsset ? 'Update Asset' : 'Add New Asset'}</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-auto">
        {show && (
          <>
            <div className="px-3">
              <ExplainatoryVideoButton videoKey="add_asset" />
            </div>
            <FormGenerator
              prefix="asset"
              formJson={
                toBeEditedAsset
                  ? updateAssetForm(
                      strategyYears,
                      assetBuyingYear,
                      assetSellingYear,
                      incompleteYearsOfStrategy,
                      isPrimaryBankAccount,
                      assetCategory,
                      apartmentType,
                      assetEditMode,
                      user,
                      assetOptions,
                      presetOptions,
                      renderPaperApartmentPayments,
                      renderTaxes,
                      renderRSUGrants
                    )
                  : addAssetForm(
                      strategyYears,
                      assetBuyingYear,
                      assetSellingYear,
                      incompleteYearsOfStrategy,
                      assetCategory,
                      apartmentType,
                      assetOptions,
                      presetOptions,
                      assetEditMode,
                      user,
                      showImportButton,
                      renderPaperApartmentPayments,
                      renderTaxes,
                      renderRSUGrants
                    )
              }
              formValues={
                toBeEditedAsset ? { 'Update Asset': toBeEditedAsset } : { 'New Asset': { returnAppreciation: 0 } }
              }
            />

            {[
              {
                show: calculatorToShow === IRR_CALCULATOR_KEY,
                onHide: setCalculatorToShow,
                key: IRR_CALCULATOR_KEY,
                Calculator: IrrCalculatorSidebar
              },
              {
                show: calculatorToShow === PINUI_BINUI_CALCULATOR_KEY,
                onHide: setCalculatorToShow,
                key: PINUI_BINUI_CALCULATOR_KEY,
                Calculator: PinuiBinuiIrrCalculatorSidebar
              },
              {
                show: calculatorToShow === PAPER_CALCULATOR_KEY,
                onHide: setCalculatorToShow,
                key: PAPER_CALCULATOR_KEY,
                Calculator: PaperApartmentCalculatorSidebar
              }
            ]
              .filter(a => activeCustomer['allowedCalculators']?.includes(a.key))
              .map(({ show, onHide, Calculator }) => (
                <Calculator
                  translate={translate}
                  customer={activeCustomer}
                  show={show}
                  adminCompTable={adminCompTable}
                  onSelectAppartment={onSelectAppartment}
                  onHide={() => onHide(false)}
                  selectMode={true}
                />
              ))}
          </>
        )}

        {showProgress && <HorizontalProgress text={toBeEditedAsset ? 'Updating Asset...' : 'Adding Asset....'} />}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={showProgress} size="sm" variant="success" className="mr-2 text-white" onClick={onAssetSubmit}>
          {toBeEditedAsset ? 'Update' : 'Add'} Asset
        </Button>

        <Button
          disabled={showProgress}
          size="sm"
          variant="success"
          className="mr-2 text-white"
          onClick={onGroupAssetSubmit}
        >
          Group {toBeEditedAsset ? 'Update' : 'Add'} Asset
        </Button>

        {!toBeEditedAsset && (
          <Button
            disabled={showProgress}
            size="sm"
            variant="danger"
            className="mr-2"
            onClick={onAddAssetWithLiabilityClick}
          >
            Link a new liability
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AssetModal;
